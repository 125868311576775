import {
  InventoryDocumentStatus,
  InventoryDocumentType,
  Tracability,
} from "../../generated/inventory";
import { ManufactureOrderQuery } from "../../generated/manufacture";
import { IManufactureOrder } from "../../types/Manufacture/order";
import { IAttachment, ICreatedBy } from "../../types/global";
import { IItem, IUom } from "../../types/Inventory/item";
import { v4 as uuidv4 } from "uuid";
import { ITraceEntry } from "../../types/Inventory";
import { UseFormSetValue } from "react-hook-form";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { ITEM_STOCK_UOMS } from "../../services/AgGrid/InventoryAgGrid";
import { GraphQLClient } from "graphql-request";
import { GanttData, Task } from "../../types/Manufacture/gantt";
import { format } from "path";
import { formatNumber } from "../dataTransformer";
import { GanttStatus } from "../../components/UI/GanttChart/GanttChartStatus";
import { gantt } from "dhtmlx-gantt";
import { uploadFileToS3 } from "../s3";

export const formatQueryManufactureOrder = (
  data: ManufactureOrderQuery["manufactureOrder"]
): IManufactureOrder => {
  const new_work_order_list = data?.work_order_list?.map((work_order) => ({
    ...work_order,
    duration: parseFloat(work_order?.duration || 0),
    work_order_id: work_order?.id,
    id: undefined,
  }));

  const new_ingredient_list = data?.ingredient_list?.map((ingredient) => ({
    ...ingredient,
    qty: parseFloat(ingredient?.qty || 0),
    cost_per_unit: parseFloat(ingredient?.cost_per_unit || 0),
  }));

  const new_waste_production_list = data?.waste_production_list?.map(
    (waste) => ({
      ...waste,
      qty: parseFloat(waste?.qty || 0),
    })
  );

  return {
    ...data,
    production_qty: parseFloat(data?.production_qty || 0),
    work_order_list: new_work_order_list,
    ingredient_list: new_ingredient_list,
    waste_production_list: new_waste_production_list,
  } as IManufactureOrder;
};

export const formatterManufactureOrder = async ({
  id,
  created_by,
  created_date,
  main_status,
  bom_id,
  bom_name,
  bom_detail,
  ingredient_list,
  work_order_list,
  waste_production_list,
  tag_list,
  routing_id,
  routing_detail,
  attachment_list,
  ...otherData
}: IManufactureOrder) => {
  let new_attachment_list: IAttachment[] = [];

  if (attachment_list && attachment_list.length > 0) {
    for (const file of attachment_list) {
      if (file instanceof File) {
        const { Location } = await uploadFileToS3(
          file,
          "manufacture_order",
          id?.toString() || ""
        );
        const formatAttachment: IAttachment = {
          attachment_name: file.attachment_name,
          uploaded_by: file.uploaded_by,
          uploaded_date: file.uploaded_date,
          url: Location,
        };
        new_attachment_list.push(formatAttachment);
      } else {
        new_attachment_list.push(file);
      }
    }
  }

  const new_ingredient_list = ingredient_list?.map((ingredient) => ({
    cuid: ingredient?.cuid || undefined,
    item_unique_id: ingredient?.item_unique_id,
    item_name: ingredient?.item_name,
    item_description: ingredient?.item_description,
    item_tracability: ingredient?.item_tracability,
    item_img_url: ingredient?.item_img_url,
    item_barcode: ingredient?.item_barcode,
    qty: ingredient?.qty,
    cost_per_unit:
      typeof ingredient?.cost_per_unit === "string"
        ? null
        : ingredient?.cost_per_unit,
    good_issue_qty: ingredient?.good_issue_qty,
    uom_unique_id: ingredient?.uom_unique_id,
    uom: ingredient?.uom,
    reference_line_item_cuid: ingredient?.reference_line_item_cuid,
  }));

  const new_waste_production_list = waste_production_list?.map(
    (production_list) => ({
      cuid: production_list?.cuid || undefined,
      item_unique_id: production_list.item_unique_id,
      item_name: production_list.item_name,
      item_description: production_list.item_description,
      qty: production_list.qty,
      uom_unique_id: production_list.uom_unique_id,
      uom: production_list.uom,
      remark: production_list.remark,
    })
  );

  const new_tag_list = tag_list?.map((tag) => tag.name) || undefined;

  const new_work_order_list = work_order_list?.map(
    (
      { created_by, actual_duration, duration, cost_price, ...workOrder },
      index
    ) => ({
      ...workOrder,
      indexing: workOrder.indexing || index,
      id: (workOrder as any)?.work_order_id,
      duration:
        duration !== null && (duration as any) !== "" ? duration : undefined,
      cost_price:
        cost_price !== null && (cost_price as any) !== ""
          ? cost_price
          : undefined,
      work_order_id: undefined,
    })
  );

  return {
    ...otherData,
    bom_id: bom_id || bom_detail?.id || null,
    bom_name: bom_name ?? null,
    ingredient_list: new_ingredient_list,
    tag_list: new_tag_list,
    work_order_list: new_work_order_list,
    waste_production_list: new_waste_production_list,
    routing_id: !routing_id ? undefined : routing_id,
    routing_detail: routing_detail ? routing_detail : undefined,
    attachment_list: new_attachment_list,
  };
};

export const createInventoryDocumentFromManufactureOrder = async (
  data: IManufactureOrder,
  currentUser: ICreatedBy,
  item?: IItem,
  type?: string
) => {
  const {
    unique_id,
    goods_receive_qty,
    ingredient_list,
    actual_production_qty,
    production_qty,
  } = data;

  let formatUom: IUom = {
    unique_id: item?.stock_uom?.unique_id || "",
    name: item?.stock_uom?.name || "",
  };

  const formatTraceEntryGR = [
    {
      item_unique_id: item?.unique_id,
      item_name: item?.name,
      item_img_url: item?.img_url,
      tracability: item?.tracability,
      type: InventoryDocumentType.GoodsReceive,
      unique_id: uuidv4(),
      document_item_qty: parseFloat((production_qty || 0).toString()),
      posted_qty: parseFloat(goods_receive_qty.toString() ?? "0"),
      qty: 0,
      uom: formatUom,
      serial_list: item?.tracability === Tracability.Serial ? [] : undefined,
      status:
        item?.tracability === Tracability.Normal
          ? InventoryDocumentStatus.IsActive
          : undefined,
      barcode: item?.barcode || undefined,
    },
  ];

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const ingredient_item_unique_id_list = ingredient_list?.map(
    (itemInt) => itemInt?.item_unique_id
  );

  const { itemStockUoms } = await graphQLClientWithHeaderItem.request(
    ITEM_STOCK_UOMS,
    {
      itemUniqueIdList: ingredient_item_unique_id_list,
    }
  );

  const formatTraceEntryGI = ingredient_list?.map((itemInt) => {
    const foundItemWithStockUom = itemStockUoms.find(
      (stockUom: any) => stockUom.unique_id === itemInt?.item_unique_id
    );
    return {
      item_unique_id: itemInt?.item_unique_id,
      item_name: itemInt?.item_name,
      item_img_url: itemInt?.item_img_url,
      tracability: itemInt?.item_tracability,
      type: InventoryDocumentType.GoodsIssue,
      unique_id: uuidv4(),
      document_item_qty: parseFloat(itemInt.qty || "0"),
      posted_qty: parseFloat(itemInt?.good_issue_qty?.toString() ?? "0"),
      qty: 0,
      uom: foundItemWithStockUom
        ? {
            unique_id: foundItemWithStockUom?.stock_uom?.unique_id || "",
            name: foundItemWithStockUom?.stock_uom?.name || "",
          }
        : {
            unique_id: itemInt?.uom_unique_id || "",
            name: itemInt?.uom || "",
          },
      serial_list:
        itemInt?.item_tracability === Tracability.Serial ? [] : undefined,
      status:
        itemInt?.item_tracability === Tracability.Normal
          ? InventoryDocumentStatus.IsActive
          : undefined,
      barcode: itemInt?.item_barcode || undefined,
      // scanned_by: currentUser,
    };
  });

  return {
    type: "manufacture_order",
    reference_unique_id: unique_id,
    trace_entry_list: type === "gr" ? formatTraceEntryGR : formatTraceEntryGI,
    created_by: currentUser,
  };
};

export const manufactureOrderToTraceEntryFormatter = (
  data: IManufactureOrder,
  item?: IItem,
  type?: InventoryDocumentType
) => {
  const { goods_receive_qty, ingredient_list, production_qty } = data;

  let formatUom: IUom = {
    unique_id: item?.stock_uom?.unique_id || "",
    name: item?.stock_uom?.name || "",
  };

  const formatTraceEntryGR: ITraceEntry[] = [
    {
      item_unique_id: item?.unique_id || "",
      item_name: item?.name || "",
      item_img_url: item?.img_url,
      tracability: item?.tracability,
      type: InventoryDocumentType.GoodsReceive,
      unique_id: uuidv4(),
      document_item_qty: parseFloat((production_qty || 0).toString()),
      posted_qty: parseFloat(goods_receive_qty.toString()) ?? 0,
      qty: 0,
      uom: formatUom,
      serial_list: item?.tracability === Tracability.Serial ? [] : undefined,
      status:
        item?.tracability === Tracability.Normal
          ? InventoryDocumentStatus.IsActive
          : undefined,
      barcode: item?.barcode || undefined,
    },
  ];

  const formatTraceEntryGI: ITraceEntry[] =
    ingredient_list?.map((itemInt) => ({
      item_unique_id: itemInt?.item_unique_id || "",
      item_name: itemInt?.item_name || "",
      item_img_url: itemInt?.item_img_url,
      tracability: itemInt?.item_tracability,
      type: InventoryDocumentType.GoodsIssue,
      unique_id: uuidv4(),
      document_item_qty: parseFloat(itemInt.qty || "0"),
      posted_qty: itemInt?.good_issue_qty
        ? parseFloat(itemInt?.good_issue_qty.toString())
        : 0,
      qty: 0,
      uom: {
        unique_id: itemInt?.uom_unique_id || "",
        name: itemInt?.uom || "",
      },
      serial_list:
        itemInt?.item_tracability === Tracability.Serial ? [] : undefined,
      status:
        itemInt?.item_tracability === Tracability.Normal
          ? InventoryDocumentStatus.IsActive
          : undefined,
      barcode: itemInt?.item_barcode || undefined,
      // scanned_by: currentUser,
    })) || [];

  const formatTraceEntryRE: ITraceEntry[] =
    ingredient_list?.map((itemInt) => ({
      item_unique_id: itemInt?.item_unique_id || "",
      item_name: itemInt?.item_name || "",
      item_img_url: itemInt?.item_img_url,
      tracability: itemInt?.item_tracability,
      type: InventoryDocumentType.GoodsReturn,
      unique_id: uuidv4(),
      document_item_qty: parseFloat(itemInt.qty || "0"),
      posted_qty: itemInt?.good_return_qty
        ? parseFloat(itemInt?.good_return_qty.toString())
        : 0,
      qty: 0,
      uom: {
        unique_id: itemInt?.uom_unique_id || "",
        name: itemInt?.uom || "",
      },
      serial_list:
        itemInt?.item_tracability === Tracability.Serial ? [] : undefined,
      status:
        itemInt?.item_tracability === Tracability.Normal
          ? InventoryDocumentStatus.IsActive
          : undefined,
      barcode: itemInt?.item_barcode || undefined,
      // scanned_by: currentUser,
    })) || [];

  if (type === InventoryDocumentType.GoodsReceive) {
    return formatTraceEntryGR;
  } else if (type === InventoryDocumentType.GoodsIssue) {
    return formatTraceEntryGI;
  } else {
    return formatTraceEntryRE;
  }
};

export const copyManufactureOrderformatter = (data: IManufactureOrder) => {
  const {
    id,
    unique_id,
    main_status,
    sub_status,
    flag_status,
    aggrid_status,
    created_by,
    created_date,
    issue_date,
    actual_production_qty,
    production_date,
    production_completion_date,
    goods_receive_qty,
    work_order_list,
    ingredient_list,
    delivery_date,
    ...otherData
  } = data;

  const new_ingredient_list = ingredient_list?.map(
    ({ good_issue_qty, cost_per_unit, cuid, ...otherData }) => ({
      ...otherData,
      cuid: uuidv4(),
      good_issue_qty: 0,
      cost_per_unit:
        typeof cost_per_unit === "string"
          ? parseFloat(cost_per_unit || "0")
          : cost_per_unit,
    })
  );

  const new_work_order_list = work_order_list?.map(
    ({
      id,
      main_status,
      started_date,
      finished_date,
      updated_status_detail,
      ...otherData
    }) => ({
      ...otherData,
      updated_status_detail: [],
    })
  );

  return {
    ...otherData,
    copied_id: id,
    copied_unique_id: unique_id,
    goods_receive_qty: 0,
    actual_production_qty: 0,
    ingredient_list: new_ingredient_list,
    work_order_list: new_work_order_list,
  };
};

export const addUsersToRelatedEmployee = (
  watchCreatedBy: ICreatedBy,
  users: ICreatedBy[],
  relatedUsers: ICreatedBy[],
  setValue: UseFormSetValue<any>
) => {
  const mergedData = Array.from(
    new Set([...users, ...relatedUsers].map((user) => user.user_unique_id))
  )
    .map((user_unique_id) => {
      const formatDataUser = users.find(
        (user) => user.user_unique_id === user_unique_id
      );
      const relatedUsersUser = relatedUsers.find(
        (user: any) => user.user_unique_id === user_unique_id
      );

      return {
        user_unique_id,
        email: formatDataUser?.email || relatedUsersUser?.email,
        first_name: formatDataUser?.first_name || relatedUsersUser?.first_name,
        last_name: formatDataUser?.last_name || relatedUsersUser?.last_name,
        img_url: formatDataUser?.img_url || relatedUsersUser?.img_url,
      };
    })
    .filter((user) => user.user_unique_id !== watchCreatedBy?.user_unique_id);

  setValue("related_user_list", mergedData);
};

export const formatDateForGantt = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const formattedMonth = month < 10 ? `${month}` : `${month}`;
  const formattedDay = day < 10 ? `${day}` : `${day}`;
  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

  return `${year}-${formattedMonth}-${formattedDay} ${formattedHours}:${formattedMinutes}`;
};

export const formatDataForGantt = (
  manufactureOrders: any[],
  ganttFilter: any,
  startDate: Date,
  endDate: Date
): { ganttData: Task[]; ganttStatus: GanttStatus } => {
  const ganttData: Task[] = [];
  const ganttStatus: GanttStatus = {
    draft: 0,
    pending_manu: 0,
    in_progress: 0,
    finished: 0,
    cancelled: 0,
  };
  manufactureOrders.sort((a, b) => b.unique_id.localeCompare(a.unique_id));

  const productionCenterMap: { [key: string]: Task[] } = {};
  const productionCenterIndexMap: { [key: string]: number } = {};

  const filteredReference = manufactureOrders.filter((mo) => {
    if (ganttFilter.reference_unique_id.length === 0) {
      return true;
    }

    if (
      !mo.reference_unique_id_list ||
      !Array.isArray(mo.reference_unique_id_list)
    ) {
      return false;
    }

    return mo.reference_unique_id_list.some((reference: string) =>
      reference.includes(ganttFilter.reference_unique_id)
    );
  });

  const filteredData = filteredReference.filter((mo) => {
    if (ganttFilter.related_user_list.length === 0) {
      return true;
    }

    if (!mo.related_user_list || !Array.isArray(mo.related_user_list)) {
      return false;
    }

    return (
      ganttFilter.related_user_list.some(
        (user: string) => user === mo.created_by.user_unique_id
      ) ||
      ganttFilter.related_user_list.some((user: string) =>
        mo.related_user_list.some((u: any) => u.user_unique_id === user)
      )
    );
  });

  let productionCenterIndex = 0;
  filteredData.forEach((order) => {
    const productionStartDate = new Date(order.production_date).setHours(
      0,
      0,
      0,
      0
    );
    const productionEndDate = new Date(
      order.production_completion_date
    ).setHours(0, 0, 0, 0);

    const adjustedProductionStartDate =
      productionStartDate < startDate.getTime()
        ? startDate
        : new Date(productionStartDate);
    const adjustedProductionEndDate =
      productionEndDate > endDate.getTime()
        ? endDate
        : new Date(productionEndDate);

    const productionDate = formatDateForGantt(adjustedProductionStartDate);

    const productionCompletionDate =
      adjustedProductionEndDate.getTime() + 24 * 60 * 60 * 1000;

    const duration = Math.ceil(
      (productionCompletionDate - adjustedProductionStartDate.getTime()) /
        (1000 * 60 * 60 * 24)
    );
    if (order.work_order_list.length > 0) {
      switch (order.aggrid_status) {
        case "draft":
          ganttStatus.draft++;
          break;
        case "pending_manu":
          ganttStatus.pending_manu++;
          break;
        case "in_progress":
          ganttStatus.in_progress++;
          break;
        case "finished":
          ganttStatus.finished++;
          break;
        case "cancelled":
          ganttStatus.cancelled++;
          break;
        default:
          break;
      }
    }

    order.work_order_list.forEach((workOrder: any, index: number) => {
      const productionCenter = workOrder.production_center || "ไม่ระบุ";

      if (!productionCenterMap[productionCenter]) {
        productionCenterMap[productionCenter] = [];
        productionCenterIndexMap[productionCenter] = productionCenterIndex;
        productionCenterIndex++;
      }

      productionCenterMap[productionCenter].push({
        id: workOrder.id,
        text: workOrder.name,
        index: workOrder.indexing,
        start_date: productionDate,
        duration: duration,
        progress: 0,
        parent: productionCenterIndexMap[productionCenter] + 10000,
        production_center: productionCenter,
        unique_id: order.unique_id,
        reference_unique_id_list: order.reference_unique_id_list,
        customer_name: order.customer_name,
        item_unique_id: order.item_unique_id,
        production_qty: order.production_qty,
        item_description: order.item_description,
        uom: order.uom,
        capacity:
          formatNumber(
            workOrder.duration_unit === "hour"
              ? workOrder.duration
              : workOrder.duration_unit === "day"
              ? (workOrder.duration * 24).toFixed(2)
              : workOrder.duration_unit === "minute"
              ? (workOrder.duration / 60).toFixed(2)
              : workOrder.duration
          ) + "\tชม.",
        aggrid_status: order.aggrid_status,
        manufactureOrder: order,
        row_height: 61,
        bar_height: 56,
      });
    });
  });

  const sortedProductionCenters = Object.keys(productionCenterMap).sort(
    (a, b) => a.localeCompare(b, undefined, { sensitivity: "base" })
  );

  sortedProductionCenters.forEach((productionCenter) => {
    const parentId = productionCenterIndexMap[productionCenter] + 10000;

    ganttData.push({
      id: parentId,
      text: productionCenter,
      capacity:
        formatNumber(
          productionCenterMap[productionCenter].reduce(
            (sum, task) => sum + parseFloat(task.capacity || "0"),
            0
          )
        ) + "\tชม.",
      row_height: 45,
      bar_height: 30,
    });

    ganttData.push(...productionCenterMap[productionCenter]);
  });

  return { ganttData, ganttStatus };
};
