import {
  ColDef,
  RowDoubleClickedEvent,
  IServerSideGetRowsParams,
  GridReadyEvent,
} from "ag-grid-community";
import { useNavigate } from "react-router";
import AgGrid from "../../../UI/AgGrid";
import { AgGridReact } from "ag-grid-react";
import { RefObject } from "react";
import { useInputTaxColumnDefs } from "./columnDefs";
import { useTranslation } from "react-i18next";
import { IInputTax } from "../../../../types/Purchase/InputTax";
import { dateFilterModel } from "../../../../utils/Formatter/AgGridFilter";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { PURCHASE_INPUT_TAX_AGGRID } from "../../../../services/AgGrid/PurchaseAgGrid";

interface Props {
  gridRef: RefObject<AgGridReact<IInputTax>>;
  onFilterChanged: (params: any) => void;
}

const InputTaxReportTable = ({ gridRef, onFilterChanged }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columnDef: ColDef[] | undefined = useInputTaxColumnDefs(t);

  const onRowDoubleClicked = (params: RowDoubleClickedEvent) => {
    navigate(`/purchase/tax/${encodeURIComponent(params.data.unique_id)}`);
  };

  const graphQLClient: GraphQLClient =
      createGraphQLClientWithMiddleware("purchase");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const {
        tax_invoice_no,
        tax_invoice_date,
        customer_name,
        customer_id,
        branch,
        post_additional_discount_amount,
        vat_amount,
      } = filterModel;
      const formatFilter = {
        tax_invoice_no: tax_invoice_no,
        tax_invoice_date: dateFilterModel(tax_invoice_date),
        customer_name: customer_name,
        customer_id: customer_id,
        branch: branch,
        post_additional_discount_amount: post_additional_discount_amount,
        vat_amount: vat_amount,
      };
      try {
        const { InputTaxReport } =
          await graphQLClient.request(PURCHASE_INPUT_TAX_AGGRID, {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          });
        params.success({
          rowData: InputTaxReport.data as any[],
          rowCount: InputTaxReport.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
    onFilterChanged(params);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDef}
      height={665}
      onGridReady={onGridReady}
      onRowDoubleClicked={onRowDoubleClicked}
    />
  );
};

export default InputTaxReportTable;

