import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import { Typography } from "@mui/material";
import ReportDateFilter from "../../../components/UI/ReportDateFilter";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useRef } from "react";
import InputTaxReportTable from "../../../components/Table/Purchase/Tax/Report";
import { IInputTax } from "../../../types/Purchase/InputTax";
import { formatDateAgGrid } from "../../../utils/Formatter/Date";
import { useState } from "react";

// exporter
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { exportCSVParams } from "../../../utils/Formatter/AgGridFilter";
import { useSnackbar } from "notistack";

const InputTaxReport = () => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact<IInputTax>>(null);
  const [showTable, setShowTable] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("purchase.index"),
      to: "/purchase",
    },
    {
      name: t("report"),
      to: "/purchase/report",
    },
    {
      name: t("purchase.tax.index"),
    },
  ];

  const { control, getValues, reset, setValue } = useForm({
    defaultValues: {
      dateType: "today",
      dateFrom: dayjs().startOf("day").toDate(),
      dateTo: dayjs().endOf("day").toDate(),
    },
  });

  const onBtnExport = useCallback(() => {
    let startDate = getValues("dateFrom");
    let endDate = getValues("dateTo");
    const params = exportCSVParams(
      t("purchase.tax.index"),
      startDate,
      endDate
    );
    if (gridRef && gridRef.current) {
      gridRef.current.api.exportDataAsCsv(params);
      enqueueSnackbar("นำออกใบสั่งซื้อสำเร็จ", {
        variant: "success",
      });
    }
  }, [enqueueSnackbar, getValues, t]);

  const onFilterChanged = useCallback(
    (params: any) => {
      let startDate = getValues("dateFrom");
      let endDate = getValues("dateTo");

      const tax_invoice_date = params.api.getFilterInstance("tax_invoice_date");

      tax_invoice_date?.setModel({
        filterType: "date",
        type: "inRange",
        dateFrom: formatDateAgGrid(startDate),
        dateTo: formatDateAgGrid(endDate),
      });

      params.api.onFilterChanged();
    },
    [getValues]
  );

  const getFilteredData = () => {
    setShowTable(true);
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.setFilterModel({});
      onFilterChanged(gridRef.current);
    }
  };

  const onFilterReset = async () => {
    if (gridRef && gridRef.current) {
      gridRef.current.api.setFilterModel({});
      onFilterChanged(gridRef.current);
    }
    await reset({
      dateType: "today",
      dateFrom: dayjs().startOf("day").toDate(),
      dateTo: dayjs().endOf("day").toDate(),
    });
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">{t("purchase.tax.index")}</Typography>
        <CustomizedButton
          title={`${t("button.export")}`}
          variant="contained"
          onClick={onBtnExport}
        />
      </HeaderLayout>
      <ReportDateFilter
        control={control}
        setValue={setValue}
        getValues={getValues}
        onFilterReset={onFilterReset}
        getFilteredData={getFilteredData}
        future
        disabledControlledSelect={true}
        disabledDate={true}
      />
      {showTable && (<InputTaxReportTable
        gridRef={gridRef}
        onFilterChanged={onFilterChanged}
      />)}
    </>
  );
}

export default InputTaxReport