import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";

import styled from "@emotion/styled";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";

import CheckedItemModal from "../../../../Table/CheckedItemModal";
import { CustomizedTooltip } from "../../../../Custom/CustomizedTooltip";
import ControlledTextField from "../../../../Controller/ControlledTextField";

import { ICreatedBy, IDefaultForm } from "../../../../../types/global";
import { ISkuDetail } from "../../../../../types/Inventory/item";

import { IManufactureOrder } from "../../../../../types/Manufacture/order";
import { createGraphQLClientWithMiddleware } from "../../../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import { BOMS_AGGRID } from "../../../../../services/AgGrid/ManufactureAgGrid";
import { addUsersToRelatedEmployee } from "../../../../../utils/Formatter/ManufactureOrder";
import ControlledNumberTextField from "../../../../Controller/ControlledNumberTextField";

type Props = IDefaultForm & {
  roundedProductionQty: number;
  handleClickOpenItem?: (unique_id?: string) => void;
};

const ItemDetail = ({
  control,
  errors,
  setValue,
  getValues,
  disabled,
  reset,
  roundedProductionQty,
  handleClickOpenItem,
}: Props) => {
  const { t } = useTranslation();
  const [showSelectItem, setShowSelectItem] = useState<boolean>(false);
  const { id } = useParams();

  const [itemIds, setItemIds] = useState<string[]>([]);
  const [itemIdsSnapshot, setItemIdsSnapshot] = useState<string[]>([]);

  const StyledLink = styled(Link)<any>(({ theme }) => ({
    color: theme.palette.primary.main,
    textDecoration: "none",
    cursor: "pointer",
  }));

  const graphQLClientWithHeaderManufacture: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  const watchItemId = useWatch({ control, name: "item_unique_id" });

  const watchReferenceList = useWatch({
    control,
    name: "reference_document_list",
  });

  const status = useWatch({
    control,
    name: "main_status",
  });

  const formDisable = (id && status !== "draft") || disabled;
  const descriptionDisable =
    (id &&
      status &&
      !["draft", "pending_manu", "in_progress"].includes(status)) ||
    disabled;

  const openItemTable = () => {
    setShowSelectItem(true);
  };

  const closeItemTable = () => {
    setShowSelectItem(false);
  };

  useEffect(() => {
    if (watchItemId) {
      setItemIds([watchItemId]);
      setItemIdsSnapshot([watchItemId]);
    }
  }, [watchItemId]);

  const finishItemSelectHandler = async (data: ISkuDetail) => {
    if (data) {
      setValue("item_unique_id", data.sku_name);
      setValue("item_name", data.item_sku?.item?.name);
      setValue("item_description", data.item_sku?.item?.desc);
      setValue("uom_unique_id", data.item_sku?.item?.stock_uom?.unique_id);
      setValue("uom", data.item_sku?.item?.stock_uom?.name);
      setValue("type", data.item_sku?.item?.manufacturing_type);
      setValue(
        "production_qty",
        data.item_sku?.item?.manufacture_minimum_qty ?? 0
      );
      if (data.item_sku?.item?.bom_id) {
        setValue("bom_id", data.item_sku.item.bom_id);
        setValue("bom_name", data.item_sku.item.bom_detail?.name);

        const { bomsFindManyAggrid } =
          await graphQLClientWithHeaderManufacture.request(BOMS_AGGRID, {
            aggridInput: {
              startRow: 0,
              endRow: 1,
              filterModel: {
                id: {
                  filterType: "number",
                  type: "equals",
                  filter: data.item_sku.item.bom_id,
                },
              },
              sortModel: [],
            },
          });

        const attachment_list = getValues("attachment_list");

        setValue("attachment_list", [
          ...attachment_list,
          ...(bomsFindManyAggrid?.data?.[0].attachment_list ?? []),
        ]);
      } else {
        setValue("bom_id", "");
        setValue("bom_name", "");
        setValue("bom_detail", "");
        setValue("ingredient_list", []);
      }
      if (data.item_sku?.item?.routing_id) {
        const routing_detail = data.item_sku.item.routing_detail;
        const routing_id = data.item_sku.item.routing_id;
        const work_order_list =
          data.item_sku.item.routing_detail.work_order_list;
        setValue("routing_id", routing_id);
        setValue("routing_detail", routing_detail);

        const relatedUsers = getValues(`related_user_list`);
        const createdBy = getValues(`created_by`);

        const { userLists } = work_order_list?.reduce(
          (prev: any, curr: any) => {
            if (
              curr.responsible_user_list &&
              curr.responsible_user_list.length > 0
            )
              prev.userLists.push(...curr.responsible_user_list);
            return prev;
          },
          {
            userLists: [] as ICreatedBy[],
          }
        );

        addUsersToRelatedEmployee(createdBy, userLists, relatedUsers, setValue);

        const attachment_list = getValues("attachment_list");

        setValue("attachment_list", [
          ...attachment_list,
          ...(data.item_sku.item.routing_detail.attachment_list ?? []),
        ]);
        setValue(
          "work_order_list",
          data.item_sku.item.routing_detail.work_order_list || []
        );
      } else {
        setValue("routing_id", "");
        setValue("routing_detail", { name: "" });
        setValue("work_order_list", []);
      }
    } else {
      reset &&
        reset((prev: IManufactureOrder) => ({
          ...prev,
          item_unique_id: "",
          item_name: "",
          stock_uom: "",
          production_qty: 0,
          item_remark: "",
          item_description: "",
          bom_id: "",
          bom_name: "",
          type: "",
          ingredient_list: [],
          bom_detail: null,
        }));
    }
    closeItemTable();
  };

  return (
    <Box mt={3}>
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Typography ml={1} fontWeight="bold">
          {t("manufacture.order.item_detail")}
        </Typography>
        {!disabled && (
          <CustomizedTooltip
            title={
              watchReferenceList && watchReferenceList.length > 0
                ? "เลือกสินค้าใหม่ ที่อ้างอิงกับใบสั่งขายใบนี้"
                : "เลือกสินค้า"
            }
          >
            <IconButton onClick={openItemTable} color="primary">
              <EditOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        )}
      </Box>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="item_unique_id"
            label={t("manufacture.order.item_unique_id")}
            control={control}
            error={Boolean(errors?.item_unique_id)}
            helperText={
              errors?.item_unique_id && errors?.item_unique_id.message
            }
            disabled
            required
            sx={{
              input: { visibility: disabled ? "hidden" : "visible" },
            }}
            InputProps={{
              startAdornment: disabled && (
                <InputAdornment position="start">
                  <StyledLink onClick={handleClickOpenItem}>
                    {watchItemId || t("manufacture.order.item_unique_id")}
                  </StyledLink>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {!disabled && watchItemId && (
                    <CustomizedTooltip title="กดเพื่อดูรายละเอียดสินค้า">
                      <IconButton
                        onClick={() =>
                          handleClickOpenItem
                            ? handleClickOpenItem()
                            : undefined
                        }
                        sx={{
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <LaunchOutlinedIcon />
                      </IconButton>
                    </CustomizedTooltip>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="item_name"
            label={t("manufacture.order.item_name")}
            control={control}
            error={Boolean(errors?.item_name)}
            helperText={errors?.item_name && errors?.item_name.message}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="item_remark"
            label={t("manufacture.order.item_remark")}
            control={control}
            error={Boolean(errors?.item_remark)}
            helperText={errors?.item_remark && errors?.item_remark.message}
            disabled={descriptionDisable}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledNumberTextField
            fullWidth
            name="production_qty"
            label={
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                {t("manufacture.order.production_qty")}
                <CustomizedTooltip
                  placement="top"
                  title={`จำนวนสั่งผลิตขั้นต่ำเพื่อเบิกวัตถุดิบที่พอดี = ${roundedProductionQty}`}
                >
                  <InfoOutlinedIcon fontSize="small" />
                </CustomizedTooltip>
              </Box>
            }
            control={control}
            error={Boolean(errors?.production_qty)}
            helperText={
              errors?.production_qty && errors?.production_qty.message
            }
            disabled={formDisable}
            InputLabelProps={{ style: { pointerEvents: "auto" } }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="uom"
            label={t("manufacture.order.uom")}
            control={control}
            error={Boolean(errors?.uom)}
            helperText={errors?.uom && errors?.uom.message}
            disabled={true}
          />
        </Grid>
        <Grid item xs={8}>
          <ControlledTextField
            name="item_description"
            label={t("manufacture.order.item_description")}
            control={control}
            error={Boolean(errors?.item_description)}
            helperText={
              errors?.item_description && errors?.item_description.message
            }
            disabled={descriptionDisable}
            multiline
            minRows={1}
            maxRows={10}
            onChange={(e, field) => {
              const inputValue = e.target.value;
              const lines = inputValue.split("\n");
              if (lines.length <= 10) {
                field.onChange(inputValue);
              }
            }}
          />
        </Grid>
      </Grid>
      <CheckedItemModal
        showSelectItem={showSelectItem}
        closeItemTable={closeItemTable}
        finishItemsSelect={finishItemSelectHandler}
        itemIds={itemIds}
        setItemIds={setItemIds}
        itemIdsSnapshot={itemIdsSnapshot}
        setItemIdsSnapshot={setItemIdsSnapshot}
        rowSelection="single"
      />
    </Box>
  );
};

export default ItemDetail;
