import { useTranslation } from "react-i18next";
import CustomizedMenuList from "../../../components/Custom/CustomizedMenuList";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

const SalesReport = () => {
  const { t } = useTranslation();
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("report"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.quotation.index"),
      to: "/sales/report/quotation",
      icon: <DescriptionOutlinedIcon color="primary" />,
    },
    {
      name: t("sales.order.index"),
      to: "/sales/report/order",
      icon: <DescriptionOutlinedIcon color="primary" />,
    },
    {
      name: t("sales.return.index"),
      to: "/sales/report/return",
      icon: <DescriptionOutlinedIcon color="primary" />,
    },
    {
      name: t("sales.tax.index"),
      to: "/sales/report/tax",
      icon: <DescriptionOutlinedIcon color="primary" />,
    },
  ];

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </>
  );
};

export default SalesReport;
