import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import { Typography } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useCallback, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { GENERATE_REPORT } from "../../../services/AgGrid/InventoryReportAgGrid";
import { dateFilterModel } from "../../../utils/Formatter/AgGridFilter";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { formatDateAgGrid } from "../../../utils/Formatter/Date";
import MovementSummaryReportTable from "../../../components/Table/Inventory/Reports/MovementSummaryReportTable";
import ReportDateWithWarehouseFilter from "../../../components/UI/ReportDateWithWarehouseFilter";

const MovementSummaryReport = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("report"),
      to: "/inventory/report",
    },
    {
      name: t("inventory.movementSummaryReport.index"),
    },
  ];
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showTable, setShowTable] = useState<boolean>(false);
  const gridRef = useRef<AgGridReact>(null);

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { control, getValues, reset, setValue } = useForm({
    defaultValues: {
      dateType: "lastWeek",
      dateFrom: dayjs().startOf("day").subtract(6, "day").toDate(),
      dateTo: dayjs().endOf("day").toDate(),
      source_warehouse_unique_id: "all",
    },
  });

  const onBtnExport = async () => {
    try {
      setIsLoading(true);
      const filterModel = gridRef?.current?.api.getFilterModel() as any;
      const sortModel = gridRef?.current?.columnApi
        .getColumnState()
        .filter((s) => s.sort !== null)
        .map(({ sort, colId }) => ({
          sort,
          colId,
        }));

      const { created_date, ...otherFilter } = filterModel;
      const formatFilter = {
        ...otherFilter,
        created_date: dateFilterModel(created_date),
      };

      await graphQLClient.request(GENERATE_REPORT, {
        priority: 1,
        reportType: "movement_summary",
        params: {
          aggridInput: {
            startRow: 0,
            endRow: Math.pow(10, 5),
            filterModel: formatFilter,
            sortModel,
          },
        },
      });

      enqueueSnackbar("คำขอสำเร็จ ระบบกำลังดำเนินการ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("คำขอไม่สำเร็จ กรุณาลองใหม่อีกครั้ง", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      let startDate = getValues("dateFrom");
      let endDate = getValues("dateTo");
      let sourceWarehouseUniqueId = getValues("source_warehouse_unique_id");

      const created_date = params.api.getFilterInstance("created_date");
      const source_warehouse_unique_id = params.api.getFilterInstance(
        "source_warehouse_unique_id"
      );

      created_date?.setModel({
        filterType: "date",
        type: "inRange",
        dateFrom: formatDateAgGrid(startDate),
        dateTo: formatDateAgGrid(endDate),
      });

      source_warehouse_unique_id?.setModel({
        filterType: "text",
        type: "equals",
        filter: sourceWarehouseUniqueId && sourceWarehouseUniqueId !== "all" ? sourceWarehouseUniqueId : "", // TO DO : Need fix Later In Component
      });

      params.api.onFilterChanged();
    },
    [getValues]
  );

  const getFilteredData = () => {
    setShowTable(true);
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.setFilterModel({});
      onFilterChanged(gridRef.current);
    }
  };

  const onFilterReset = () => {
    reset({
      dateType: "lastWeek",
      dateFrom: dayjs().startOf("day").subtract(6, "day").toDate(),
      dateTo: dayjs().endOf("day").toDate(),
      source_warehouse_unique_id: "",
    });
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.setFilterModel({});
      onFilterChanged(gridRef.current);
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">
          {t("inventory.movementSummaryReport.index")}
        </Typography>
        <CustomizedButton
          title={`${t("button.export")}`}
          variant="contained"
          onClick={onBtnExport}
          disabled={isLoading || !showTable}
        />
      </HeaderLayout>
      <ReportDateWithWarehouseFilter
        control={control}
        setValue={setValue}
        getValues={getValues}
        onFilterReset={onFilterReset}
        getFilteredData={getFilteredData}
      />
      {showTable && (
        <MovementSummaryReportTable
          gridRef={gridRef}
          onFilterChanged={onFilterChanged}
        />
      )}
    </>
  );
};

export default MovementSummaryReport;