import { useSearchParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";
import { purchaseOrderSchema } from "../components/Form/Purchase/Order/schema";
import { salesOrderSchema } from "../components/Form/Sales/Order/schema";
import { OCR_DOCUMENT } from "../services/Setting/OCR/OCRDocument";
import { useSnackbar } from "notistack";
import { useStateContext } from "../contexts/auth-context";

const MODULE_LIST = [
  {
    document_type: "sales_order",
    url: "/sales/order/add",
  },
  {
    document_type: "purchase_order",
    url: "/purchase/order/add",
  },
];

const MOCK_DATA_LIST = [
  {
    document_type: "sales_order",
    data: {
      ...salesOrderSchema,
    },
  },
  {
    document_type: "purchase_order",
    data: { ...purchaseOrderSchema },
  },
];

const OCRNavigate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const params = Object.fromEntries(searchParams.entries());

  const {
    state: { authUser },
  } = useStateContext();

  const graphqlClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  useEffect(() => {
    const handleNavigate = async () => {
      if (params.module !== "ocr" || params.action !== "create_document") {
        return;
      }

      try {
        const { OCRDocument } = await graphqlClient.request(OCR_DOCUMENT, {
          findInput: {
            tracking_id: params.tracking_id,
            project_id: params.project_id,
          },
        });
        const matchingModule = MODULE_LIST.find(
          (module) => module.document_type === OCRDocument.document_type
        );
        if (!matchingModule) {
          throw new Error();
        }
        const payload = MOCK_DATA_LIST.find(
          (data) => data.document_type === OCRDocument.document_type
        )?.data;

        navigate(matchingModule.url, {
          state: {
            ...payload,
            ocr_log: { ...OCRDocument, document_type: undefined },
            created_by: authUser && {
              user_unique_id: authUser.unique_id,
              email: authUser.email,
              first_name: authUser.first_name,
              last_name: authUser.last_name,
              img_url: authUser.img_url,
            },
            is_ocr_create: true,
          },
        });
      } catch (err) {
        enqueueSnackbar("เกิดความผิดพลาด", {
          variant: "error",
        });
        navigate("/landing");
      }
    };

    handleNavigate();
  }, [params, navigate, enqueueSnackbar, graphqlClient]);

  return <></>;
};

export default OCRNavigate;
