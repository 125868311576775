import { gql } from "graphql-request";

export const BOMS_AGGRID = gql`
  query BomsFindManyAggrid($aggridInput: AggridTemplateInput) {
    bomsFindManyAggrid(aggridInput: $aggridInput) {
      data {
        id
        name
        description
        created_date
        start_date
        end_date
        internal_remark
        is_active
        is_main_bom
        item_unique_id
        item_name
        mfg_qty
        uom_unique_id
        uom
        mfg_type
        creator_unique_id
        created_by {
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
        ingredient_list {
          cuid
          item_unique_id
          item_name
          item_description
          qty
          uom_unique_id
          uom
          bom_name
          manufacture_order {
            id
            unique_id
            main_status
          }
          item_img_url
          item_tracability
          item_barcode
          cost_per_unit
        }
        attachment_list {
          attachment_name
          url
          uploaded_date
          uploaded_by {
            user_unique_id
            first_name
            last_name
          }
        }
      }
      count
    }
  }
`;

export const MANUFACTURE_ORDER_AGGRID = gql`
  query ManufactureOrdersFindManyAggrid($aggridInput: AggridTemplateInput) {
    manufactureOrdersFindManyAggrid(aggridInput: $aggridInput) {
      data {
        id
        unique_id
        main_status
        sub_status
        flag_status
        aggrid_status
        reference_unique_id_list
        external_reference_id
        related_user_list
        created_by {
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
        created_date
        issue_date
        production_date
        production_completion_date
        delivery_date
        customer_contact_unique_id
        customer_name
        project
        item_unique_id
        item_name
        item_description
        item_remark
        production_qty
        uom_unique_id
        actual_production_qty
        uom
        bom_id
        bom_name
        routing_id
        routing_id
        type
        goods_receive_qty
        template_remark_id
        remark
        tag_list {
          id
          name
        }
        ingredient_list {
          cuid
          item_unique_id
          item_name
          item_description
          qty
          uom_unique_id
          uom
          bom_name
          item_img_url
          item_tracability
          item_barcode
        }
        work_order_list {
          id
          indexing
          name
          main_status
          sub_status
          flag_status
          aggrid_status
          reference_unique_id
          plant
          production_center
          start_date_time
          duration
          duration_unit
          started_date
          created_by {
            user_unique_id
            first_name
            last_name
            email
            img_url
          }
        }
        waste_production_list {
          cuid
          reference_unique_id
          item_unique_id
          item_name
          item_description
          qty
          uom_unique_id
          uom
          remark
          manufacture_order_unique_id
        }
        attachment_list {
          attachment_name
          url
          uploaded_date
          uploaded_by {
            user_unique_id
            first_name
            last_name
          }
        }
      }
      count
    }
  }
`;

export const ROUTING_AGGRID = gql`
  query RoutingsFindManyAggrid($aggridInput: AggridTemplateInput) {
    routingsFindManyAggrid(aggridInput: $aggridInput) {
      data {
        id
        name
        description
        is_active
        is_main_routing
        created_date
        start_date
        end_date
        item_unique_id
        item_name
        stock_uom
        mfg_type
        work_order_id_list
        work_order_list
        created_by {
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
        internal_remark
      }
      count
    }
  }
`;

export const ROUTING_FIND_UNIQUE = gql`
  query Routing($uniqueInput: RoutingUniqueInput!) {
    routing(uniqueInput: $uniqueInput) {
      id
      name
      description
      is_active
      created_date
      start_date
      end_date
      item_unique_id
      item_name
      stock_uom
      mfg_type
      is_main_routing
      work_order_id_list
      work_order_list
      created_by {
        user_unique_id
        first_name
        last_name
        email
        img_url
      }
      internal_remark
      attachment_list {
        attachment_name
        url
        uploaded_date
        uploaded_by {
          user_unique_id
          first_name
          last_name
        }
      }
    }
  }
`;
