import { gql } from "graphql-request";

export const OCR_DOCUMENT = gql`
  query OCRDocument($findInput: OcrDocumentFindInput!) {
    OCRDocument(findInput: $findInput) {
      ocr_tracking_id
      ocr_status
      ocr_attrachment
      ocr_confidence
      document_type
      ocr_filename
      ocr_task_check_url
      ocr_human_check_status
      ocr_human_check_time
      ocr_project_id
      uploaded_by
      body_json
    }
  }
`;
