import { v4 as uuidv4 } from "uuid";
import { positionType } from "./userInfo";
import { IUom } from "../types/Inventory/item";

const DEFAULT_FLOAT_DECIMAL_DIGITS = 2;

export const filterPositionByDepartment = (
  department: string,
  setPosition: any
) => {
  switch (department) {
    case "management":
      setPosition([
        {
          id: uuidv4(),
          label: "กรรมการผู้จัดการ",
          value: "กรรมการผู้จัดการ",
        },
        {
          id: uuidv4(),
          label: "ผู้บริหาร",
          value: "ผู้บริหาร",
        },
      ]);
      break;
    case "inventory":
      setPosition([
        {
          id: uuidv4(),
          label: "พนักงานคลังสโตร์",
          value: "พนักงานคลังสโตร์",
        },
        {
          id: uuidv4(),
          label: "ธุรการคลัง",
          value: "ธุรการคลัง",
        },
      ]);
      break;
    case "sales":
      setPosition([
        {
          id: uuidv4(),
          label: "ผู้จัดการฝ่ายขาย",
          value: "ผู้จัดการฝ่ายขาย",
        },
        {
          id: uuidv4(),
          label: "เซลล์",
          value: "เซลล์",
        },
        {
          id: uuidv4(),
          label: "เซลล์แอดมิน",
          value: "เซลล์แอดมิน",
        },
      ]);
      break;
    case "purchase":
      setPosition([
        {
          id: uuidv4(),
          label: "หัวหน้าจัดซื้อ",
          value: "หัวหน้าจัดซื้อ",
        },
        {
          id: uuidv4(),
          label: "จัดซื้อ",
          value: "จัดซื้อ",
        },
      ]);
      break;
    case "account":
      setPosition([
        {
          id: uuidv4(),
          label: "ผู้จัดการฝ่ายบัญชี",
          value: "ผู้จัดการฝ่ายบัญชี",
        },
        {
          id: uuidv4(),
          label: "หัวหน้าฝ่ายสินเชื่อ",
          value: "หัวหน้าฝ่ายสินเชื่อ",
        },
        {
          id: uuidv4(),
          label: "บัญชีลูกหนี้",
          value: "บัญชีลูกหนี้",
        },
        {
          id: uuidv4(),
          label: "บัญชีคลัง",
          value: "บัญชีคลัง",
        },
        {
          id: uuidv4(),
          label: "บัญชี",
          value: "บัญชี",
        },
      ]);
      break;
    case "logistic":
      setPosition([
        {
          id: uuidv4(),
          label: "หัวหน้าจัดส่ง",
          value: "หัวหน้าจัดส่ง",
        },
      ]);
      break;
    case "manufacture":
      setPosition([
        {
          id: uuidv4(),
          label: "ผู้จัดการฝ่ายผลิต",
          value: "ผู้จัดการฝ่ายผลิต",
        },
        {
          id: uuidv4(),
          label: "ผู้จัดการผลิตฝ่ายวัตถุดิบ",
          value: "ผู้จัดการผลิตฝ่ายวัตถุดิบ",
        },
        {
          id: uuidv4(),
          label: "พนักงานฝ่ายผลิต",
          value: "พนักงานฝ่ายผลิต",
        },
      ]);
      break;
    case "admin":
      setPosition([
        {
          id: uuidv4(),
          label: "ผู้ดูแลระบบ",
          value: "ผู้ดูแลระบบ",
        },
      ]);
      break;
    default:
      setPosition(positionType);
      break;
  }
};

export const departmentEngToThai = (department: string) => {
  switch (department) {
    case "management":
      return "บริหาร";
    case "inventory":
      return "คลังสินค้า";
    case "sales":
      return "รายรับ";
    case "purchase":
      return "จัดซื้อ";
    case "account":
      return "บัญชี";
    case "logistic":
      return "ขนส่ง";
    case "manufacture":
      return "ผลิต";
    case "admin":
      return "ดูแลระบบ";
    default:
      return department || "";
  }
};

export const departmentThaiToEnum = (department: string) => {
  switch (department) {
    case "บริหาร":
      return "management";
    case "คลังสินค้า":
      return "inventory";
    case "รายรับ":
      return "sales";
    case "จัดซื้อ":
      return "purchase";
    case "บัญชี":
      return "account";
    case "ขนส่ง":
      return "logistic";
    case "ผลิต":
      return "manufacture";
    case "ดูแลระบบ":
      return "admin";
    default:
      return department;
  }
};

export const formatVatOption = (value: string) => {
  if (value === "NO_VAT") return "ไม่มี";
  else if (value === "VAT_0") return "0 %";
  else if (value === "VAT_7") return "7 %";
  else if (value === "0") return "0 %";
  else if (value === "7") return "7 %";
  else return value;
};

export const formatVatOptionForCRUD = (value: string) => {
  if (value === "ไม่มี") return "ไม่มี";
  else if (value === "0 %") return "0";
  else if (value === "7 %") return "7";
  else return value;
};

export const formatNumber = (number: number, digits?: number) => {
  return (
    Math.round(number * 100 * (1 + Number.EPSILON)) / 100
  )?.toLocaleString(undefined, {
    minimumFractionDigits: digits ?? 2,
    maximumFractionDigits: digits ?? 2,
  });
};

export const roundingNumber = (number: number, digits: number = 2): number => {
  const factor = Math.pow(10, digits);
  return Math.round(number * factor * (1 + Number.EPSILON)) / factor;
};

export const itemTypeEngToThai = (type: string) => {
  if (type === "service") return "บริการ";
  else if (type === "variant") return "สินค้ามีตัวเลือก";
  else if (type === "bundle") return "สินค้าจัดเซ็ต";
  else return "สินค้าธรรมดา";
};

export const renderUomOptions = (data?: IUom[] | null) =>
  data?.map((uom) => ({
    id: uom?.unique_id,
    label: uom?.name,
    value: uom?.unique_id,
  })) || [];

export const parseFloatFunction = (
  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  field: any
) => {
  const value = e.target.value;
  return field.onChange(parseFloat(value));
};

export const formatString = (str: any) => {
  return str?.toString().trim();
};

export const formatStringArray = (
  arr: string[] | string,
  filterInvalid?: boolean
) => {
  if (typeof arr === "string") {
    return [arr];
  }
  const result = Array.isArray(arr)
    ? arr?.map((item) => item?.toString().trim())
    : [];
  if (filterInvalid && result?.length > 0) {
    return result?.filter((item) => item);
  }
  return result;
};

export const formatStringToArray = (str: string, denominator?: string) => {
  return str?.split(denominator || ",")?.map((item) => item?.trim());
};

export const formatFloat = (input: any, points?: number) => {
  return !isNaN(parseFloat(input))
    ? parseFloat(
        parseFloat(input).toFixed(points || DEFAULT_FLOAT_DECIMAL_DIGITS)
      )
    : undefined;
};

export const formatInt = (input: any) => {
  return !isNaN(parseInt(input)) ? parseInt(input) : undefined;
};

export const entryTypeEngToThai = (data: string) => {
  switch (data) {
    case "goods_receive":
      return "นำเข้า";
    case "goods_issue":
      return "นำออก";
    case "goods_transfer":
      return "โอนย้าย";
    case "goods_adjustment":
    case "goods_adjust":
      return "ปรับปรุง";
    default:
      return "";
  }
};

export const mapStatusToThai = (status: string) => {
  switch (status) {
    case "draft":
      return "ร่าง";
    case "finished":
      return "เสร็จสิ้น";
    case "in_progress":
    case "inProgress":
      return "กำลังผลิต";
    case "pendingManu":
    case "pending_manu":
      return "รอผลิต";
    case "cancelled":
    case "canceled":
      return "ยกเลิก";
    case "wait_accept":
      return "รอตอบรับ";
    case "accepted":
      return "ตอบรับแล้ว";
    case "wait_approve":
      return "รออนุมัติ";
    case "approved":
      return "อนุมัติแล้ว";
    case "not_approved":
      return "ไม่อนุมัติ";
    case "wait_deliver":
      return "รอจัดส่ง";
    default:
      return status;
  }
};

export const formatInventoryDocumentType = (data: {
  goods_receive_unique_id: string | null;
  goods_issue_unique_id: string | null;
  goods_transfer_unique_id: string | null;
  goods_adjust_unique_id: string | null;
}) => {
  let reference_unique_id: string = "";
  let reference_document_type: string = "";
  if (data.goods_receive_unique_id) {
    reference_unique_id = data.goods_receive_unique_id;
    reference_document_type = "goods_receive";
  } else if (data.goods_issue_unique_id) {
    reference_unique_id = data.goods_issue_unique_id;
    reference_document_type = "goods_issue";
  } else if (data.goods_transfer_unique_id) {
    reference_unique_id = data.goods_transfer_unique_id;
    reference_document_type = "goods_transfer";
  } else if (data.goods_adjust_unique_id) {
    reference_unique_id = data.goods_adjust_unique_id;
    reference_document_type = "goods_adjustment";
  }
  return { reference_unique_id, reference_document_type };
};

export const formatExportedWorkOrderDurationUnit = (status: string) => {
  switch (status) {
    case "day":
      return "วัน";
    case "hour":
      return "ชั่วโมง";
    case "minute":
      return "นาที";
    default:
      return "";
  }
};
