import { IGoodsTransfer } from "../../../../types/Inventory/goodsTransfer";
import dayjs from "dayjs";
import * as Yup from "yup";
import { Tracability } from "../../../../generated/inventory";

export const goodsTransferSchema: IGoodsTransfer = {
  unique_id: "",
  source_warehouse_unique_id: "",
  destination_warehouse_unique_id: "",
  main_status: "",
  created_date: dayjs().toDate(),
  posted_date: dayjs(),
  remark: "",
  trace_entry_list: [],
  flag_status: [],
};

const goodsTransferTraceValidation = Yup.object().shape({
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .positive("กรุณาระบุจำนวนที่จะโอนย้าย")
    .required("กรุณาระบุจำนวนที่จะโอนย้าย"),
});

const goodsTransferSerialValidation = Yup.object().shape({
  qty: Yup.number().when("tracability", (tracability: any) =>
    tracability && tracability[0] === Tracability.Normal
      ? Yup.number()
          .transform((value) =>
            isNaN(value) || value === null || value === undefined ? 0 : value
          )
          .positive("กรุณาระบุจำนวนที่จะโอนย้าย")
          .required("กรุณาระบุจำนวนที่จะโอนย้าย")
      : Yup.number().nullable()
  ),
  serial_list: Yup.array().when("tracability", (tracability: any) =>
    tracability && tracability[0] === Tracability.Serial
      ? Yup.array()
          .of(goodsTransferTraceValidation)
          .min(1, "กรุณาสแกนรายการสินค้า")
      : Yup.array().nullable()
  ),
});

export const goodsTransferValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  posted_date: Yup.date().typeError("กรุณาระบุ").required("กรุณาระบุ"),
  source_warehouse_unique_id: Yup.string().required("กรุณาระบุคลังต้นทาง"),
  destination_warehouse_unique_id: Yup.string().required(
    "กรุณาระบุคลังปลายทาง"
  ),
  trace_entry_list: Yup.array()
    .of(goodsTransferSerialValidation)
    .min(1, "กรุณาเพิ่มสินค้า"),
});
