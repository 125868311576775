import { Box, Typography } from "@mui/material";
import React from "react";

export interface GanttStatus {
  draft: number;
  pending_manu: number;
  in_progress: number;
  finished: number;
  cancelled: number;
}

interface GanttStatusProps {
  data: GanttStatus;
}

const GanttStatus = ({ data }: GanttStatusProps) => {
  const total =
    data.draft +
    data.pending_manu +
    data.in_progress +
    data.finished +
    data.cancelled;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
        height: "40px",
        gap: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          height: "100%",
          alignItems: "center",
          padding: "8px 16px",
          justifyContent: "space-between",
          background: "#F2F2F2",
          borderRadius: "8px",
        }}
      >
        <Typography fontSize={14} fontWeight={600} color={"#333333"}>
          ทั้งหมด
        </Typography>
        <Typography fontSize={14} fontWeight={400} color={"#333333"}>
          {total}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          height: "100%",
          alignItems: "center",
          padding: "8px 16px",
          justifyContent: "space-between",
          background: "#F9F9F9",
          borderRadius: "8px",
        }}
      >
        <Typography fontSize={14} fontWeight={600} color={"#737373"}>
          ร่าง
        </Typography>
        <Typography fontSize={14} fontWeight={400} color={"#333333"}>
          {data.draft}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          height: "100%",
          alignItems: "center",
          padding: "8px 16px",
          justifyContent: "space-between",
          background: "#FFF4E5",
          borderRadius: "8px",
        }}
      >
        <Typography fontSize={14} fontWeight={600} color={"#E58000"}>
          รอผลิต
        </Typography>
        <Typography fontSize={14} fontWeight={400} color={"#333333"}>
          {data.pending_manu}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          height: "100%",
          alignItems: "center",
          padding: "8px 16px",
          justifyContent: "space-between",
          background: "#ECF6FD",
          borderRadius: "8px",
        }}
      >
        <Typography fontSize={14} fontWeight={600} color={"#2167D3"}>
          กำลังผลิต
        </Typography>
        <Typography fontSize={14} fontWeight={400} color={"#333333"}>
          {data.in_progress}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          height: "100%",
          alignItems: "center",
          padding: "8px 16px",
          justifyContent: "space-between",
          background: "#E6F9F0",
          borderRadius: "8px",
        }}
      >
        <Typography fontSize={14} fontWeight={600} color={"#22915A"}>
          เสร็จสิ้น
        </Typography>
        <Typography fontSize={14} fontWeight={400} color={"#333333"}>
          {data.finished}
        </Typography>
      </Box>
    </Box>
  );
};

export default GanttStatus;
