import {
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  Alert,
} from "@mui/material";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useWatch } from "react-hook-form";
import { useSalesOrderOption } from "../../../../hooks/Sales/use-sales-order-option";
import { useSalesOrderCreate } from "../../../../hooks/Sales/use-sales-order-create";

import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";

import {
  ICreatedBy,
  IDefaultForm,
  IMenuOption,
} from "../../../../types/global";
import EmployeeListForm from "../../EmployeeList";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";
import CustomizedMenuOptions from "../../../Custom/CustomizedMenuOptions";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import CustomizedChips from "../../../Custom/CustomizedChips";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import OCRStatus from "../../../UI/OCRStatus";
import { Theme } from "@mui/system";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  UseMutateAsyncFunction,
} from "@tanstack/react-query";
import {
  ActivityType,
  DocumentInput,
  Exact,
  GetSalesWorkflowProgressQuery,
  ModelType,
  SalesActivityLogDocumentType,
  SalesApprovalTemplatesQuery,
  SalesDocumentNextStatusMutation,
  SalesDocumentType,
  SalesOrderQuery,
  SalesOrderUpdateInput,
  SalesOrderUpdateMutation,
  SalesOrderWhereUniqueInput,
  SalesUniqueIdQuery,
  Status,
  useGetSalesWorkflowProgressQuery,
  useSalesApprovalTemplatesQuery,
  useSalesDocumentCancelMutation,
  useSalesUniqueIdQuery,
} from "../../../../generated/sales";
import { useSnackbar } from "notistack";
import {
  copySalesOrderFormatter,
  createDeliveryOrderFromOrder,
  createGoodsIssueFromOrder,
  createManufactureOrderFromOrder,
  createPurchaseRequestFromOrder,
  createSalesReturnFromOrder,
  salesOrderUpdatePayloadFormatter,
} from "../../../../utils/Formatter/SalesOrder";
import { useConfirmation } from "../../../../hooks/use-confrimation";
import Confirmation from "../../../UI/Confirmation";
import { useStateContext } from "../../../../contexts/auth-context";
import SalesEmployeeListForm from "../../EmployeeList/SalesEmployeeList";
import ModalUI from "../../../UI/ModalUI";
import CustomizedButton from "../../../Custom/CustomizedButton";
import { ISalesOrder } from "../../../../types/Sales/salesOrder";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import { useModal } from "../../../../hooks/use-modal";
import ItemListModal from "../../../Table/ItemListModal";
import { AgGridReact } from "ag-grid-react";
import { ISalesItemList } from "../../../../types/Sales";
import { salesOrderSchema } from "./schema";
import { useActiveStep } from "../../../../hooks/use-active-step";
import StepperUI from "../../../UI/StepperUI";
import { useActivityLogCreate } from "../../../../hooks/use-global-activity-log";
import { ActivityLog } from "../../../UI/SlideInPanel/ActivityLog";
import TaxInvoiceModal from "../../../UI/TaxInvoiceModal";
import {
  salesOrderPdfTypeEngToThai,
  salesOrderPdfTypeThaiToEng,
} from "../../../../utils/Formatter/Global";
import ApprovalListForm from "../../ApprovalList";
import showApprovalList from "../../ApprovalList/ApproverList";

type Props = IDefaultForm & {
  editClickHandler: () => void;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<SalesOrderQuery, unknown>>;
  update: UseMutateAsyncFunction<
    SalesOrderUpdateMutation,
    Error,
    Exact<{
      uniqueInput: SalesOrderWhereUniqueInput;
      updateInput: SalesOrderUpdateInput;
    }>,
    unknown
  >;
  updateStatus: UseMutateAsyncFunction<
    SalesDocumentNextStatusMutation,
    Error,
    Exact<{
      documentInput: DocumentInput;
    }>,
    unknown
  >;
  taxInvoiceNumber: string | null | undefined;
};

const SalesOrderHeader = ({
  control,
  errors,
  setValue,
  getValues,
  disabled,
  editClickHandler,
  refetch,
  update,
  updateStatus,
  handleSubmit,
  taxInvoiceNumber,
}: Props) => {
  const gridRef = useRef<AgGridReact<ISalesItemList>>(null);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [createdDateIsOpen, setCreatedDateIsOpen] = useState<boolean>(false);
  const [issueDateIsOpen, setIssueDateIsOpen] = useState<boolean>(false);
  const [dueDateIsOpen, setDueDateIsOpen] = useState<boolean>(false);
  const [taxInvoiceIsOpen, setTaxInvoiceIsOpen] = useState<boolean>(false);
  const [deliveryDateIsOpen, setDeliveryDateIsOpen] = useState<boolean>(false);

  const {
    state: { authUser },
  } = useStateContext();

  const graphQLClientWithHeaderSales: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const { data: approvalTemplatesData } =
    useSalesApprovalTemplatesQuery<SalesApprovalTemplatesQuery>(
      graphQLClientWithHeaderSales,
      {}
    );

  const { refetch: getUniqueId } = useSalesUniqueIdQuery<SalesUniqueIdQuery>(
    graphQLClientWithHeaderSales,
    {
      modelType: ModelType.SalesOrder,
    },
    {
      enabled: false,
    }
  );

  const uniqueId = useWatch({ control, name: "unique_id" });

  const { data: workflowProgressData, isLoading: isWorkflowProgressLoading, refetch: refetchWorkflowProgress } =
    useGetSalesWorkflowProgressQuery<GetSalesWorkflowProgressQuery>(
      graphQLClientWithHeaderSales,
      {
        input: {
          reference_document_type: SalesDocumentType.SalesOrder,
          reference_unique_id: uniqueId,
        },
      },
      {
        enabled: Boolean(uniqueId),
        staleTime: 0,
        cacheTime: 0,
      }
    );

  const { mutateAsync: cancel } = useSalesDocumentCancelMutation<Error>(
    graphQLClientWithHeaderSales
  );

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const reference_unique_id_list = useWatch({
    control,
    name: "reference_unique_id_list",
  });

  const tax_invoice_date = useWatch({
    control,
    name: "tax_invoice_date",
  });

  const ocr_log = useWatch({
    control,
    name: "ocr_log",
  });

  const printOptions: IMenuOption[] = [
    {
      value: `${t("sales.order.index")}`,
      disabled: !id,
    },
    {
      value: t("global.custom_document_print"),
      disabled: !id,
    },
    // {
    //   value: salesOrderPdfTypeEngToThai["tax-receipt"],
    //   disabled: !tax_invoice_date,
    // },
    {
      value: salesOrderPdfTypeEngToThai["sales-invoice"],
      disabled: !tax_invoice_date,
    },
    {
      value: salesOrderPdfTypeEngToThai["tax-invoice"],
      disabled: !tax_invoice_date,
    },
    // {
    //   value: salesOrderPdfTypeEngToThai["receipt"],
    //   disabled: !tax_invoice_date,
    // },
  ];

  const watchMainStatus = useWatch({ control, name: "main_status" });

  useEffect(() => {
    if (status === "approved" || status === "ejected") {
      refetchWorkflowProgress();
    }
  }, [status, refetchWorkflowProgress]);

  useEffect(() => {}, [approvalTemplatesData]);
  const isApprovalEnabled =
    approvalTemplatesData?.approvalTemplates &&
    approvalTemplatesData.approvalTemplates.length > 0
      ? approvalTemplatesData.approvalTemplates.some(
          (template) =>
            template?.is_have_approval &&
            template.document_type === "sales_order"
        )
      : false;

  const showApprovalLists = showApprovalList({
    workflowProgressData,
    isApprovalEnabled,
    documentType: "sales",
  });

  const steps = isApprovalEnabled
    ? [
        {
          label: t("status.draft"),
          value: "draft",
        },
        {
          label: t("status.wait_approve"),
          value: "wait_approve",
        },
        {
          label: t("status.approved"),
          value: "approved",
        },
        {
          label: t("status.finished"),
          value: "finished",
        },
      ]
    : [
        {
          label: t("status.draft"),
          value: "draft",
        },
        {
          label: t("status.approved"),
          value: "approved",
        },
        {
          label: t("status.finished"),
          value: "finished",
        },
      ];

  const {
    activeStep,
    percentage,
    error: stepperError,
  } = useActiveStep(
    status,
    watchMainStatus,
    SalesDocumentType.SalesOrder,
    undefined,
    isApprovalEnabled
  );

  const selectModifyOptions = useSalesOrderOption(status, taxInvoiceNumber);
  const { selectCreateOptions, disabledCreateOptions } =
    useSalesOrderCreate(status);

  const {
    modal: taxInvoiceNumberModal,
    openModalHandler: openTaxInvoiceNumberModal,
    closeModalHandler: closeTaxInvoiceNumberModal,
  } = useModal();

  const { salesActivityLogCreate } = useActivityLogCreate();

  const cancelDocumentHandler = async () => {
    await cancel({
      documentInput: {
        reference_document_type: SalesDocumentType.SalesOrder,
        unique_id: id ? id : "",
      },
    });
    try {
      await salesActivityLogCreate({
        activity_type: ActivityType.StatusChange,
        document_type: SalesActivityLogDocumentType.SalesOrder,
        reference_id: getValues("id"),
        activity_detail: {
          curr_status: Status.Cancelled,
        },
      });
      enqueueSnackbar("ยกเลิกใบสั่งขายสำเร็จ", {
        variant: "success",
      });
      await refetch();
    } catch (error) {
      enqueueSnackbar("ยกเลิกใบสั่งขายไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const copyDocumentHandler = () => {
    const salesOrder = getValues();
    navigate("/sales/order/add", {
      state: copySalesOrderFormatter(salesOrder),
    });
  };

  const saveInvoiceHandler = async (data: ISalesOrder) => {
    try {
      const payload = salesOrderUpdatePayloadFormatter(
        data,
        "finished"
      ) as SalesOrderUpdateInput;
      await update({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: payload,
      });
      await updateStatus({
        documentInput: {
          reference_document_type: SalesDocumentType.SalesOrder,
          unique_id: data.unique_id,
        },
      });
      enqueueSnackbar("บันทึกใบกำกับภาษีสำเร็จ", {
        variant: "success",
      });
      await refetch();
    } catch (err) {
      enqueueSnackbar("บันทึกใบกำกับภาษีไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(cancelDocumentHandler);

  const {
    confirmation: copyConfirmation,
    openConfirmationHandler: openCopyConfirmation,
    closeConfirmationHandler: closeCopyConfirmation,
    submitConfirmationHandler: submitCopyConfirmation,
  } = useConfirmation(copyDocumentHandler);

  const {
    confirmation: saveInvoiceModal,
    openConfirmationHandler: openSaveInvoiceModal,
    closeConfirmationHandler: closeSaveInvoiceModal,
    submitConfirmationHandler: submitSaveInvoiceModal,
  } = useConfirmation(
    handleSubmit ? handleSubmit(saveInvoiceHandler) : undefined
  );

  const generateUniqueId = useCallback(async () => {
    const { data } = await getUniqueId();
    setValue("unique_id", data?.utilGetUniqueId);
  }, [getUniqueId, setValue]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const selectedItemToMo = async () => {
    const salesOrder = getValues();
    const selectedItems = gridRef?.current?.api.getSelectedRows();
    const currentUser: ICreatedBy = {
      user_unique_id: authUser?.unique_id || "",
      email: authUser?.email || "",
      first_name: authUser?.first_name || "",
      last_name: authUser?.last_name,
      img_url: authUser?.img_url,
    };

    localStorage.setItem(
      "mo state",
      JSON.stringify(
        await createManufactureOrderFromOrder(
          salesOrder,
          selectedItems?.[0] || salesOrderSchema.item_list?.[0],
          currentUser
        )
      )
    );

    window.open("/manufacture/order/add", "_blank");
  };

  const itemIsManufactured = getValues("item_list");
  const filteredItems = itemIsManufactured.filter((item: any) => {
    return item.is_manufactured === true;
  });
  return (
    <Fragment>
      <Grid container mb={3}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mb={2}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
            }}
          >
            <Typography variant="h5">{t("sales.order.index")}</Typography>
            <CustomizedStatus status={status} />
            {ocr_log && <OCRStatus control={control} />}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent={isMobile ? "flex-start" : "flex-end"}
          >
            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={"พิมพ์เอกสาร"}
                options={printOptions}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  if (value.innerText === "ใบสั่งขาย") {
                    navigate(
                      `/sales/order/${encodeURIComponent(id ?? "")}/pdf`
                    );
                  }
                  if (value.innerText === "รูปแบบกำหนดเอง") {
                    navigate(
                      `/sales/order/${encodeURIComponent(id ?? "")}/custom-pdf`
                    );
                  }

                  if (salesOrderPdfTypeThaiToEng[value.innerText]) {
                    navigate(
                      `/sales/order/${encodeURIComponent(id ?? "")}/pdf?type=` +
                        salesOrderPdfTypeThaiToEng[value.innerText]
                    );
                  }
                }}
                disabled={!id}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={"ตัวเลือก"}
                options={selectModifyOptions}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case "แก้ไข":
                      editClickHandler();
                      break;
                    case "คัดลอก":
                      openCopyConfirmation();
                      break;
                    case "ยกเลิก":
                      openConfirmationHandler();
                      break;
                    case "บันทึกใบแจ้งหนี้":
                      openSaveInvoiceModal();
                      break;
                    case "บันทึกใบกำกับภาษี":
                      openTaxInvoiceNumberModal();
                      break;
                    default:
                      break;
                  }
                }}
                disabled={!id}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={"สร้าง"}
                options={selectCreateOptions}
                onSelect={async (e) => {
                  const value = e.target as HTMLElement;
                  const salesOrder = getValues();
                  const currentUser: ICreatedBy = {
                    user_unique_id: authUser?.unique_id || "",
                    email: authUser?.email || "",
                    first_name: authUser?.first_name || "",
                    last_name: authUser?.last_name,
                    img_url: authUser?.img_url,
                  };
                  switch (value.innerText) {
                    case "ใบขอซื้อ":
                      navigate("/purchase/request/add", {
                        state: createPurchaseRequestFromOrder(salesOrder),
                      });
                      break;
                    case "ใบสั่งผลิต":
                      openModalHandler();

                      break;
                    case "ใบส่งของ":
                      navigate("/logistic/delivery_order/add", {
                        state: createDeliveryOrderFromOrder(salesOrder),
                      });
                      break;
                    case "ใบนำออก":
                      navigate("/inventory/goods_issue/add", {
                        state: await createGoodsIssueFromOrder(
                          salesOrder,
                          currentUser
                        ),
                      });
                      break;
                    case "ใบรับคืน":
                      navigate("/sales/return/add", {
                        state: createSalesReturnFromOrder(salesOrder),
                      });
                      break;
                    default:
                  }
                }}
                disabled={disabledCreateOptions}
              />
            </Grid>
            {id && (
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <CustomizedTooltip
                  title="ดูความเคลื่อนไหว"
                  enterNextDelay={200}
                >
                  <IconButton
                    onClick={() => {
                      setOpenDrawer(true);
                    }}
                    color="primary"
                  >
                    <RestoreOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <StepperUI
        steps={steps}
        activeStep={activeStep}
        error={stepperError}
        percentageProgress={percentage}
      />
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mb={3}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography fontWeight="bold">{t("sales.unique_id")}</Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={6} lg={6} xl={6}>
              <ControlledTextField
                name="unique_id"
                control={control}
                error={Boolean(errors?.unique_id)}
                helperText={errors?.unique_id && errors?.unique_id.message}
                disabled={Boolean(id)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={3} lg={3} xl={3}>
              {!id && (
                <CustomizedTooltip
                  title="เรียกเลขที่เอกสารใหม่"
                  enterNextDelay={200}
                >
                  <IconButton onClick={generateUniqueId} color="primary">
                    <RestartAltOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography fontWeight="bold">
                {t("sales.reference_unique_id")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={6} lg={6} xl={6}>
              {reference_unique_id_list && reference_unique_id_list.length > 0
                ? reference_unique_id_list.map(
                    (reference_unique_id: string) => (
                      <CustomizedChips
                        key={reference_unique_id}
                        onClick={() =>
                          window.open(
                            `/sales/quotation/${encodeURIComponent(
                              reference_unique_id
                            )}`,
                            "_blank"
                          )
                        }
                        value={reference_unique_id}
                        color="primary"
                      />
                    )
                  )
                : "-"}
            </Grid>
            <Grid item xs={1} sm={1} md={3} lg={3} xl={3} />
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography fontWeight="bold">
                {t("sales.external_reference_id")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={6} lg={6} xl={6}>
              <ControlledTextField
                name="external_reference_id"
                control={control}
                error={Boolean(errors?.external_reference_id)}
                helperText={
                  errors?.external_reference_id &&
                  errors?.external_reference_id.message
                }
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={3} lg={3} xl={3} />
            {taxInvoiceNumber != null && (
              <>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Typography fontWeight="bold">
                    {t("sales.tax_invoice_number")}
                  </Typography>
                </Grid>
                <Grid item xs={11} sm={11} md={6} lg={6} xl={6}>
                  <Typography>{taxInvoiceNumber}</Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={3} lg={3} xl={3} />
              </>
            )}
            {!isWorkflowProgressLoading && showApprovalLists && (
              <>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Typography fontWeight="bold">
                    {t("sales.approver_list")}
                  </Typography>
                </Grid>
                <Grid item xs={11} sm={11} md={9} lg={9} xl={9}>
                  <ApprovalListForm
                    control={control}
                    documentType="sales_order"
                    approvalTemplates={
                      approvalTemplatesData?.approvalTemplates || []
                    }
                    workflowProgress={
                      workflowProgressData?.salesWorkflowProgressByDocument ||
                      []
                    }
                    data={getValues("flag_status")}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography fontWeight="bold">
                {t("sales.employee_list")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={9} lg={9} xl={9}>
              <EmployeeListForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                disabled={disabled}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography fontWeight="bold">
                {t("sales.sales_contact_list")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={9} lg={9} xl={9}>
              <SalesEmployeeListForm
                control={control}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          mb={isMobile ? 2 : undefined}
        >
          <Grid
            container
            spacing={1}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
          >
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledDatePicker
                name="created_date"
                control={control}
                error={errors.created_date}
                open={createdDateIsOpen}
                onClose={() => setCreatedDateIsOpen(false)}
                onOpen={() => setCreatedDateIsOpen(true)}
                label={t("date.created_date")}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledDatePicker
                name="issue_date"
                control={control}
                error={errors.issue_date}
                open={issueDateIsOpen}
                onClose={() => setIssueDateIsOpen(false)}
                onOpen={() => setIssueDateIsOpen(true)}
                label={t("date.issue_date")}
                disabled={disabled}
                required
              />
            </Grid>
            <Box width={"100%"} />
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledDatePicker
                name="due_date"
                control={control}
                error={errors.due_date}
                open={dueDateIsOpen}
                onClose={() => setDueDateIsOpen(false)}
                onOpen={() => setDueDateIsOpen(true)}
                label={t("date.due_date")}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledDatePicker
                name="delivery_date"
                control={control}
                error={errors.delivery_date}
                open={deliveryDateIsOpen}
                onClose={() => setDeliveryDateIsOpen(false)}
                onOpen={() => setDeliveryDateIsOpen(true)}
                label={t("date.delivery_date")}
                disabled={disabled}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
            sx={{ mt: 0.5 }}
          >
            {tax_invoice_date && (
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <ControlledDatePicker
                  name="tax_invoice_date"
                  control={control}
                  error={errors.tax_invoice_date}
                  open={taxInvoiceIsOpen}
                  onClose={() => setTaxInvoiceIsOpen(false)}
                  onOpen={() => setTaxInvoiceIsOpen(true)}
                  label={t("sales.tax_invoice_date")}
                  disabled={true}
                />
              </Grid>
            )}
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledTextField
                type="number"
                label={t("sales.credit_day")}
                control={control}
                name="credit_day"
                error={Boolean(errors?.credit_day)}
                helperText={errors?.credit_day && errors?.credit_day?.message}
                disabled={disabled}
                onChange={(e, field) => {
                  const parseIntValue = parseInt(e.target.value);
                  field.onChange(parseIntValue);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Confirmation
        title="ยืนยันหากต้องการยกเลิกเอกสาร"
        message="หากยกเลิกแล้ว จะไม่สามารถเปลี่ยนแปลงได้"
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
      <Confirmation
        title="ยืนยันหากต้องการคัดลอกเอกสาร"
        open={copyConfirmation}
        handleClose={closeCopyConfirmation}
        action={submitCopyConfirmation}
      />
      <ModalUI
        title="บันทึกใบแจ้งหนี้"
        open={saveInvoiceModal}
        handleClose={closeSaveInvoiceModal}
        maxWidth="xs"
        fullWidth
        action={
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
              gap: ".5rem",
            }}
          >
            <CustomizedButton
              title="ยกเลิก"
              variant="outlined"
              onClick={closeSaveInvoiceModal}
            />
            <CustomizedButton
              title="ยืนยัน"
              variant="contained"
              onClick={submitSaveInvoiceModal}
            />
          </Box>
        }
      >
        <ControlledTextField
          label={t("sales.external_reference_id")}
          control={control}
          name="external_reference_id_confirmation"
        />
        <Box
          sx={{
            mt: 2,
          }}
        >
          <Alert severity="info" style={{ backgroundColor: "#e5f6fd" }}>
            หากบันทึกแล้วจะไม่สามารถเปลี่ยนแปลงได้
          </Alert>
        </Box>
      </ModalUI>
      <TaxInvoiceModal
        taxInvoiceNumberModal={taxInvoiceNumberModal}
        closeTaxInvoiceNumberModal={closeTaxInvoiceNumberModal}
        refetch={refetch}
      />
      <ItemListModal
        gridRef={gridRef}
        itemModal={modal}
        closeItemModalHandler={closeModalHandler}
        addItemsHandler={selectedItemToMo}
        // itemList={getValues("item_list")}
        itemList={filteredItems}
        documentType="item"
        disabled={disabled}
      />
      <ActivityLog
        service="sales"
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
        documentId={getValues("id")}
        documentType="sales_order"
      />
    </Fragment>
  );
};

export default SalesOrderHeader;
