import { GraphQLClient } from "graphql-request";
import { EntityTypeEnum } from "../../generated/creatable";
import { InventoryDocumentType } from "../../generated/inventory";
import { SalesDocumentType, SalesOrderQuery } from "../../generated/sales";
import { ITEMS_SKU_AGGRID } from "../../services/AgGrid/InventoryAgGrid";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { ISalesItemList } from "../../types/Sales";
import { ISalesOrder } from "../../types/Sales/salesOrder";
import { IAttachment, ICreatedBy } from "../../types/global";
import { itemListToTraceEntryListFormatter, formatContactName } from "./Global";
import { ITEM_STOCK_UOMS } from "../../services/AgGrid/InventoryAgGrid";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
// import * as Splitter from "../addressSpliter";
import {
  BOMS_AGGRID,
  ROUTING_FIND_UNIQUE,
} from "../../services/AgGrid/ManufactureAgGrid";


export const salesOrderCreatePayloadFormatter = (
  data: ISalesOrder,
  status: string
) => {
  const {
    id,
    item_list,
    customer_contact,
    tag_list,
    created_date,
    ocr_log,
    is_ocr_create,
    ...otherData
  } = data;
  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.name) : [];
  const formatItemList = item_list.map(({ uom_group, ...otherItem }) => ({
    ...otherItem,
    reference_document_type: SalesDocumentType.SalesOrder,
    reference_unique_id: data.unique_id,
  }));

  const { unique_id_name, ...customer } = customer_contact;
  const formatOCRLog = ocr_log
    ? { ...ocr_log, body_json: undefined }
    : undefined;
  const formatPayload = {
    ...otherData,
    ocr_log: formatOCRLog,
    shipping_cost: otherData.shipping_cost || 0,
    additional_discount: otherData.additional_discount || 0,
    customer_contact: customer,
    item_list: formatItemList,
    tag_list: formatTagList,
    sub_status: status,
  };
  return formatPayload;
};

export const salesOrderUpdatePayloadFormatter = (
  data: ISalesOrder,
  status: string,
  isNotApprove?: boolean
) => {
  const {
    id,
    unique_id,
    main_status,
    flag_status,
    aggrid_status,
    customer_contact,
    updated_date,
    item_list,
    tag_list,
    created_by,
    created_date,
    external_reference_id,
    external_reference_id_confirmation,
    tax_invoice_number,
    tax_invoice_date,
    ocr_log,
    ...otherData
  } = data;
  const { unique_id_name, ...customer } = customer_contact;

  const formatItemList = item_list.map(({ uom_group, ...otherItem }) => ({
    ...otherItem,
    reference_document_type: SalesDocumentType.SalesOrder,
    reference_unique_id: data.unique_id,
    qty_invoiced: status === "finished" ? otherItem.qty : 0,
  }));

  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.name) : [];

  let formatExternalReferenceId: string | undefined = external_reference_id;

  if (
    external_reference_id_confirmation &&
    external_reference_id_confirmation.trim().length > 0
  ) {
    if (external_reference_id && external_reference_id.trim().length > 0) {
      const externalToArray = external_reference_id.trim().split(",");
      const newExternalConfirmation = [
        ...externalToArray,
        external_reference_id_confirmation,
      ];
      const formatNewExternal = newExternalConfirmation.join(", ");
      formatExternalReferenceId = formatNewExternal;
    } else {
      formatExternalReferenceId = external_reference_id_confirmation;
    }
  }

  const formatPayload = {
    ...otherData,
    shipping_cost: otherData.shipping_cost || 0,
    additional_discount: otherData.additional_discount || 0,
    customer_contact: customer,
    // flag_status:
    //   flag_status?
    //   isNotApprove && flag_status
    //     ? !flag_status.find((fl) => fl === "not_approved")
    //       ? [...flag_status, "not_approved"]
    //       : flag_status
    //     : undefined
    //   : undefined,
    flag_status: (() => {
      if (flag_status) {
        if (flag_status.includes("not_approved")) {
          if (["wait_approve", "approved"].includes(status)) {
            return flag_status.filter((fl) => fl !== "not_approved");
          }
          return flag_status;
        }
        if (isNotApprove) {
          return [...flag_status, "not_approved"];
        }
        return flag_status;
      }
      if (isNotApprove) {
        return ["not_approved"];
      }
      return undefined;
    })(),
    item_list: formatItemList,
    tag_list: formatTagList,
    sub_status: status,
    external_reference_id:
      status === "finished" ? formatExternalReferenceId : external_reference_id,
  };
  return formatPayload;
};

export const salesOrderQueryFormatter = async (data: ISalesOrder) => {
  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");
  const allItemListUniqueId = data.item_list.map((item) => item.item_unique_id);
  const { itemSkuDetailsFindManyAggrid } =
    await graphQLClientWithHeaderItem.request(ITEMS_SKU_AGGRID, {
      aggridInput: {
        startRow: 0,
        endRow: allItemListUniqueId.length,
        filterModel: {
          sku_name: {
            filterType: "set",
            values: allItemListUniqueId,
          },
        },
      },
    });
  const { data: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;

  let formatItemList: ISalesItemList[] = [];

  data.item_list.forEach((item) => {
    const foundItemIndex = itemSkuDetails.findIndex(
      (realItem: any) => realItem.sku_name === item.item_unique_id
    );

    formatItemList.push({
      ...item,
      uom_group: itemSkuDetails[foundItemIndex]?.item_sku.item.uom_group,
    });
  });

  const formatCustomer = {
    ...data.customer_contact,
    unique_id_name: `${data.customer_contact_unique_id} - ${data.customer_contact.name}`,
  };

  const formatPayload = {
    ...data,
    customer_contact: formatCustomer,
    item_list: formatItemList,
  };
  return formatPayload;
};

export const salesOrderFromOCRFormatter = (data: ISalesOrder) => {
  const {
    ocr_attrachment,
    ocr_confidence,
    ocr_status,
    ocr_tracking_id,
    ocr_filename,
    ocr_task_check_url,
    ocr_human_check_status,
    ocr_human_check_time,
    ocr_project_id,
    uploaded_by,
    body_json,
  } = data.ocr_log;

  const { item_list, contact, ...otherFromOCR } = body_json;

  const foundBillingAddress = contact?.address_list?.find(
    (address: any) => address.address_type === "ที่อยู่จดทะเบียน"
  );

  const foundDeliveryAddress = contact?.address_list?.find(
    (address: any) => address.address_type === "ที่อยู่จัดส่ง"
  );

  const formatBillingAddress = foundBillingAddress
    ? {
        address: foundBillingAddress.address,
        sub_district: foundBillingAddress.sub_district,
        district: foundBillingAddress.district,
        province: foundBillingAddress.province,
        postal_code: foundBillingAddress.postal_code,
        country: foundBillingAddress.country,
      }
    : {
        address: "",
        sub_district: "",
        district: "",
        province: "",
        postal_code: "",
        country: "",
      };

  const formatDeliveryAddress = foundDeliveryAddress
    ? {
        address_type: foundDeliveryAddress.address_type,
        is_same_as_default_address:
          foundDeliveryAddress.is_same_as_default_address,
        address_contact_name: foundDeliveryAddress.address_contact_name,
        address_contact_phone: foundDeliveryAddress.address_contact_phone,
        address: foundDeliveryAddress.address,
        sub_district: foundDeliveryAddress.sub_district,
        district: foundDeliveryAddress.district,
        province: foundDeliveryAddress.province,
        postal_code: foundDeliveryAddress.postal_code,
        country: foundDeliveryAddress.country,
      }
    : {
        address_type: "",
        is_same_as_default_address: false,
        address_contact_name: "",
        address_contact_phone: "",
        address: "",
        sub_district: "",
        district: "",
        province: "",
        postal_code: "",
        country: "",
      };

  const customer_contact = contact
    ? {
        unique_id_name: `${contact.unique_id} - ${formatContactName(
          contact.contact_type,
          contact.contact_type_detail,
          contact.title_name || "",
          contact.main_contact_name,
          contact.secondary_contact_name || ""
        )}`,
        name: formatContactName(
          contact.contact_type,
          contact.contact_type_detail,
          contact.title_name || "",
          contact.main_contact_name,
          contact.secondary_contact_name || ""
        ),
        phone:
          contact.contact_channel_list?.find(
            (contact_channel: any) =>
              contact_channel.contact_channel_type === "เบอร์โทรศัพท์"
          )?.contact_channel_name || "",
        identity_no: contact.identity_no,
        branch: contact.branch_name,
        fax:
          contact.contact_channel_list?.find(
            (contact_channel: any) =>
              contact_channel.contact_channel_type === "แฟกซ์"
          )?.contact_channel_name || "",
        email:
          contact.contact_channel_list?.find(
            (contact_channel: any) =>
              contact_channel.contact_channel_type === "Email"
          )?.contact_channel_name || "",
        billing_address: formatBillingAddress,
        delivery_address: formatDeliveryAddress,
      }
    : {};

  const formattedItemList = item_list.map((item: any) => {
    return {
      unique_id: uuidv4(),
      ...item,
    };
  });

  const ocr_log = {
    ocr_attrachment,
    ocr_confidence,
    ocr_status,
    ocr_tracking_id,
    ocr_filename,
    ocr_task_check_url,
    ocr_human_check_status,
    ocr_human_check_time,
    ocr_project_id,
    uploaded_by,
    ocr_uploaded_time: dayjs().toDate(),
    ocr_modified_time: dayjs().toDate(),
  };

  return {
    ...data,
    ...otherFromOCR,
    ocr_log,
    created_date: dayjs().toDate(),
    issue_date: dayjs(),
    due_date: dayjs(),
    delivery_date: dayjs(),
    customer_contact_unique_id: contact?.unique_id,
    customer_contact,
    item_list: formattedItemList,
  };
};

export const copySalesOrderFormatter = (
  data?: SalesOrderQuery["salesOrder"]
) => {
  if (data) {
    const {
      id,
      unique_id,
      created_date,
      issue_date,
      due_date,
      delivery_date,
      created_by,
      external_reference_id,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      updated_date,
      reference_unique_id_list,
      tax_invoice_number,
      tax_invoice_date,
      ocr_log,
      ...otherData
    } = data;

    const formatItemList =
      item_list &&
      item_list.map(
        ({
          qty_invoiced,
          qty_returned,
          qty_shipped,
          qty_to_ship,
          qty_manufactured,
          qty_issued,
          ...otherItemList
        }) => ({
          ...otherItemList,
          unique_id: uuidv4(),
        })
      );

    return {
      ...otherData,
      copied_id: id,
      copied_unique_id: unique_id,
      item_list: formatItemList,
    };
  }
};

export const createSalesReturnFromOrder = (data: ISalesOrder) => {
  if (data) {
    const {
      id,
      created_by,
      unique_id,
      created_date,
      issue_date,
      due_date,
      delivery_date,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      updated_date,
      tax_invoice_number,
      tax_invoice_date,
      payment_type,
      ocr_log,
      ...otherData
    } = data;

    const formatItemList =
      item_list &&
      item_list.map(
        ({
          reference_document_type,
          reference_unique_id,
          qty,
          ...otherItemList
        }) => {
          const { uom_group, uom } = otherItemList;
          let stock_qty: number = 1;
          if (uom_group) {
            if (uom !== uom_group.base_uom?.unique_id) {
              if (
                uom_group.uom_conversion_list &&
                uom_group.uom_conversion_list.length > 0
              ) {
                const conversionUom = uom_group.uom_conversion_list.find(
                  (conversion) => conversion.target_uom_unique_id === uom
                );
                if (conversionUom) {
                  const targetUomQty =
                    conversionUom.base_uom_rate / conversionUom.target_uom_rate;
                  stock_qty = targetUomQty;
                }
              }
            }
          }

          return {
            ...otherItemList,
            qty: 1,
            so_qty: qty,
            stock_qty: stock_qty,
          };
        }
      );

    return {
      ...otherData,
      reference_unique_id_list: [data.unique_id],
      item_list: formatItemList,
    };
  }
};

export const createPurchaseRequestFromOrder = (data: ISalesOrder) => {
  if (data) {
    const {
      id,
      created_by,
      unique_id,
      created_date,
      issue_date,
      due_date,
      delivery_date,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      updated_date,
      sales_contact_list,
      customer_contact,
      customer_contact_unique_id,
      account_id,
      tag_list,
      sales_channel,
      additional_discount,
      additional_discount_type,
      credit_day,
      net_amount,
      pre_vat_amount,
      price_vat_type,
      shipping_cost,
      sub_total,
      total_amount,
      vat_0_amount,
      vat_7_amount,
      vat_amount,
      vat_exempted_amount,
      withholding_tax_amount,
      tax_invoice_number,
      tax_invoice_date,
      payment_type,
      ocr_log,
      ...otherData
    } = data;

    const formatItemList =
      item_list &&
      item_list.map(
        ({
          reference_document_type,
          reference_unique_id,
          qty_invoiced,
          qty_ordered,
          qty_shipped,
          qty_to_ship,
          qty_returned,
          qty_manufactured,
          so_qty,
          volume,
          weight,
          unique_id,
          is_manufactured,
          is_purchasable,
          is_saleable,
          is_stockable,
          ...otherItemList
        }) => ({
          ...otherItemList,
          qty_ordered: 0,
          reference_line_item: {
            reference_document_type: SalesDocumentType.SalesOrder,
            reference_unique_id: unique_id,
          },
        })
      );

    return {
      ...otherData,
      reference_unique_id_list: [data.unique_id],
      item_list: formatItemList,
    };
  }
};

export const createGoodsIssueFromOrder = async (
  data: ISalesOrder,
  currentUser: ICreatedBy
) => {
  if (data) {
    const { unique_id, item_list } = data;

    const item_unique_id_list = item_list.map(
      (item: any) => item.item_unique_id
    );

    const graphQLClientWithHeaderItem: GraphQLClient =
      createGraphQLClientWithMiddleware("item");

    const { itemStockUoms } = await graphQLClientWithHeaderItem.request(
      ITEM_STOCK_UOMS,
      {
        itemUniqueIdList: item_unique_id_list,
      }
    );

    const itemListWithStockUoms = item_list.map((item: any) => {
      const foundItemWithStockUom = itemStockUoms.find(
        (stockUom: any) => stockUom.unique_id === item.item_unique_id
      );

      return {
        ...item,
        uom: foundItemWithStockUom?.stock_uom?.unique_id ?? item.uom,
      };
    });

    const formatTraceEntry = itemListToTraceEntryListFormatter(
      itemListWithStockUoms,
      InventoryDocumentType.GoodsIssue,
      EntityTypeEnum.SalesOrder
    );

    return {
      type: "sales_order",
      reference_unique_id: unique_id,
      trace_entry_list: formatTraceEntry,
      created_by: currentUser,
    };
  }
};

export const createDeliveryOrderFromOrder = (data: ISalesOrder) => {
  if (data) {
    const {
      id,
      created_by,
      unique_id,
      created_date,
      issue_date,
      due_date,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      updated_date,
      sales_contact_list,
      account_id,
      tag_list,
      sales_channel,
      additional_discount,
      additional_discount_type,
      credit_day,
      net_amount,
      pre_vat_amount,
      price_vat_type,
      shipping_cost,
      sub_total,
      total_amount,
      vat_0_amount,
      vat_7_amount,
      vat_amount,
      vat_exempted_amount,
      withholding_tax_amount,
      template_remark_id,
      tax_invoice_number,
      tax_invoice_date,
      payment_type,
      ocr_log,
      ...otherData
    } = data;

    const formatItemList =
      item_list &&
      item_list.map(
        ({
          reference_document_type,
          reference_unique_id,
          qty_invoiced,
          qty_ordered,
          qty_issued,
          qty_shipped,
          qty_to_ship,
          qty_returned,
          qty_manufactured,
          so_qty,
          barcode,
          item_img_url,
          tracability,
          item_type,
          unique_id,
          is_manufactured,
          is_purchasable,
          is_saleable,
          is_stockable,
          ...otherItemList
        }) => ({
          ...otherItemList,
          unique_id: uuidv4(),
          sales_qty: otherItemList.qty,
          sales_uom: otherItemList.uom,
          reference_line_item: {
            reference_document_type: SalesDocumentType.SalesOrder,
            reference_unique_id: unique_id,
          },
        })
      );

    return {
      ...otherData,
      reference_unique_id_list: [data.unique_id],
      item_list: formatItemList,
    };
  }
};

export const createManufactureOrderFromOrder = async (
  data: ISalesOrder,
  itemList: ISalesItemList,
  currentUser: ICreatedBy
) => {
  const {
    id,
    created_by,
    unique_id,
    created_date,
    issue_date,
    due_date,
    aggrid_status,
    main_status,
    sub_status,
    flag_status,
    item_list,
    updated_date,
    sales_contact_list,
    account_id,
    tag_list,
    sales_channel,
    additional_discount,
    additional_discount_type,
    credit_day,
    net_amount,
    pre_vat_amount,
    price_vat_type,
    shipping_cost,
    sub_total,
    total_amount,
    vat_0_amount,
    vat_7_amount,
    vat_amount,
    vat_exempted_amount,
    withholding_tax_amount,
    template_remark_id,
    customer_contact,
    tax_invoice_number,
    tax_invoice_date,
    payment_type,
    ocr_log,
    ...otherData
  } = data;

  const {
    unique_id: itemUuid,
    item_unique_id,
    item_name,
    item_sku_desc,
    uom,
    remark,
    qty,
  } = itemList;

  const graphQLClientItem = createGraphQLClientWithMiddleware("item");

  const { itemSkuDetailsFindManyAggrid } = await graphQLClientItem.request(
    ITEMS_SKU_AGGRID,
    {
      aggridInput: {
        startRow: 0,
        endRow: 1,
        filterModel: {
          sku_name: { filterType: "set", values: [item_unique_id] },
        },
      },
    }
  );

  const itemSkuDetail = itemSkuDetailsFindManyAggrid.data?.[0];
  if (!itemSkuDetail) throw new Error("Item SKU details not found.");

  const graphQLClientManufacture =
    createGraphQLClientWithMiddleware("manufacture");

  let bomId: string | undefined,
    bomName: string | undefined,
    attachmentList: IAttachment[] = [];

  if (itemSkuDetail.item_sku?.item?.bom_id) {
    const { data: bomData } = await graphQLClientManufacture.request(
      BOMS_AGGRID,
      {
        aggridInput: {
          startRow: 0,
          endRow: 1,
          filterModel: {
            id: {
              filterType: "number",
              type: "equals",
              filter: itemSkuDetail.item_sku.item.bom_id,
            },
          },
        },
      }
    );

    bomId = itemSkuDetail.item_sku.item.bom_id;
    bomName = itemSkuDetail.item_sku.item.bom_detail?.name || "";

    if (bomData?.[0]?.attachment_list?.length) {
      attachmentList = [...bomData[0].attachment_list];
    }
  }

  let routingDetail,
    routingId,
    workOrderList: any[] = [],
    relatedUserList: ICreatedBy[] = [];

  if (itemSkuDetail.item_sku?.item?.routing_id) {
    routingId = itemSkuDetail.item_sku.item.routing_id;
    routingDetail = itemSkuDetail.item_sku.item.routing_detail || { name: "" };

    const { routing } = await graphQLClientManufacture.request(
      ROUTING_FIND_UNIQUE,
      {
        uniqueInput: { id: routingId },
      }
    );

    workOrderList = routing?.work_order_list || [];

    relatedUserList = [
      ...new Set(
        workOrderList
          .flatMap((order) => order.responsible_user_list || [])
          .map((user) => user.user_unique_id)
      ),
    ]
      .map((userId) => {
        const userData = workOrderList
          .flatMap((order) => order.responsible_user_list || [])
          .find((user) => user.user_unique_id === userId);
        return userData
          ? {
              user_unique_id: userId,
              email: userData.email,
              first_name: userData.first_name,
              last_name: userData.last_name,
              img_url: userData.img_url,
            }
          : null;
      })
      .filter(
        (user) => user && user.user_unique_id !== currentUser.user_unique_id
      ) as ICreatedBy[];

    if (routing?.attachment_list?.length) {
      attachmentList.push(...routing.attachment_list);
    }
  }

  return {
    ...otherData,
    reference_unique_id_list: [unique_id],
    reference_item_uuid: itemUuid,
    item_unique_id,
    item_name,
    item_description: item_sku_desc,
    uom,
    uom_unique_id: uom,
    type: itemSkuDetail.item_sku?.item?.manufacturing_type,
    bom_id: bomId,
    bom_name: bomName,
    attachment_list: attachmentList,
    routing_detail: routingDetail,
    routing_id: routingId,
    work_order_list: workOrderList,
    item_remark: remark,
    production_qty: qty,
    created_by: currentUser,
    related_user_list: relatedUserList,
  };
};
