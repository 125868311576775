import { useMemo, useEffect, useState } from "react";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { PurchaseApprovalTemplatesQuery, usePurchaseApprovalTemplatesQuery } from "../../generated/purchase";

const usePurchaseCheckApproval = (documentType: string) => {
  const graphQLClientWithHeaderPurchase = useMemo(
    () => createGraphQLClientWithMiddleware("purchase"),
    []
  );

  const { data: approvalTemplatesData, refetch } = usePurchaseApprovalTemplatesQuery<PurchaseApprovalTemplatesQuery>(
    graphQLClientWithHeaderPurchase, {}
  );

  const [isHaveApproval, setIsHaveApproval] = useState(false);

  useEffect(() => {
    refetch(); 
  }, [refetch]);

  useEffect(() => {
    setIsHaveApproval(
      approvalTemplatesData?.purchaseApprovalTemplates?.some(
        (template) => template?.document_type === documentType && template?.is_have_approval
      ) ?? false
    );
  }, [approvalTemplatesData, documentType]);

  return isHaveApproval;
};

export default usePurchaseCheckApproval;