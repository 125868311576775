import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useSnackbar } from "notistack";
import { GraphQLClient } from "graphql-request";

import { IRouting } from "../../../types/Manufacture/routing";
import { IBreadcrumbsAndMenu } from "../../../types/global";

import {
  routingSchema,
  routingValidation,
} from "../../../components/Form/Manufacture/Routing/schema";
import RoutingHeader from "../../../components/Form/Manufacture/Routing/Header";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import RoutingDetail from "../../../components/Form/Manufacture/Routing/Detail";
import RoutingRemark from "../../../components/Form/Manufacture/Routing/Remark";
import RoutingAttachment from "../../../components/Form/Manufacture/Routing/Attachment";
import RoutingTable from "../../../components/Form/Manufacture/Routing/Table";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { Stack } from "@mui/material";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";

import { useDisable } from "../../../hooks/use-disable";
import {
  RoutingQuery,
  useRoutingCreateMutation,
  useRoutingQuery,
  useRoutingUpdateMutation,
} from "../../../generated/manufacture";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useEffect, useState } from "react";
import {
  formatQueryRouting,
  formatterRouting,
} from "../../../utils/Formatter/Routing";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingMascot from "../../../components/UI/LoadingMascot";
import { useOnLeavePage } from "../../../hooks/use-on-leave-page";

const RoutingContainer = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useDisable();
  const { enqueueSnackbar } = useSnackbar();

  const [allLoading, setAllLoading] = useState<boolean>(false);

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  const methods = useForm<IRouting>({
    defaultValues: routingSchema,
    resolver: yupResolver(routingValidation),
  });

  const {
    handleSubmit,
    reset,
    getValues,
    formState: { dirtyFields },
  } = methods;

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("manufacture.index"),
      to: "/manufacture",
    },
    {
      name: t("manufacture.routing.index"),
      to: "/manufacture/routing",
    },
    {
      name: id
        ? getValues("name") || ""
        : `${t("button.create")}${t("manufacture.routing.index")}`,
    },
  ];

  const { data, isSuccess } = useRoutingQuery<RoutingQuery>(
    graphQLClient,
    {
      uniqueInput: { id: parseInt(id ?? "0") },
    },
    { enabled: !!id }
  );

  const { mutateAsync: routingCreate } = useRoutingCreateMutation<Error>(
    graphQLClient,
    {
      onMutate(variables) {
        setDisabled(true);
      },
      onSuccess(data, variables, context) {
        navigate("/manufacture/routing/" + data.routingCreate?.id);
        enqueueSnackbar("สร้างขั้นตอนงานสำเร็จ", {
          variant: "success",
        });
      },
      onError(error, variables, context) {
        const formatError = JSON.stringify(error);
        if (
          formatError.includes(
            "Unique constraint failed on the fields: (`name`)"
          )
        ) {
          enqueueSnackbar("ชื่อขั้นตอนงานนี้มีในระบบแล้ว", {
            variant: "error",
          });
        } else {
          enqueueSnackbar("สร้างขั้นตอนงานไม่สำเร็จ", {
            variant: "error",
          });
        }
        setDisabled(false);
      },
    }
  );

  const { mutateAsync: routingUpdate } = useRoutingUpdateMutation<Error>(
    graphQLClient,
    {
      onMutate(variables) {
        setDisabled(true);
      },
      onSuccess(data, variables, context) {
        const routingType = data.routingUpdate as IRouting;
        methods.reset(routingType);
        enqueueSnackbar("แก้ไขขั้นตอนงานสำเร็จ", {
          variant: "success",
        });
      },
      onError(error, variables, context) {
        const formatError = JSON.stringify(error);
        if (
          formatError.includes(
            "Unique constraint failed on the fields: (`name`)"
          )
        ) {
          enqueueSnackbar("ชื่อขั้นตอนงานนี้มีในระบบแล้ว", {
            variant: "error",
          });
        } else {
          enqueueSnackbar("แก้ไขขั้นตอนงานไม่สำเร็จ", {
            variant: "error",
          });
        }
        setDisabled(false);
      },
    }
  );

  useEffect(() => {
    if (id) {
      if (isSuccess) {
        const routingType = data.routing as IRouting;
        const format = formatQueryRouting(routingType);
        reset(format);
        setDisabled(true);
      }
    } else if (state) {
      reset({ ...routingSchema, ...state });
    } else {
      setDisabled(false);
    }
  }, [data?.routing, id, isSuccess, reset, setDisabled, state]);

  const isAttachmentDirty = Array.isArray(dirtyFields?.attachment_list)
    ? dirtyFields.attachment_list.some((field) =>
        Object.values(field).some(Boolean)
      ) // Check if any field inside is dirty
    : Boolean(dirtyFields?.attachment_list);

  useOnLeavePage(isAttachmentDirty);

  const onSubmit = async (data: IRouting) => {
    if (dirtyFields.attachment_list) {
      setAllLoading(true);
    }
    const { id, name, ...formatData } = await formatterRouting(data);
    setAllLoading(false);
    if (!id) await routingCreate({ createInput: { name, ...formatData } });
    else {
      await routingUpdate({
        updateInput: formatData,
        uniqueInput: { id: id },
      });
    }
  };

  const mainRoutingHandler = async () => {
    const routingType = data?.routing as IRouting;
    if (dirtyFields.attachment_list) {
      setAllLoading(true);
    }
    const { id, name, ...formatData } = await formatterRouting(routingType);
    setAllLoading(false);
    await routingUpdate({
      updateInput: { ...formatData, is_main_routing: true },
      uniqueInput: { id: id },
    });
  };

  return (
    <FormProvider {...methods}>
      <LoadingMascot isLoading={allLoading} />
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <RoutingHeader mainRoutingHandler={mainRoutingHandler} />
      <RoutingDetail />
      <RoutingTable />
      <RoutingRemark />
      <RoutingAttachment />
      {!disabled && (
        <BottomNavbar>
          <Stack direction="row" spacing={1} alignItems="center">
            <CustomizedButton
              variant="outlined"
              title={t("button.cancel")}
              onClick={() => navigate("/manufacture/routing")}
            />
            <CustomizedButton
              type="submit"
              variant="contained"
              title={t("button.save")}
              onClick={handleSubmit(onSubmit)}
            />
          </Stack>
        </BottomNavbar>
      )}
    </FormProvider>
  );
};

export default RoutingContainer;
