import { Control, Controller } from "react-hook-form";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  SelectProps,
  FormHelperText,
} from "@mui/material";
import { ISelectOption } from "../../types/global";

type ExtendedSelectProps = SelectProps & {
  name: string;
  label?: string;
  control: Control<any>;
  options: ISelectOption[];
  onChange?: SelectProps["onChange"];
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  required?: boolean;
  MenuProps?: SelectProps["MenuProps"];
  InputLabelProps?: SelectProps["sx"];
};

const ControlledSetSelect = ({
  name,
  label,
  control,
  options,
  error,
  helperText,
  disabled,
  required,
  MenuProps,
  onChange,
  InputLabelProps,
  ...rest
}: ExtendedSelectProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl fullWidth error={error}>
          <InputLabel
            sx={{
              ...InputLabelProps,
              transform: "translate(14px, 10px) scale(1)",
              "&.Mui-focused": {
                transform: "translate(14px, -9px) scale(0.75)",
              },
              "&.MuiFormLabel-filled": {
                transform: "translate(14px, -9px) scale(0.75)",
              },
            }}
          >
            {label}
          </InputLabel>
          <Select
            {...field}
            multiple
            input={<OutlinedInput label={label} />}
            renderValue={(selected) =>
              Array.isArray(selected)
                ? (selected as string[])
                    .map(
                      (value) =>
                        options.find((opt) => opt.value === value)?.label
                    )
                    .join(", ")
                : ""
            }
            MenuProps={MenuProps}
            disabled={disabled}
            required={required}
            size="small"
            onChange={(e, _) => {
              field.onChange(e);
              onChange && onChange(e, _);
            }}
            {...rest}
          >
            {options.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{ height: "37px" }}
              >
                <Checkbox
                  checked={
                    Array.isArray(field.value) &&
                    field.value.includes(option.value)
                  }
                />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default ControlledSetSelect;
