import { useTranslation } from "react-i18next";
import ModalUI from "./ModalUI";
import { Box, Grid, Typography } from "@mui/material";
import { useMemo, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import ControlledSelect from "../Controller/ControlledSelect";
import { IExporter, ISelectOption } from "../../types/global";
import ControlledDatePicker from "../Controller/ControlledDatePicker";
import RadioBox from "./RadioBox";
import CustomizedButton from "../Custom/CustomizedButton";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";

type Props = {
  open: boolean;
  closeModalHandler: () => void;
  typeOptions: ISelectOption[];
  exportHandler: (data: IExporter) => void;
  future?: boolean;
};

const ExporterModal = ({
  open,
  closeModalHandler,
  typeOptions,
  exportHandler,
  future,
}: Props) => {
  const exporterSchena: IExporter = {
    type: typeOptions[0]?.value,
    export_type: "all",
    start_date: null,
    end_date: null,
    date_type: "today",
  };

  const exporterValidation = Yup.object().shape({
    type: Yup.string().required("กรุณาเลือกประเภทข้อมูล"),
    start_date: Yup.date().when("export_type", {
      is: "date_range",
      then: (schema) => schema.required("กรุณาเลือกวันที่"),
      otherwise: (schema) => schema.nullable(),
    }),
    end_date: Yup.date().when("export_type", {
      is: "date_range",
      then: (schema) => schema.required("กรุณาเลือกวันที่"),
      otherwise: (schema) => schema.nullable(),
    }),
  });

  const { t } = useTranslation();
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
  } = useForm<IExporter>({
    defaultValues: exporterSchena,
    resolver: yupResolver<any>(exporterValidation),
  });

  const pastOption = useMemo(
    () => [
      {
        label: "กำหนดเอง",
        value: "user_defined",
      },
      {
        label: "วันนี้",
        value: "today",
      },
      { label: "เมื่อวาน", value: "yesterday" },
      { label: "7 วันที่แล้ว", value: "last_7_days" },
      { label: "30 วันที่แล้ว", value: "last_30_days" },
      { label: "สัปดาห์นี้", value: "this_week" },
      { label: "สัปดาห์ที่แล้ว", value: "last_week" },
      { label: "เดือนนี้", value: "this_month" },
      { label: "เดือนที่แล้ว", value: "last_month" },
      { label: "ไตรมาสนี้", value: "this_quarter" },
      { label: "ไตรมาสที่แล้ว", value: "last_quarter" },
      { label: "ปีนี้", value: "this_year" },
      { label: "ปีที่แล้ว", value: "last_year" },
    ],
    []
  );

  const futureOption = useMemo(
    () => [
      {
        label: "พรุ่งนี้",
        value: "tomorrow",
      },
      { label: "7 วันข้างหน้า", value: "next_7_days" },
      { label: "30 วันข้างหน้า", value: "next_30_days" },
      { label: "สัปดาห์หน้า", value: "next_week" },
      { label: "เดือนหน้า", value: "next_month" },
      { label: "ไตรมาสหน้า", value: "next_quarter" },
      { label: "ปีหน้า", value: "next_year" },
    ],
    []
  );

  const exportChangedDate = useCallback(
    (startDate: Date | null, endDate: Date | null) => {
      setValue("start_date", startDate);
      setValue("end_date", endDate);
    },
    [setValue]
  );

  const onChangeDateHandle = useCallback(() => {
    const dateType = getValues("date_type");
    if (dateType === "user_defined") return;
    let startDate:Date | null = dayjs().startOf("day").subtract(1, "day").toDate();
    let endDate:Date | null = dayjs().endOf("day").toDate();

    if (dateType === "today") {
      startDate = dayjs().startOf("day").toDate();
      endDate = dayjs().endOf("day").toDate();
    } else if (dateType === "yesterday") {
      startDate = dayjs().startOf("day").subtract(1, "day").toDate();
      endDate = dayjs().endOf("day").subtract(1, "day").toDate();
    } else if (dateType === "last_7_days") {
      startDate = dayjs().startOf("day").subtract(6, "day").toDate();
      endDate = dayjs().endOf("day").toDate();
    } else if (dateType === "last_30_days") {
      startDate = dayjs().startOf("day").subtract(29, "day").toDate();
      endDate = dayjs().endOf("day").toDate();
    } else if (dateType === "this_week") {
      startDate = dayjs().startOf("week").toDate();
      endDate = dayjs().endOf("week").toDate();
    } else if (dateType === "last_week") {
      startDate = dayjs().startOf("week").subtract(1, "week").toDate();
      endDate = dayjs().endOf("week").subtract(1, "week").toDate();
    } else if (dateType === "this_month") {
      startDate = dayjs().startOf("month").toDate();
      endDate = dayjs().endOf("month").toDate();
    } else if (dateType === "last_month") {
      startDate = dayjs().startOf("month").subtract(1, "month").toDate();
      endDate = dayjs().startOf("month").subtract(1, "day").toDate();
    } else if (dateType === "this_year") {
      startDate = dayjs().startOf("year").toDate();
      endDate = dayjs().endOf("year").toDate();
    } else if (dateType === "last_year") {
      startDate = dayjs().startOf("year").subtract(1, "year").toDate();
      endDate = dayjs().endOf("year").subtract(1, "year").toDate();
    } else if (dateType === "this_quarter") {
      startDate = dayjs().startOf("quarter").toDate();
      endDate = dayjs().endOf("quarter").toDate();
    } else if (dateType === "last_quarter") {
      startDate = dayjs().startOf("quarter").subtract(1, "quarter").toDate();
      endDate = dayjs().endOf("quarter").subtract(1, "quarter").toDate();
    } else if (dateType === "tomorrow") {
      startDate = dayjs().startOf("day").add(1, "day").toDate();
      endDate = dayjs().endOf("day").add(1, "day").toDate();
    } else if (dateType === "next_7_days") {
      startDate = dayjs().startOf("day").toDate();
      endDate = dayjs().endOf("day").add(6, "day").toDate();
    } else if (dateType === "next_30_days") {
      startDate = dayjs().startOf("day").toDate();
      endDate = dayjs().endOf("day").add(29, "day").toDate();
    } else if (dateType === "next_week") {
      startDate = dayjs().startOf("week").add(1, "week").toDate();
      endDate = dayjs().endOf("week").add(1, "week").toDate();
    } else if (dateType === "next_month") {
      startDate = dayjs().startOf("month").add(1, "month").toDate();
      endDate = dayjs().endOf("month").add(1, "month").toDate();
    } else if (dateType === "next_quarter") {
      startDate = dayjs().startOf("quarter").add(1, "quarter").toDate();
      endDate = dayjs().endOf("quarter").add(1, "quarter").toDate();
    } else if (dateType === "next_year") {
      startDate = dayjs().startOf("year").add(1, "year").toDate();
      endDate = dayjs().endOf("year").add(1, "year").toDate();
    }

    const exportType = getValues("export_type");
    if(exportType === 'all') {
      startDate = null;
      endDate = null;
    }

    return exportChangedDate(startDate, endDate);
  }, [exportChangedDate, getValues]);

  const watchExportType = watch("export_type");
  const watchDateType = watch("date_type");
  
  useEffect(() => {
    if (watchDateType) onChangeDateHandle();
  }, [onChangeDateHandle, watchDateType, watchExportType]);

  const onClose = () => {
    reset(exporterSchena);
    closeModalHandler();
  };

  return (
    <ModalUI
      title={t("button.export")}
      open={open}
      handleClose={onClose}
      maxWidth={"sm"}
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={onClose}
          />
          <CustomizedButton
            title={t("button.export")}
            variant="contained"
            onClick={() => {
              handleSubmit(exportHandler)();
              if (isValid) {
                onClose();
              }
            }}
          />
        </Box>
      }
    >
      <Typography color="primary.main" fontWeight={600} mb={2}>
        เลือกประเภทข้อมูล
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12}>
          <ControlledSelect
            name="type"
            control={control}
            label={"ข้อมูล"}
            error={Boolean(errors.type)}
            helperText={errors.type?.message?.toString()}
            options={typeOptions}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <RadioBox
            label={t("global.all")}
            onClick={() => {
              setValue("export_type", "all");
              setValue("start_date", null);
              setValue("end_date", null);
              setValue("date_type", "today");
            }}
            checked={watchExportType === "all"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <RadioBox
            label={t("global.date_range")}
            onClick={() => {
              setValue("export_type", "date_range");
            }}
            checked={watchExportType === "date_range"}
          />
        </Grid>
        {watchExportType === "date_range" && (
          <>
            <Grid item xs={8} sm={8} md={4} lg={4} xl={4}>
              <ControlledSelect
                name="date_type"
                control={control}
                options={future ? [...pastOption, ...futureOption] : pastOption}
              />
            </Grid>
            <Grid item xs={8} sm={8} md={4} lg={4} xl={4}>
              <ControlledDatePicker
                control={control}
                name="start_date"
                label={t("date.from")}
                onChange={(e, field) => {
                  setValue("date_type", "user_defined");
                  if (e) field.onChange(e);
                }}
                required
              />
            </Grid>
            <Grid item xs={8} sm={8} md={4} lg={4} xl={4}>
              <ControlledDatePicker
                control={control}
                name="end_date"
                label={t("date.to")}
                onChange={(e, field) => {
                  setValue("date_type", "user_defined");
                  if (e) field.onChange(e);
                }}
                required
              />
            </Grid>
          </>
        )}
      </Grid>
    </ModalUI>
  );
};

export default ExporterModal;
