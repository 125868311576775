import { IDefaultForm } from "../../../types/global";
import DocDropzone from "./DocDropzone";

const AttachmentTab = ({
  control,
  setValue,
  getValues,
  disabled,
  errors,
}: IDefaultForm) => {
  return (
    <DocDropzone
      control={control}
      setValue={setValue}
      name="attachment_list"
      disabled={disabled}
      getValues={getValues}
      multiple
    />
  );
};

export default AttachmentTab;
