import { Box, DialogContentText, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Approver, IApproval } from "../../../types/global";
import ApprovalList from "../../../components/Form/Approval/ApprovalListNew";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { approvalSchema, approvalValidation } from "../../../components/Form/Approval/schema";
import ModalUI from "../../../components/UI/ModalUI";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import { useUsersQuery, UsersQuery } from "../../../generated/company-user";
import { useUserInformationsQuery, UserInformationsQuery } from "../../../generated/user-infomation";
import { IUser, IUserInfo } from "../../../types/Auth/user";
import { useCreateApprovalTemplateMutation, useSalesApprovalTemplatesQuery, useUpdateApprovalTemplateMutation } from "../../../generated/sales";

const SalesApproval = ({ activeTab, salesData }: { activeTab: string, salesData: IApproval | null}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const graphQLClientWithHeaderSales: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");
  const createApprovalTemplate = useCreateApprovalTemplateMutation(graphQLClientWithHeaderSales);
  const updateApprovalTemplate = useUpdateApprovalTemplateMutation(graphQLClientWithHeaderSales);
  const { data: approvalTemplatesData, refetch } = useSalesApprovalTemplatesQuery(graphQLClientWithHeaderSales, {});
  const [confirmationModal, setConfirmationModal] = useState(false);

  // const methods = useForm<IApproval>({
  //   defaultValues: approvalSchema,
  //   resolver: yupResolver<any>(approvalValidation),
  // });

  // const {
  //   handleSubmit,
  //   reset,
  // } = methods;
  const { reset } = useFormContext();

  const documents = [
    { name: "ใบเสนอราคา", document_type: "quotation" },
    { name: "ใบสั่งขาย", document_type: "sales_order" },
    // { name: "ใบรับคืน", document_type: "sales_return" }
  ];

  const graphQLClientWithHeaderCompany: GraphQLClient =
    createGraphQLClientWithMiddleware("company-user");

  const graphQLClientWithHeaderSetting: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data: userData } =
    useUsersQuery<UsersQuery>(graphQLClientWithHeaderCompany);

  const { data: userInfoData } =
    useUserInformationsQuery<UserInformationsQuery>(
      graphQLClientWithHeaderSetting
    );
  const [localSalesData, setLocalSalesData] = useState<IApproval | null>(null);

  const mergeUsersFormatter = (users: IUser[], userInfos: IUserInfo[]): Approver[] => {
    return users?.map((user) => {
      const userInfo = userInfos.find((info) => info.user_unique_id === user.unique_id);
      return {
        unique_id: user.unique_id,
        first_name: user.first_name,
        last_name: user.last_name || '',
        email: user.email,
        img_url: user.img_url || [],
        position: userInfo?.position || null,
        department: userInfo?.department || null,
        status: userInfo?.status || null,
      };
    });
  };

  useEffect(() => {
    if (approvalTemplatesData && approvalTemplatesData.approvalTemplates && approvalTemplatesData.approvalTemplates.length > 0) {
      interface ApprovalTemplateData {
        id: number;
        document_type: string;
        approval_detail_list: { id: number; approval_step: number; approver_list: Approver[] }[];
        is_have_approval: boolean;
        required_approval_number: number;
      }

      const mergedUsers = userData && userInfoData ? mergeUsersFormatter(
        userData.users as IUser[],
        userInfoData.userInformations as IUserInfo[]
      ) : [];

      const mapUserDetails = (approvalIdList: string[]): Approver[] => {
        return approvalIdList?.map((id) => {
          const user = mergedUsers.find((mergedUser) => mergedUser.unique_id === id);
          return user
            ? {
              unique_id: user.unique_id,
              first_name: user.first_name,
              last_name: user.last_name,
              email: user.email,
              img_url: user.img_url,
              position: user.position,
              department: user.department,
              status: user.status,
            }
            : null;
        }).filter(Boolean) as Approver[];
      };
      const sortedApprovalTemplatesData: ApprovalTemplateData[] = approvalTemplatesData.approvalTemplates.filter((template): template is ApprovalTemplateData => template !== null).sort((a, b) => {
        // const order = ["quotation", "sales_order", "sales_return"];
        const order = ["quotation", "sales_order"];
        return order.indexOf(a?.document_type ?? '') - order.indexOf(b.document_type);
      });

      const formattedTemplates = sortedApprovalTemplatesData?.map((template) => {
        const approverList = mapUserDetails(
          template.approval_detail_list[0]?.approver_list?.map(approver => approver.unique_id) || []
        );

        return {
          id: template.id.toString(),
          document_type: template.document_type,
          workflow_document_type: template.document_type,
          workflow_step_number: 1,
          required_approval: 1,
          approver_list: approverList,
          is_have_approval: template.is_have_approval,
        };
      });

      const toggleStates = formattedTemplates.map(
        (template) => !!template.approver_list.length
      );
      
      if (localSalesData && toggleStates) {
        reset(localSalesData);
      } else {
        reset({
          approval_templates: formattedTemplates,
          is_have_approval: toggleStates.some((state) => state),
        });
      }
    }
  }, [approvalTemplatesData, reset, userData, userInfoData, localSalesData]);

  useEffect(() => {
    return setLocalSalesData(salesData);
  }, [salesData]);

  // const saveHandler = async (data: IApproval) => {
  //   try {
  //     // const validTemplates = (data.approval_templates || []).filter((template) => {
  //     //   if (template.is_have_approval) {
  //     //     return template.approver_list?.length > 0;
  //     //   }
  //     //   return true;
  //     // });
  //     const adjustedTemplates = (data.approval_templates || []).map((template) => {
  //       if (template.is_have_approval && (!template.approver_list || template.approver_list.length === 0)) {
  //         return {
  //           ...template,
  //           is_have_approval: false,
  //         };
  //       }
  //       return template;
  //     });
  //     const validTemplates = adjustedTemplates.filter((template) => {
  //       return template.is_have_approval || template.id;
  //     });

  //     const mutationPromises = validTemplates.map(async (template, index) => {
  //       try {
  //         const isApproved = template.is_have_approval;
  //         const approverList = template.approver_list.map((approver) => ({
  //           unique_id: approver.unique_id,
  //           first_name: approver.first_name,
  //           last_name: approver.last_name,
  //           email: approver.email,
  //           img_url: approver.img_url || [],
  //           status: approver.status || null,
  //           position: approver.position || null,
  //           department: approver.department || null,
  //         }));

  //         const formattedInput = {
  //           document_type: documents[index]?.document_type,
  //           approval_detail_list: approverList.length
  //             ? [
  //               {
  //                 approval_step: 1,
  //                 approval_id_list: approverList
  //                   .map((approver) => approver?.unique_id)
  //                   .filter((id) => !!id),
  //                 approver_list: approverList,
  //               },
  //             ]
  //             : [],
  //           is_have_approval: isApproved ?? false,
  //         };

  //         if (template.id) {
  //           return await updateApprovalTemplate.mutateAsync({
  //             uniqueInput: { id: Number(template.id) },
  //             updateInput: formattedInput,
  //           });
  //         } else if (isApproved) {
  //           return await createApprovalTemplate.mutateAsync({ createInput: formattedInput });
  //         }
  //         return Promise.resolve();
  //       } catch (err) {
  //         console.error(`Error processing template [${index}]:`, err);
  //         throw err;
  //       }
  //     });

  //     const results = await Promise.allSettled(mutationPromises);

  //     const hasError = results.some((result) => result.status === "rejected");
  //     if (hasError) {
  //       enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "warning" });
  //     } else {
  //       enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
  //     }
  //     setConfirmationModal(false);
  //     refetch();
  //   } catch (error) {
  //     console.error("Error during saveHandler:", error);
  //     enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
  //   }
  // };

  return (
    <Box my={2}>
      {/* <FormProvider {...methods}> */}
      <ApprovalList key={activeTab} documents={documents} />
      {/* <BottomNavbar>
          {(
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                title="ยกเลิก"
                variant="outlined"
                onClick={() => reset()} />
              <CustomizedButton
                variant="contained"
                title={t("button.save")}
                onClick={() => setConfirmationModal(true)} />
            </Stack>
          )}
        </BottomNavbar>
        <ModalUI
          title="ยืนยันการเปลี่ยนข้อมูล"
          open={confirmationModal}
          handleClose={() => setConfirmationModal(false)}
          maxWidth="xs"
          fullWidth
          action={
            <Box
              sx={{
                mt: 2,
                display: "flex",
                justifyContent: "flex-end",
                gap: ".5rem",
              }}
            >
              <CustomizedButton
                title="ยกเลิก"
                variant="outlined"
                onClick={() => setConfirmationModal(false)}
              />
              <CustomizedButton
                title="ยืนยัน"
                variant="contained"
                onClick={handleSubmit(saveHandler)}
              />
            </Box>
          }
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <ErrorOutlineOutlinedIcon sx={{ fontSize: 60, color: "#BDBDBD" }} />
          </Box>
          <DialogContentText sx={{
            textAlign: "center",
            color: "black"
          }}>
            <span>การเปลี่ยนแปลงข้อมูลการอนุมัติ</span>
            <br />
            <strong>จะส่งผลต่อเอกสารที่ยังไม่ได้รับการอนุมัติ</strong>
          </DialogContentText>
        </ModalUI> */}
      {/* </FormProvider> */}
    </Box>
  );
};

export default SalesApproval;