import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu, ITab, IApproval } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { useLocation, useSearchParams } from "react-router-dom";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import { useEffect, useState, useRef } from "react";
import { Box, Stack, DialogContentText } from "@mui/material";
import PurchaseApproval from "./PurchaseNew";
import SalesApproval from "./SalesNew";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { approvalSchema, approvalValidation } from "../../../components/Form/Approval/schema";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import ModalUI from "../../../components/UI/ModalUI";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { useSnackbar } from "notistack";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { ApprovalTemplateCreateInput, ApprovalTemplateUniqueInput, ApprovalTemplateUpdateInput, CreateApprovalTemplateMutation, UpdateApprovalTemplateMutation, useCreateApprovalTemplateMutation, useUpdateApprovalTemplateMutation } from "../../../generated/sales";
import { Exact, PurchaseApprovalTemplateCreateInput, PurchaseApprovalTemplateCreateMutation, PurchaseApprovalTemplateUniqueInput, PurchaseApprovalTemplateUpdateInput, PurchaseApprovalTemplateUpdateMutation, usePurchaseApprovalTemplateCreateMutation, usePurchaseApprovalTemplateUpdateMutation } from "../../../generated/purchase";
import { UseMutationResult } from "@tanstack/react-query";
import LoadingMascot from "../../../components/UI/LoadingMascot";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";

const ApprovalSetting = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { enqueueSnackbar } = useSnackbar();

  const graphQLClientSales: GraphQLClient = createGraphQLClientWithMiddleware("sales");
  const graphQLClientPurchase: GraphQLClient = createGraphQLClientWithMiddleware("purchase");

  const createSalesApproval = useCreateApprovalTemplateMutation(graphQLClientSales);
  const updateSalesApproval = useUpdateApprovalTemplateMutation(graphQLClientSales);
  const createPurchaseApproval = usePurchaseApprovalTemplateCreateMutation(graphQLClientPurchase);
  const updatePurchaseApproval = usePurchaseApprovalTemplateUpdateMutation(graphQLClientPurchase);

  const [currentTab, setCurrentTab] = useState(tab || "sales");
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [salesData, setSalesData] = useState<IApproval | null>(null);
  const [previousTab, setPreviousTab] = useState<string | null>(null);
  const [purchaseData, setPurchaseData] = useState<IApproval | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm<IApproval>({
    defaultValues: approvalSchema,
    resolver: yupResolver<any>(approvalValidation),
  });

  const { handleSubmit, reset, getValues } = methods;

  const isFirstRender = useRef(true);

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.approval.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("setting.approval.sales"),
      path: `${pathname}?tab=sales`,
    },
    {
      label: t("setting.approval.purchase"),
      path: `${pathname}?tab=purchase`,
    },
  ];

  useEffect(() => {
    if (!isFirstRender.current) {
      const currentValues = getValues();

      if (currentTab === "sales") {
        setSalesData(currentValues);
      } else if (currentTab === "purchase") {
        setPurchaseData(currentValues);
      }
    }

    setCurrentTab(searchParams.get("tab") || "sales");

    if (searchParams.get("tab") === "sales" && salesData) {
      reset(salesData);
    } else if (searchParams.get("tab") === "purchase" && purchaseData) {
      reset(purchaseData);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!isFirstRender.current) {
      if (tab === "sales" && salesData) {
        reset(salesData);
      } else if (tab === "purchase" && purchaseData) {
        reset(purchaseData);
      }
    }
    setCurrentTab(tab || "sales");
  }, [tab]);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  const saveHandler = async () => {
    setConfirmationModal(false);
    setIsLoading(true);
    try {
      const currentValues = getValues();

      const mergedSalesData = currentTab === "sales"
        ? { ...salesData, ...currentValues }
        : salesData;

      const mergedPurchaseData = currentTab === "purchase"
        ? { ...purchaseData, ...currentValues }
        : purchaseData;

      const salesTemplates = (mergedSalesData?.approval_templates || []).filter((template) =>
        ["quotation", "sales_order"].includes(template.workflow_document_type)
      );

      const purchaseTemplates = (mergedPurchaseData?.approval_templates || []).filter((template) =>
        ["purchase_order"].includes(template.workflow_document_type)
      );

      const adjustedSalesTemplates = salesTemplates.map((template) => ({
        ...template,
        is_have_approval: template.approver_list?.length > 0 ? template.is_have_approval : false,
      }));

      const adjustedPurchaseTemplates = purchaseTemplates.map((template) => ({
        ...template,
        is_have_approval: template.approver_list?.length > 0 ? template.is_have_approval : false,
      }));

      const validSalesTemplates = adjustedSalesTemplates.filter((template) => template.is_have_approval || template.id);
      const validPurchaseTemplates = adjustedPurchaseTemplates.filter((template) => template.is_have_approval || template.id);

      const processTemplates = async (
        templates: any[],
        createAPI: UseMutationResult<CreateApprovalTemplateMutation, unknown, Exact<{ createInput: ApprovalTemplateCreateInput; }>, unknown> | UseMutationResult<PurchaseApprovalTemplateCreateMutation, unknown, Exact<{ createInput: PurchaseApprovalTemplateCreateInput; }>, unknown>,
        updateAPI: UseMutationResult<UpdateApprovalTemplateMutation, unknown, Exact<{ uniqueInput: ApprovalTemplateUniqueInput; updateInput: ApprovalTemplateUpdateInput; }>, unknown>
      ) => {
        const promises = templates.map(async (template) => {
          const formattedApprovers = template.approver_list.map((approver: { unique_id: any; first_name: any; last_name: any; email: any; img_url: any; position: any; department: any; status: any; }) => ({
            unique_id: approver.unique_id,
            first_name: approver.first_name,
            last_name: approver.last_name,
            email: approver.email,
            img_url: approver.img_url || [],
            position: approver.position || null,
            department: approver.department || null,
            status: approver.status || null,
          }));

          const formattedInput = {
            document_type: template.document_type,
            approval_detail_list: formattedApprovers.length
              ? [
                {
                  approval_step: 1,
                  approval_id_list: formattedApprovers.map((a: { unique_id: any; }) => a.unique_id),
                  approver_list: formattedApprovers,
                },
              ]
              : [],
            is_have_approval: template.is_have_approval,
          };

          if (template.id) {
            return await updateAPI.mutateAsync({
              uniqueInput: { id: Number(template.id) },
              updateInput: formattedInput,
            });
          } else if (template.is_have_approval) {
            return await createAPI.mutateAsync({ createInput: formattedInput });
          }
          return null;
        });

        return Promise.allSettled(promises);
      };

      const [salesResult, purchaseResult] = await Promise.all([
        processTemplates(validSalesTemplates, createSalesApproval, updateSalesApproval),
        processTemplates(validPurchaseTemplates, createPurchaseApproval, updatePurchaseApproval),
      ]);

      const hasError = [...salesResult, ...purchaseResult].some((res) => res.status === "rejected");

      if (currentTab === "sales") {
        setSalesData(mergedSalesData);
      } else if (currentTab === "purchase") {
        setPurchaseData(mergedPurchaseData);
      }
  
      setTimeout(() => {
        if (currentTab === "sales") {
          if (mergedSalesData) {
            reset(mergedSalesData);
          }
        } else if (currentTab === "purchase") {
          if (mergedPurchaseData) {
            reset(mergedPurchaseData);
          }
        }
      }, 0);

      if (hasError) {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      } else {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
        reset({
          approval_templates: [...validSalesTemplates, ...validPurchaseTemplates],
          is_have_approval: validSalesTemplates.some((t) => t.is_have_approval) ||
            validPurchaseTemplates.some((t) => t.is_have_approval),
        });
      }
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    reset();
    setSalesData(null);
    setPurchaseData(null);
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <FormProvider {...methods}>
        <Box my={2}>
          <CustomizedTab tabs={tabs} currentTab={`${pathname}?tab=${currentTab}`} divider />
          {currentTab === "purchase" && <PurchaseApproval activeTab="purchase" purchaseData={purchaseData} />}
          {currentTab === "sales" && <SalesApproval activeTab="sales" salesData={salesData} />}

          <BottomNavbar>
            {(
              <Stack direction="row" spacing={1} alignItems="center">
                <CustomizedButton
                  title="ยกเลิก"
                  variant="outlined"
                  onClick={handleReset} />
                <CustomizedButton
                  variant="contained"
                  title={t("button.save")}
                  onClick={() => setConfirmationModal(true)} />
              </Stack>
            )}
          </BottomNavbar>

          <ModalUI
            title="ยืนยันการเปลี่ยนข้อมูล"
            open={confirmationModal}
            handleClose={() => setConfirmationModal(false)}
            maxWidth="xs"
            fullWidth
            action={
              <Stack direction="row" spacing={1} justifyContent="flex-end" mt={2}>
                <CustomizedButton title="ยกเลิก" variant="outlined" onClick={() => setConfirmationModal(false)} />
                <CustomizedButton title="ยืนยัน" variant="contained" onClick={handleSubmit(saveHandler)} />
              </Stack>
            }
          >
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 2 }}>
              <ErrorOutlineOutlinedIcon sx={{ fontSize: 60, color: "#BDBDBD" }} />
            </Box>
            <DialogContentText sx={{ textAlign: "center", color: "black" }}>
              <span>การเปลี่ยนแปลงข้อมูลการอนุมัติ</span>
              <br />
              <strong>จะส่งผลต่อเอกสารที่ยังไม่ได้รับการอนุมัติ</strong>
            </DialogContentText>
          </ModalUI>
          <LoadingMascot isLoading={isLoading} />
        </Box>
      </FormProvider>
    </>
  );
};

export default ApprovalSetting;