import React from "react";
import ModalUI from "../ModalUI";
import { Avatar, Box, DialogTitle, Divider, Typography } from "@mui/material";
import CustomizedStatus from "../../Custom/CustomizedStatus";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useItemSkuDetailQuery } from "../../../generated/inventory";
import CustomizedAvatar from "../../Custom/CustomizedAvatar";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import CustomizedLetterAvatar from "../../Custom/CustomizedLetterAvatar";
import dayjs from "dayjs";
import CustomizedChips from "../../Custom/CustomizedChips";

interface GanttTaskInfoModalProps {
  open: boolean;
  handleClose: () => void;
  selectedTask: any;
}

const GanttTaskInfoModal = ({
  open,
  handleClose,
  selectedTask,
}: GanttTaskInfoModalProps) => {
  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");
  const { data } = useItemSkuDetailQuery(graphQLClientWithHeaderItem, {
    uniqueInput: {
      sku_name: selectedTask?.manufactureOrder?.item_unique_id || "",
    },
  });

  if (!selectedTask) return null;

  const selectedManufactureOrder = selectedTask.manufactureOrder;

  const selectedTaskIndex = Number(selectedTask.index);
  return (
    <ModalUI
      width={"400px"}
      open={open}
      handleClose={handleClose}
      header={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() =>
              window.open(
                `/manufacture/order/${encodeURIComponent(
                  selectedManufactureOrder.unique_id
                )}`,
                "_blank"
              )
            }
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                m: 0,
                p: 2,
                pr: 0,
                minHeight: "62px",
                maxWidth: "200px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  color: "primary.main",
                  fontSize: "20px",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {selectedTask.text}
              </Typography>
            </DialogTitle>
            <LaunchOutlinedIcon color="primary" sx={{ fontSize: "16px" }} />
          </Box>
          {selectedManufactureOrder.aggrid_status !== "draft" && (
            <Box sx={{ pr: "55px" }}>
              <CustomizedStatus
                status={
                  selectedManufactureOrder?.work_order_list?.[selectedTaskIndex]
                    ?.main_status
                }
              />
            </Box>
          )}
        </Box>
      }
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            background: "#ECF6FD",
            gap: "8px",
            padding: "4px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              background: "#ECF6FD",
            }}
          >
            <CustomizedAvatar
              noTooltip
              variant="rounded"
              avatars={[
                {
                  img_url: data?.itemSkuDetail?.img_url
                    ? data?.itemSkuDetail?.img_url[0]
                    : "",
                  unique_id: data?.itemSkuDetail?.sku_name || "",
                  first_name: data?.itemSkuDetail?.sku_name || "",
                  last_name: "",
                },
              ]}
              sx={{
                padding: 0,
                width: 96,
                height: 96,
                border: "1px solid #E2E2E2",
              }}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "8px" }}>
              <Typography
                fontSize={"14px"}
                fontWeight={600}
                lineHeight={"24px"}
              >
                {data?.itemSkuDetail?.sku_name}
              </Typography>
              <Typography
                fontSize={"14px"}
                fontWeight={400}
                lineHeight={"24px"}
              >
                {"x " +
                  selectedManufactureOrder.production_qty +
                  " " +
                  selectedManufactureOrder.uom}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Typography
                fontSize={"14px"}
                fontWeight={400}
                lineHeight={"24px"}
                color={"#737373"}
              >
                {`${selectedManufactureOrder.item_name}`}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Typography
                fontSize={"14px"}
                fontWeight={400}
                lineHeight={"24px"}
                color={"#737373"}
              >
                {`${selectedManufactureOrder.item_description}`}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Typography
                fontSize={"14px"}
                fontWeight={400}
                lineHeight={"24px"}
                color={"#737373"}
              >
                {"หมายเหตุ : " +
                  (selectedManufactureOrder.item_remark
                    ? selectedManufactureOrder.item_remark
                    : "-")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", flex: "0.25" }}>
            <Typography fontSize={"14px"} fontWeight={600} lineHeight={"24px"}>
              โรงงาน
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", flex: "0.75" }}>
            <Typography fontSize={"14px"} fontWeight={400} lineHeight={"24px"}>
              {selectedManufactureOrder?.work_order_list?.[selectedTaskIndex]
                ?.plant || "-"}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", flex: "0.25" }}>
            <Typography fontSize={"14px"} fontWeight={600} lineHeight={"24px"}>
              ศูนย์การผลิต
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", flex: "0.75" }}>
            <Typography fontSize={"14px"} fontWeight={400} lineHeight={"24px"}>
              {selectedTask.production_center || "-"}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", flex: "0.25" }}>
            <Typography fontSize={"14px"} fontWeight={600} lineHeight={"24px"}>
              ผู้รับผิดชอบ
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flex: "0.75",
              gap: "4px",
            }}
          >
            <CustomizedTooltip
              title={`${selectedManufactureOrder.created_by.first_name} ${selectedManufactureOrder.created_by.last_name}`}
              enterNextDelay={200}
            >
              <>
                {selectedManufactureOrder.created_by.img_url && (
                  <Avatar
                    sx={{ width: "24px", height: "24px" }}
                    alt={`img${selectedManufactureOrder.created_by}`}
                    src={selectedManufactureOrder.created_by.img_url}
                  />
                )}
                {!selectedManufactureOrder.created_by.img_url && (
                  <CustomizedLetterAvatar
                    sx={{ width: "24px", height: "24px", fontSize: "13px" }}
                    name={
                      selectedManufactureOrder.created_by.first_name +
                      " " +
                      selectedManufactureOrder.created_by.last_name
                    }
                  />
                )}
              </>
            </CustomizedTooltip>
          </Box>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", flex: "0.25" }}>
            <Typography fontSize={"14px"} fontWeight={600} lineHeight={"24px"}>
              หมายเหตุ
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", flex: "0.75" }}>
            <Typography fontSize={"14px"} fontWeight={400} lineHeight={"24px"}>
              {selectedManufactureOrder.remark || "-"}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ display: "inline-flex", flexDirection: "row", gap: "4px" }}
          >
            <Typography
              fontSize={"12px"}
              fontWeight={600}
              lineHeight={"18px"}
              color={"#737373"}
            >
              เริ่มผลิต
            </Typography>
            <Typography
              fontSize={"12px"}
              fontWeight={400}
              lineHeight={"18px"}
              color={"#737373"}
            >
              {dayjs(selectedManufactureOrder.production_date).format(
                "DD/MM/YYYY"
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              backgroundColor: "#D9D9D9",
            }}
          />
          <Box
            sx={{ display: "inline-flex", flexDirection: "row", gap: "4px" }}
          >
            <Typography
              fontSize={"12px"}
              fontWeight={600}
              lineHeight={"18px"}
              color={"#737373"}
            >
              ผลิตเสร็จ
            </Typography>
            <Typography
              fontSize={"12px"}
              fontWeight={400}
              lineHeight={"18px"}
              color={"#737373"}
            >
              {dayjs(
                selectedManufactureOrder.production_completion_date
              ).format("DD/MM/YYYY")}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", flex: "0.5" }}>
            <Typography fontSize={"14px"} fontWeight={600} lineHeight={"24px"}>
              ใบสั่งผลิต
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flex: "1",
              marginTop: "3px",
            }}
          >
            <CustomizedChips
              onClick={() =>
                window.open(
                  `/manufacture/order/${encodeURIComponent(
                    selectedManufactureOrder.unique_id
                  )}`,
                  "_blank"
                )
              }
              value={selectedManufactureOrder.unique_id}
              color="primary"
              sx={{ fontSize: "14px", height: "25px" }}
            />
          </Box>
          <Box sx={{}}>
            <CustomizedStatus status={selectedManufactureOrder.aggrid_status} />
          </Box>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", flex: "0.25" }}>
            <Typography fontSize={"14px"} fontWeight={600} lineHeight={"24px"}>
              ผู้เกี่ยวข้อง
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flex: "0.75",
              gap: "4px",
            }}
          >
            <CustomizedTooltip
              title={`${selectedManufactureOrder.created_by.first_name} ${selectedManufactureOrder.created_by.last_name}`}
              enterNextDelay={200}
            >
              <>
                {selectedManufactureOrder.created_by.img_url && (
                  <Avatar
                    sx={{ width: "24px", height: "24px" }}
                    alt={`img${selectedManufactureOrder.created_by}`}
                    src={selectedManufactureOrder.created_by.img_url}
                  />
                )}
                {!selectedManufactureOrder.created_by.img_url && (
                  <CustomizedLetterAvatar
                    sx={{ width: "24px", height: "24px", fontSize: "13px" }}
                    name={
                      selectedManufactureOrder.created_by.first_name +
                      " " +
                      selectedManufactureOrder.created_by.last_name
                    }
                  />
                )}
                {selectedManufactureOrder.related_user_list.map(
                  (item: any, index: number) => (
                    <CustomizedTooltip
                      title={`${item.first_name} ${item.last_name}`}
                      enterNextDelay={200}
                    >
                      <>
                        {item.img_url.length > 0 && (
                          <Avatar
                            sx={{ width: "24px", height: "24px" }}
                            alt={`img${index}`}
                            src={item.img_url}
                          />
                        )}
                        {item.img_url.length === 0 && (
                          <CustomizedLetterAvatar
                            sx={{
                              width: "24px",
                              height: "24px",
                              fontSize: "13px",
                            }}
                            name={item.first_name + " " + item.last_name}
                          />
                        )}
                      </>
                    </CustomizedTooltip>
                  )
                )}
              </>
            </CustomizedTooltip>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box sx={{ display: "flex", flexDirection: "row", flex: "0.25" }}>
            <Typography fontSize={"14px"} fontWeight={600} lineHeight={"24px"}>
              ลูกค้า
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", flex: "0.75" }}>
            <Typography fontSize={"14px"} fontWeight={400} lineHeight={"24px"}>
              {selectedManufactureOrder.customer_name.split(" - ")[1]}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", flex: "0.25" }}>
            <Typography fontSize={"14px"} fontWeight={600} lineHeight={"24px"}>
              เลขที่อ้างอิง
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", flex: "0.75" }}>
            {selectedManufactureOrder.reference_unique_id_list.map(
              (reference: string) => (
                <CustomizedChips
                  onClick={() =>
                    window.open(
                      `/sales/order/${encodeURIComponent(reference)}`,
                      "_blank"
                    )
                  }
                  value={reference}
                  color="primary"
                  sx={{ fontSize: "14px", height: "25px" }}
                />
              )
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ display: "inline-flex", flexDirection: "row", gap: "4px" }}
          >
            <Typography
              fontSize={"12px"}
              fontWeight={600}
              lineHeight={"18px"}
              color={"#737373"}
            >
              กำหนดผลิต
            </Typography>
            <Typography
              fontSize={"12px"}
              fontWeight={400}
              lineHeight={"18px"}
              color={"#737373"}
            >
              {dayjs(selectedManufactureOrder.production_date).format(
                "DD/MM/YYYY"
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              backgroundColor: "#D9D9D9",
            }}
          />
          <Box
            sx={{ display: "inline-flex", flexDirection: "row", gap: "4px" }}
          >
            <Typography
              fontSize={"12px"}
              fontWeight={600}
              lineHeight={"18px"}
              color={"#737373"}
            >
              กำหนดผลิตเสร็จ
            </Typography>
            <Typography
              fontSize={"12px"}
              fontWeight={400}
              lineHeight={"18px"}
              color={"#737373"}
            >
              {dayjs(
                selectedManufactureOrder.production_completion_date
              ).format("DD/MM/YYYY")}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", flex: 0.25 }}>
            <Typography fontSize={"14px"} fontWeight={600} lineHeight={"24px"}>
              การจัดกลุ่ม
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flex: 0.75,
              gap: "4px",
            }}
          >
            {selectedManufactureOrder.tag_list.map((tag: any) => (
              <CustomizedChips
                value={tag.name}
                color="primary"
                sx={{ fontSize: "14px", height: "25px" }}
              />
            ))}
          </Box>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", flex: 0.25 }}>
            <Typography fontSize={"14px"} fontWeight={600} lineHeight={"24px"}>
              ประเภท
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", flex: 0.75 }}>
            <Typography fontSize={"14px"} fontWeight={400} lineHeight={"24px"}>
              {selectedManufactureOrder.type}
            </Typography>
          </Box>
        </Box>
      </Box>
    </ModalUI>
  );
};

export default GanttTaskInfoModal;
