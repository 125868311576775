import { Box, Typography } from "@mui/material";
import { GraphQLClient } from "graphql-request";
import { useTranslation } from "react-i18next";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  useOcrSettingQuery,
  OcrSettingQuery,
  useOcrCreditUsageQuery,
  OcrCreditUsageQuery,
} from "../../../generated/general";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import { formatNumber } from "../../../utils/dataTransformer";

const OCRInformation = () => {
  const graphqlClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { t } = useTranslation();

  const { data: ocrSetting, isLoading: ocrSettingIsLoading } =
    useOcrSettingQuery<OcrSettingQuery>(graphqlClient);

  const { data: creditUsage, isLoading: creditUsageIsLoading } =
    useOcrCreditUsageQuery<OcrCreditUsageQuery>(graphqlClient);

  if (ocrSettingIsLoading || creditUsageIsLoading) {
    return <></>;
  }

  return (
    <>
      {ocrSetting && ocrSetting?.OCRSetting?.ocr_is_enabled && (
        <CustomizedBox display="flex" flexDirection="column" gap={2}>
          <Box display="flex" justifySelf="center" gap={2}>
            <Typography fontWeight="bold">{t("setting.action.ocr")}</Typography>
          </Box>
          <Box display="flex" justifySelf="center" gap={2}>
            <Typography fontWeight="bold">
              {t("setting.action.ocr_credit_total")} :
            </Typography>
            <Typography>{`${formatNumber(
              creditUsage?.OCRCreditUsage.used_credit ?? 0,
              0
            )}/${formatNumber(
              creditUsage?.OCRCreditUsage.total_credit ?? 0,
              0
            )}`}</Typography>
          </Box>
          {creditUsage?.OCRCreditUsage.so_is_valid && (
            <Box display="flex" justifySelf="center" gap={2} ml={2}>
              <Typography fontWeight="bold" width={"2.25rem"}>
                {t("setting.action.sales")} :
              </Typography>
              <Typography>{`${formatNumber(
                creditUsage?.OCRCreditUsage.so_used_credit ?? 0,
                0
              )}/${formatNumber(
                creditUsage?.OCRCreditUsage.so_total_credit ?? 0,
                0
              )}`}</Typography>
            </Box>
          )}
          {creditUsage?.OCRCreditUsage.po_is_valid && (
            <Box display="flex" justifySelf="center" gap={2} ml={2}>
              <Typography fontWeight="bold" width={"2.25rem"}>
                {t("setting.action.purchase")} :
              </Typography>
              <Typography>{`${formatNumber(
                creditUsage?.OCRCreditUsage.po_used_credit ?? 0,
                0
              )}/${formatNumber(
                creditUsage?.OCRCreditUsage.po_total_credit ?? 0,
                0
              )}`}</Typography>
            </Box>
          )}
        </CustomizedBox>
      )}
    </>
  );
};

export default OCRInformation;
