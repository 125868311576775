import { useEffect, useContext, useRef } from "react";
import {
  useNavigate,
  useLocation,
  UNSAFE_NavigationContext,
} from "react-router-dom";

export const useOnLeavePage = (
  isDirty: boolean,
  message = "You have unsaved changes. Are you sure you want to leave?"
) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navigator } = useContext(UNSAFE_NavigationContext);
  const isBlockingRef = useRef(false); // Track whether confirmation is already in progress

  // ✅ Handle browser refresh / tab close
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue = message; // Required for modern browsers
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [isDirty, message]);

  // ✅ Handle browser back/forward button
  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      if (!isDirty || isBlockingRef.current) return;

      isBlockingRef.current = true;
      if (!window.confirm(message)) {
        event.preventDefault();
        navigate(location.pathname, { replace: true });
      }
      setTimeout(() => (isBlockingRef.current = false), 300); // Slight delay prevents immediate re-trigger
    };

    window.addEventListener("popstate", handlePopState);
    return () => window.removeEventListener("popstate", handlePopState);
  }, [isDirty, message, navigate, location.pathname]);

  // ✅ Handle in-app navigation (e.g., `navigate()`, `<Link>`)
  useEffect(() => {
    if (!isDirty) return;

    const originalPush = navigator.push;
    const originalReplace = navigator.replace;

    const handleNavigation = (
      method: (...args: any[]) => void,
      ...args: any[]
    ) => {
      if (isBlockingRef.current) return;
      isBlockingRef.current = true;

      if (window.confirm(message)) {
        method(...args); // Allow navigation
      }
      setTimeout(() => (isBlockingRef.current = false), 300);
    };

    navigator.push = (...args) => handleNavigation(originalPush, ...args);
    navigator.replace = (...args) => handleNavigation(originalReplace, ...args);

    return () => {
      navigator.push = originalPush;
      navigator.replace = originalReplace;
    };
  }, [isDirty, message, navigator]);
};
