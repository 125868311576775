import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { IBreadcrumbsAndMenu, ITab, IExporter } from "../../../types/global";

import HeaderLayout from "../../../components/UI/HeaderLayout";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import SalesOrderTable, { SaleOrderTableType } from "../../../components/Table/Sales/Order";
import ButtonLayout from "../../../components/UI/ButtonLayout";
import { useRef, useEffect, useState } from "react";
import ConfigurableAgGridEditButton from "../../../components/UI/ConfigurableAgGrid/ConfigurableAgGridEditButton";
import { ConfigurableAgGridReact } from "../../../components/UI/ConfigurableAgGrid/ConfigurableAgGrid";
import useSalesCheckApproval from "../../../hooks/Sales/use-sales-check-approval";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";
import { useSnackbar } from "notistack";
import { useModal } from "../../../hooks/use-modal";
import ExporterModal from "../../../components/UI/ExporterModal";

import { useSalesOrderGenerateExportQuery, SalesOrderGenerateExportQuery, SalesExportGenerateInput, SalesOrderExportType  } from "../../../generated/sales";

// mock contact all
import { useExportContactQuery, ExportContactQuery, ContactExportInput, ContactExportType, } from "../../../generated/contact";

const SalesOrder = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");
  const tableRef = useRef<ConfigurableAgGridReact<any>>()
  const isHaveApproval = useSalesCheckApproval("sales_order");
  const graphQLClientWithHeaderSales: GraphQLClient =
  createGraphQLClientWithMiddleware("sales");

  const { enqueueSnackbar } = useSnackbar();
  
  // mock contact all
  // --------------------------- * ---------------------------
  const [exportInput, setExportInput] = useState<SalesExportGenerateInput | null>(
    null
  );

  const graphqlClient = createGraphQLClientWithMiddleware("sales");

  const { refetch: refetchData, isLoading: isExporting } =
    useSalesOrderGenerateExportQuery<SalesOrderGenerateExportQuery>(
      graphqlClient,
      {
        input: exportInput,
      },
      {
        enabled: false,
      }
    );

  const exportHandler = async (data: IExporter) => {
      if (data) {
        const { type, start_date, end_date } = data;
        setExportInput({
          export_type: type as SalesOrderExportType,
          start_date: start_date,
          end_date: end_date,
        });
      }
    };
  // --------------------------- * ---------------------------

  const { modal, openModalHandler, closeModalHandler } = useModal();
  
  useEffect(() => {
    const fetchDataAndOpenWindow = async () => {
      try {
        const { data: exportData } = await refetchData();
        window.open(exportData?.SalesOrderGenerateExport || "", "_blank");
        enqueueSnackbar(`${t("button.export")}สำเร็จ`, {
          variant: "success",
        });
      } catch (error) {
        enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    };

    if (exportInput !== null) {
      fetchDataAndOpenWindow();
    }
  }, [enqueueSnackbar, exportInput, refetchData, t]);

  const options = [
    {
      value: "นำออกใบสั่งซื้อ",
    },
  ];

  const salesOrderTypeOptions = [
    {
      label: "รายเอกสาร",
      value: "sales_order",
    },
    {
      label: "รายสินค้า",
      value: "sales_order_item",
    }
  ]
  
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("sales.order.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("global.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    ...(isHaveApproval
      ? [
          {
            label: t("status.wait_approve"),
            path: `${pathname}?filter=wait_approve`,
          },
          {
            label: t("status.not_approved"),
            path: `${pathname}?filter=not_approved`,
          },
        ]
      : []),
    {
      label: t("status.approved"),
      path: `${pathname}?filter=approved`,
    },
    {
      label: t("status.expired"),
      path: `${pathname}?filter=expired`,
    },
    {
      label: t("status.finished"),
      path: `${pathname}?filter=finished`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">{t("sales.order.index")}</Typography>
        <ButtonLayout>
          <CustomizedButton
            title={`${t("button.create")}${t("sales.order.index")}`}
            variant="contained"
            onClick={() => navigate(`${pathname}/add`)}
          />
          {/* <ConfigurableAgGridEditButton tableRef={tableRef}/> */}
          <CustomizedMenuOptions
            isIcon
            label="contact-options"
            options={options}
            onSelect={(e) => {
              const value = e.target as HTMLElement;
              switch (value.innerText) {
                case "นำออกใบสั่งซื้อ":
                  openModalHandler();
                  break;
                default:
              }
            }}
          />
        </ButtonLayout>
      </HeaderLayout>
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      <SalesOrderTable isFilter={isFilter} ref={tableRef}/>
      <ExporterModal
        open={modal}
        closeModalHandler={closeModalHandler}
        typeOptions={salesOrderTypeOptions}
        exportHandler={exportHandler}
      />
    </>
  );
};

export default SalesOrder;
