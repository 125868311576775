import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  styled,
  //   SvgIcon,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { IModalUI } from "../../types/global";
// import { ReactComponent as NewTabIcon } from "../../assets/newTabIcon.svg";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2.5),
    paddingTop: "8px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2.5),
    paddingTop: "0",
  },
}));

const ModalUI = ({
  open,
  handleClose,
  fullWidth,
  maxWidth,
  title,
  titleSize,
  navigateTo,
  children,
  width,
  isLoading,
  isRed,
  startIcon,
  action,
  status,
  actionIcon,
  fullScreen,
  header,
}: IModalUI) => {
  return (
    <StyledDialog
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: width ?? "auto",
          },
        },
      }}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {isLoading && <LinearProgress />}
      {/* {navigateTo && (
        <IconButton
          onClick={() => {
            window.open(navigateTo, "_blank");
          }}
        >
          <SvgIcon
            // component={NewTabIcon}
            sx={{
              position: "absolute",
              right: 50,
              top: 20,
              color: (theme) => theme.palette.grey[500],
            }}
          />
        </IconButton>
      )} */}
      {handleClose ? (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}

      {header ? (
        header
      ) : startIcon ? (
        <Box sx={{ display: "flex", gap: 1, alignItems: "center", m: 0, p: 2 }}>
          {startIcon}
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              color: isRed ?? "inherit",
              padding: 0,
              fontSize: titleSize ? titleSize : "18px",
            }}
          >
            {title}
          </DialogTitle>
          {status}
          {actionIcon}
        </Box>
      ) : (
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              m: 0,
              p: 2,
              color: isRed ?? "inherit",
              fontSize: titleSize ? titleSize : "18px",
            }}
          >
            {title}
          </DialogTitle>
          {status}
          {actionIcon}
        </Box>
      )}
      <DialogContent>{children}</DialogContent>
      {action && <DialogActions>{action}</DialogActions>}
    </StyledDialog>
  );
};

export default ModalUI;
