import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { Typography } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import QuotationTable from "../../../components/Table/Sales/Quotation";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import useSalesCheckApproval from "../../../hooks/Sales/use-sales-check-approval";

const Quotation = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");
  const isHaveApproval = useSalesCheckApproval("quotation");
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("sales.quotation.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("global.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    ...(isHaveApproval
      ? [
          {
            label: t("status.wait_approve"),
            path: `${pathname}?filter=wait_approve`,
          },
          {
            label: t("status.not_approved"),
            path: `${pathname}?filter=not_approved`,
          },
        ]
      : []),
    {
      label: t("status.wait_accept"),
      path: `${pathname}?filter=wait_accept`,
    },
    {
      label: t("status.accepted"),
      path: `${pathname}?filter=accepted`,
    },
    {
      label: t("status.expired"),
      path: `${pathname}?filter=expired`,
    },
    {
      label: t("status.finished"),
      path: `${pathname}?filter=finished`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">{t("sales.quotation.index")}</Typography>
        <CustomizedButton
          title={`${t("button.create")}${t("sales.quotation.index")}`}
          variant="contained"
          onClick={() => navigate(`${pathname}/add`)}
        />
      </HeaderLayout>
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      <QuotationTable isFilter={isFilter} />
    </>
  );
};

export default Quotation;
