import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMemo, Fragment } from "react";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import CabinOutlinedIcon from "@mui/icons-material/CabinOutlined";
// import AddBusinessOutlinedIcon from "@mui/icons-material/AddBusinessOutlined";
import { useNavbar } from "../../../hooks/use-navbar";
import { COMPANY_NAME, CURRENT_VERSION } from "../../../config/variables";
import { LeftNavbarSection } from "./LeftNavbarSection";
import CustomizedScrollbar from "../../Custom/CustomizedScrollbar";
import { useStateContext } from "../../../contexts/auth-context";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";

const LeftNavbar = () => {
  const theme = useTheme();
  const navbar = useNavbar();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const {
    state: { authUser, permissions },
  } = useStateContext();
  const tenant_id = sessionStorage.getItem("tenant-id");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const viewPermission = useMemo(() => ["ALL", "SELF", "ALLOW"], []);

  const sections = useMemo(() => {
    return [
      permissions &&
        viewPermission.includes(permissions.contact?.view || "NONE") && {
          title: t("contact.index"),
          items: [
            {
              title: t("contact.index"),
              path: "/contact",
              icon: <PeopleOutlineOutlinedIcon fontSize="small" />,
              children: [
                {
                  title: "ทั้งหมด",
                  path: "/contact/all",
                },
                {
                  title: t("contact.contact_type.customer"),
                  path: "/contact/customer",
                },
                {
                  title: t("contact.contact_type.vendor"),
                  path: "/contact/vendor",
                },
                // {
                //   title: t("report"),
                //   path: "/contact/report",
                // },
              ],
            },
          ],
        },
      permissions &&
        viewPermission.includes(permissions.inventory?.view || "NONE") && {
          title: t("inventory.index"),
          items: [
            {
              title: t("inventory.index"),
              path: "/inventory",
              icon: <Inventory2OutlinedIcon fontSize="small" />,
              children: [
                viewPermission.includes(permissions.item.view) && {
                  title: t("inventory.items.index"),
                  path: "/inventory/items",
                },
                // {
                //   title: t("inventory.sales_bom.index"),
                //   path: "/inventory/sales_bom",
                // },
                viewPermission.includes(permissions.goods_receive.view) && {
                  title: t("inventory.goods_receive.index"),
                  path: "/inventory/goods_receive",
                },
                viewPermission.includes(permissions.goods_return.view) && {
                  title: t("inventory.goods_return.index"),
                  path: "/inventory/goods_return",
                },
                viewPermission.includes(permissions.goods_issue.view) && {
                  title: t("inventory.goods_issue.index"),
                  path: "/inventory/goods_issue",
                },
                viewPermission.includes(permissions.goods_transfer.view) && {
                  title: t("inventory.goods_transfer.index"),
                  path: "/inventory/goods_transfer",
                },
                viewPermission.includes(permissions.goods_adjustment.view) && {
                  title: t("inventory.goods_adjustment.index"),
                  path: "/inventory/goods_adjustment",
                },
                viewPermission.includes(permissions.inventory_report.view) && {
                  title: t("inventory.report"),
                  path: "/inventory/report",
                },
              ],
            },
          ],
        },
      // permissions &&
      //   viewPermission.includes(permissions.project?.view || "NONE") && {
      //     title: t("project.index"),
      //     items: [
      //       {
      //         title: t("project.index"),
      //         path: "/project",
      //         icon: <AddBusinessOutlinedIcon fontSize="small" />,
      //         children: [
      //           viewPermission.includes(permissions.projects.view) && {
      //             title: t("project.index"),
      //             path: "/project/projects",
      //           },
      //           viewPermission.includes(permissions.event.view) && {
      //             title: t("project.event.index"),
      //             path: "/project/events",
      //           },
      //           // {
      //           //   title: t("report"),
      //           //   path: "/project/report",
      //           // },
      //         ],
      //       },
      //     ],
      //   },
      permissions &&
        viewPermission.includes(permissions.sales?.view || "NONE") && {
          title: t("sales.index"),
          items: [
            {
              title: t("sales.index"),
              path: "/sales",
              icon: <ControlPointOutlinedIcon fontSize="small" />,
              children: [
                viewPermission.includes(permissions.quotation.view) && {
                  title: t("sales.quotation.index"),
                  path: "/sales/quotation",
                },
                viewPermission.includes(permissions.sales_order.view) && {
                  title: t("sales.order.index"),
                  path: "/sales/order",
                },
                viewPermission.includes(permissions.sales_return.view) && {
                  title: t("sales.return.index"),
                  path: "/sales/return",
                },
                viewPermission.includes(permissions.sales_report.view) && {
                  title: t("report"),
                  path: "/sales/report",
                },
              ],
            },
          ],
        },
      permissions &&
        viewPermission.includes(permissions.purchase?.view || "NONE") && {
          title: t("purchase.index"),
          items: [
            {
              title: t("purchase.index"),
              path: "/purchase",
              icon: <AddShoppingCartOutlinedIcon fontSize="small" />,
              children: [
                viewPermission.includes(permissions.purchase_request.view) && {
                  title: t("purchase.request.index"),
                  path: "/purchase/request",
                },
                viewPermission.includes(permissions.purchase_order.view) && {
                  title: t("purchase.order.index"),
                  path: "/purchase/order",
                },
                viewPermission.includes(permissions.purchase_return.view) && {
                  title: t("purchase.return.index"),
                  path: "/purchase/return",
                },
                viewPermission.includes(permissions.purchase_report.view) && {
                  title: t("report"),
                  path: "/purchase/report",
                },
              ],
            },
          ],
        },
      permissions &&
        viewPermission.includes(permissions.delivery?.view || "NONE") && {
          title: t("logistic.index"),
          items: [
            {
              title: t("logistic.index"),
              path: "/logistic",
              icon: <LocalShippingOutlinedIcon fontSize="small" />,
              children: [
                viewPermission.includes(permissions.delivery_order.view) && {
                  title: t("logistic.delivery_order.index"),
                  path: "/logistic/delivery_order",
                },
                // {
                //   title: t("logistic.delivery_trip.index"),
                //   path: "/logistic/delivery_trip",
                // },
              ],
            },
          ],
        },
      permissions &&
        viewPermission.includes(permissions.manufacture?.view || "NONE") && {
          title: t("manufacture.index"),
          items: [
            {
              title: t("manufacture.index"),
              path: "/manufacture",
              icon: <CabinOutlinedIcon fontSize="small" />,
              children: [
                viewPermission.includes(permissions.bom.view) && {
                  title: t("manufacture.bom.index"),
                  path: "/manufacture/bom",
                },
                viewPermission.includes(permissions.routing.view) && {
                  title: t("manufacture.routing.index"),
                  path: "/manufacture/routing",
                },
                viewPermission.includes(permissions.manufacture_order.view) && {
                  title: t("manufacture.order.index"),
                  path: "/manufacture/order",
                },
                // {
                //   title: t("manufacture.workOrder.index"),
                //   path: "/manufacture/work-order",
                // },
                viewPermission.includes(
                  permissions.manufacture_report.view
                ) && {
                  title: t("manufacture.report"),
                  path: "/manufacture/report",
                },
              ],
            },
          ],
        },
      {
        title: t("user.index"),
        items: [
          {
            title: t("user.index"),
            path: "/user",
            icon: <PeopleOutlineOutlinedIcon fontSize="small" />,
            children: [
              viewPermission.includes(
                permissions?.user_information.view || "NONE"
              ) && {
                title: t("user.account.index"),
                path: "/user/account",
              },
              viewPermission.includes(permissions?.role.view || "NONE") && {
                title: t("user.rbac.index"),
                path: "/user/rbac",
              },
              {
                title: t("user.setting.index"),
                path:
                  "/user/setting/" + authUser?.unique_id + "?subtab=inventory",
              },
            ],
          },
        ],
      },
      permissions &&
        viewPermission.includes(permissions.setting?.view || "NONE") && {
          title: t("setting.index"),
          items: [
            {
              title: t("setting.index"),
              path: "/setting",
              icon: <SettingsOutlinedIcon fontSize="small" />,
              children: [
                viewPermission.includes(permissions.setting_company.view) && {
                  title: t("setting.company.index"),
                  path: "/setting/company",
                },
                {
                  title: t("setting.package.sentence.you_package"),
                  path: "/setting/package",
                },
                viewPermission.includes(permissions.setting_approval.view) && {
                  title: t("setting.approval.index"),
                  path: "/setting/approval",
                },
                viewPermission.includes(permissions.setting_remark.view) && {
                  title: t("setting.remark.index"),
                  path: "/setting/remark",
                },
                viewPermission.includes(permissions.setting_inventory.view) && {
                  title: t("setting.inventory.index"),
                  path: "/setting/inventory",
                },
                viewPermission.includes(permissions.setting_logistic.view) && {
                  title: t("setting.logistic.index"),
                  path: "/setting/logistic",
                },
                // {
                //   title: t("setting.manufacture.index"),
                //   path: "/setting/manufacture",
                // },
                viewPermission.includes(permissions.setting_project.view) && {
                  title: t("setting.project.status"),
                  path: "/setting/project-status",
                },
                ["2eF5dAF6D", "CN000000"].includes(tenant_id ?? "") && {
                  title: t("setting.action.index"),
                  path: "/setting/action",
                },
              ].filter(Boolean),
            },
          ],
        },
    ];
  }, [authUser?.unique_id, permissions, t, viewPermission, tenant_id]);

  const fileredSections = sections.filter((section) => section);

  const formatSections = fileredSections.map((section) => {
    if (!section) {
      // Handle the case where section is false
      return null; // or some other appropriate value
    }
    const items = section.items.filter((item) => item);
    const formatItems = items.map((item) => ({
      ...item,
      children: item.children.filter((child) => child),
    }));
    return {
      title: section.title,
      items: formatItems,
    };
  });

  // const formatSections = fileredSections.map((section) => {
  //   const items = section.items.filter((item) => item !== false);
  //   const formatItems = items.map((item) => ({
  //     ...item,
  //     children: item.children.filter((child) => child !== false),
  //   }));
  //   return {
  //     title: section.title,
  //     items: formatItems,
  //   };
  // });

  const navItemNavigateHandle = (path: string) => {
    navigate(path);
    if (isSmallScreen) {
      navbar?.sidebar.setIsSidebarOpen(false);
    }
  };

  const content = (
    <CustomizedScrollbar
      sx={{
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100dvh",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box
            sx={{
              pt: 4,
              pl: 3,
              mb: 3,
            }}
          >
            <Link to={"/"}>
              <img
                style={{ cursor: "pointer", height: 45 }}
                src="/static/logo.png"
                alt="logo"
                onClick={() => navigate("/")}
              />
            </Link>
            <IconButton
              sx={{
                position: "absolute",
                right: 16,
                height: "auto",
                color: (theme) => theme.palette.primary.main,
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.main, 0.1),
                "&:hover": {
                  backgroundColor: (theme) =>
                    alpha(theme.palette.primary.main, 0.2),
                },
              }}
              onClick={() => navbar?.sidebar.setIsSidebarOpen(false)}
            >
              <MenuIcon fontSize="small" />
            </IconButton>
          </Box>
          <Divider variant="middle" />
          <Box sx={{ flexGrow: 1 }}>
            {formatSections.map((section) => (
              <Fragment key={section?.title}>
                {section?.title === t("user.index") && (
                  <Divider variant="middle" />
                )}
                <LeftNavbarSection
                  {...section}
                  isSmallScreen={isSmallScreen}
                  key={section?.title}
                  path={pathname}
                  onItemClick={navItemNavigateHandle}
                  // searchParams={searchParamsList}
                  sx={{
                    p: 0,
                    "&:first-of-type": {
                      pt: 1,
                      mt: 1,
                    },
                    mt: section?.title === t("user.index") ? 1 : 0,
                  }}
                />
              </Fragment>
            ))}
          </Box>
        </Box>
        <Box sx={{ width: "100%", py: 3 }}>
          <Typography align="center" sx={{ opacity: 0.52, fontSize: 14 }}>
            Powered by {COMPANY_NAME}
          </Typography>
          <Typography align="center" sx={{ opacity: 0.52, fontSize: 14 }}>
            Application version {CURRENT_VERSION}
          </Typography>
        </Box>
      </Box>
    </CustomizedScrollbar>
  );
  return (
    <Drawer
      open={navbar?.sidebar.isSidebarOpen}
      onClose={() => navbar?.sidebar.setIsSidebarOpen(false)}
      PaperProps={{
        sx: {
          backgroundColor: "#F9F9F9",
          borderRightColor: "divider",
          borderRightStyle: "solid",
          borderRightWidth: 1,
          width: 260,
          zIndex: 2,
        },
      }}
      variant={isSmallScreen ? "temporary" : "persistent"}
    >
      {content}
    </Drawer>
  );
};

export default LeftNavbar;
