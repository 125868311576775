import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import TocIcon from "@mui/icons-material/Toc";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { formatNumber } from "../../../../utils/dataTransformer";

import ItemNameCell from "./ItemNameCell";
import Confirmation from "../../../UI/Confirmation";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import ControlledTextField from "../../../Controller/ControlledTextField";
import CustomizedNumberTextField from "../../../Controller/ControlledNumberTextField";
import { IBom } from "../../../../types/Manufacture/bom";
import { IDefaultForm } from "../../../../types/global";
import { UseFieldArrayMove, UseFieldArrayRemove } from "react-hook-form";

// import { graphQLClientWithHeaderItem } from "../../../../services/graphqlClient";
// import {
//   ItemQueryVariables,
//   useItemQuery,
// } from "../../../../generated/inventory";
// import { IDefaultForm } from "../../../../types/global";

type Props = {
  fields: IBom["ingredient_list"];
  move: UseFieldArrayMove;
  remove: UseFieldArrayRemove;
  control: IDefaultForm["control"];
  errors: IDefaultForm["errors"];
  disabled: IDefaultForm["disabled"];
  isTooltip?: boolean;
  setItemIds: Dispatch<SetStateAction<string[]>>;
  setItemIdsSnapshot: Dispatch<SetStateAction<string[]>>;
  handleClickOpenItem?: (unique_id?: string) => void;
};

const BomItemList = ({
  fields,
  move,
  remove,
  control,
  errors,
  disabled,
  isTooltip,
  setItemIds,
  setItemIdsSnapshot,
  handleClickOpenItem,
}: Props) => {
  const [headers, setHeaders] = useState<
    { label: string; width?: number; align?: TableCellProps["align"] }[]
  >([]);

  const [deletedIndex, setDeletedIndex] = useState<number>(0);

  const [openDeleteItemConfirmation, setOpenDeleteItemConfirmation] =
    useState(false);

  const openDeleteItemConfirmationHandler = (index: number) => {
    setOpenDeleteItemConfirmation(true);
    setDeletedIndex(index);
  };

  const closeDeleteItemConfirmationHandler = () => {
    setOpenDeleteItemConfirmation(false);
    setDeletedIndex(0);
  };

  const deleteItemConfirmationAction = () => {
    setItemIds((prev) => {
      return prev.filter((_, index) => index !== deletedIndex);
    });
    setItemIdsSnapshot((prev) => {
      return prev.filter((_, index) => index !== deletedIndex);
    });
    remove(deletedIndex);
    closeDeleteItemConfirmationHandler();
  };

  const dragEndHandler = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    move(result.source.index, result.destination.index);
  };

  useEffect(() => {
    if (disabled) {
      setHeaders([
        { label: "รายการ", width: 40 },
        { label: "วัตถุดิบ", width: 250 },
        { label: "จำนวนที่ต้องใช้", width: 150, align: "right" },
        { label: "ต้นทุนต่อหน่วย", width: 150, align: "right" },
        { label: "หน่วย", width: 150 },
      ]);
    } else {
      setHeaders([
        { label: "รายการ", width: 40 },
        { label: "วัตถุดิบ", width: 250 },
        { label: "จำนวนที่ต้องใช้", width: 150, align: "right" },
        { label: "ต้นทุนต่อหน่วย", width: 150, align: "right" },
        { label: "หน่วย", width: 150 },
        { label: "", width: 10 },
      ]);
    }
  }, [disabled]);

  return (
    <Box sx={{ my: 3 }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
          >
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align={!header.align ? "center" : header.align}
                  key={index}
                  width={header.width}
                >
                  <Box
                    display="flex"
                    gap={1}
                    justifyContent={!header.align ? "center" : header.align}
                  >
                    <Typography
                      textAlign="center"
                      fontSize={14}
                      fontWeight={700}
                    >
                      {header.label}
                    </Typography>
                    {isTooltip && index === 2 && (
                      <CustomizedTooltip title="จำนวนด้านล่างคือจำนวนที่ต้องใช้ตาม BOM">
                        <InfoOutlinedIcon fontSize="small" />
                      </CustomizedTooltip>
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={dragEndHandler}>
            <Droppable droppableId="droppable" isDropDisabled={disabled}>
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {fields?.map((row: any, index: number) => (
                    <Draggable
                      key={row.id}
                      draggableId={"ingredient-" + row.id}
                      index={index}
                      isDragDisabled={disabled}
                    >
                      {(provided) => (
                        <TableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{
                            ...provided.draggableProps.style,
                            breakInside: "avoid",
                          }}
                        >
                          <TableCell align="center">
                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {!disabled && <TocIcon fontSize="small" />}
                              <Typography fontSize={14}>{index + 1}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            <ItemNameCell
                              index={index}
                              control={control}
                              errors={errors}
                              disabled={disabled}
                              field={row}
                              handleClickOpenItem={handleClickOpenItem}
                            />
                          </TableCell>
                          <TableCell align="right">
                            {disabled ? (
                              <Typography fontSize={14}>
                                {formatNumber(parseFloat(row.qty))}
                              </Typography>
                            ) : (
                              <CustomizedNumberTextField
                                name={`ingredient_list[${index}].qty`}
                                control={control}
                                error={Boolean(
                                  errors[`ingredient_list`] &&
                                    errors[`ingredient_list`][index] &&
                                    errors[`ingredient_list`][index].qty
                                )}
                                // helperText={
                                //   fields[index].qty
                                //     ? formatNumber(
                                //         parseInt(fields[index].qty ?? "0")
                                //       )
                                //     : null
                                // }
                              />
                            )}
                          </TableCell>

                          <TableCell align="right">
                            {disabled ? (
                              <Typography fontSize={14}>
                                {formatNumber(
                                  parseFloat(row.cost_per_unit) || 0
                                )}
                              </Typography>
                            ) : (
                              <Box>
                                <CustomizedNumberTextField
                                  name={`ingredient_list[${index}].cost_per_unit`}
                                  control={control}
                                  // error={Boolean(
                                  //   errors[`ingredient_list`] &&
                                  //     errors[`ingredient_list`][index] &&
                                  //     errors[`ingredient_list`][index]
                                  //       .cost_price
                                  // )}
                                  // helperText={
                                  //   fields[index].cost_price
                                  //     ? formatNumber(
                                  //         parseInt(
                                  //           fields[index].cost_price ?? "0"
                                  //         )
                                  //       )
                                  //     : null
                                  // }
                                />
                              </Box>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {disabled ? (
                              <Typography fontSize={14}>{row.uom}</Typography>
                            ) : (
                              <ControlledTextField
                                name={`ingredient_list[${index}].uom`}
                                control={control}
                                disabled={true}
                              />
                            )}
                          </TableCell>
                          {!disabled && (
                            <TableCell align="center">
                              <IconButton
                                aria-label="delete"
                                size="small"
                                sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                                onClick={() =>
                                  openDeleteItemConfirmationHandler(index)
                                }
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
      {/* <ModalUI
        open={openItemDetailModal}
        handleClose={handleCloseItemDetailModal}
        navigateTo={`/inventory/items/${item.document_id}`}
        title="รายละเอียดสินค้า"
        fullWidth
      >
        <ItemInfo
          control={itemInfoControl}
          errors={itemInfoErrors}
          getValues={getItemInfoValues}
          handleSubmit={handleSubmitItemInfo}
          reset={resetItemInfo}
          setValue={setItemInfoValue}
          onCancel={handleCloseItemDetailModal}
          viewOnly={true}
        />
      </ModalUI> */}
      <Confirmation
        title="ต้องการลบสินค้าใช่หรือไม่"
        open={openDeleteItemConfirmation}
        action={deleteItemConfirmationAction}
        handleClose={closeDeleteItemConfirmationHandler}
      />
    </Box>
  );
};

export default BomItemList;
