import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";

import { useStateContext } from "../contexts/auth-context";

import { useUserInformationQuery } from "../generated/user-infomation";

import { defaultPermission } from "../utils/userInfo";
import { mergeUserPermissions } from "../utils/Formatter/Role";

import { ICreatedBy } from "../types/global";
import { IPermission, IRole } from "../types/Auth/user";
import { Cookies } from "react-cookie";

const cookies = new Cookies();

export const useUserRole = () => {
  const selected_tenant = cookies.get("selected_tenant");

  const {
    dispatch,
    state: { authUser },
  } = useStateContext();

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  // console.log("selected", selected_tenant);
  // console.log("auth user", authUser);

  const { refetch, isLoading, isFetching } = useUserInformationQuery(
    graphQLClient,
    {
      uniqueInput: { user_unique_id: authUser?.unique_id },
    },
    {
      enabled: false,
    }
  );

  useEffect(() => {
    if (selected_tenant && authUser) {
      const getData = async () => {
        const userData = await refetch();
        return userData.data?.userinformation;
      };

      getData().then((user) => {
        if (user) {
          let permission: IPermission = defaultPermission;
          if (user?.user_unique_id !== "NPR-ADMIN") {
            permission = mergeUserPermissions(
              (user?.role_list as IRole[]) || []
            );
          }
          dispatch({
            type: "SET_PERMISSION",
            payload: permission,
          });
        }
      });
    }
  }, [authUser, dispatch, refetch, selected_tenant]);

  return selected_tenant && authUser && isLoading;
};

export const useRoleSelfPermission = (
  createdBy?: ICreatedBy | null,
  relatedUserList?: ICreatedBy[] | null
) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const {
    state: { authUser, permissions },
  } = useStateContext();

  useEffect(() => {
    if (createdBy) {
      for (const value of pathname.split("/")) {
        let path_value = value;
        if (pathname.split("/").some((path) => path === "sales")) {
          if (value === "return") path_value = "sales_return";
          if (value === "order") path_value = "sales_order";
        }
        if (pathname.split("/").some((path) => path === "purchase")) {
          if (value === "return") path_value = "purchase_return";
          if (value === "order") path_value = "purchase_order";
        }
        if (permissions?.hasOwnProperty(path_value)) {
          const viewPermission = permissions[path_value].view;
          if (
            viewPermission === "SELF" &&
            createdBy.user_unique_id !== authUser?.unique_id &&
            (!relatedUserList ||
              relatedUserList.every(
                (relatedUser) =>
                  relatedUser.user_unique_id !== authUser?.unique_id
              ))
          )
            navigate("/unauthorized");
        }
      }
    }
  }, [
    authUser?.unique_id,
    createdBy,
    relatedUserList,
    navigate,
    pathname,
    permissions,
  ]);
};
