import { Backdrop, Box, Typography } from "@mui/material";

type Props = {
  isLoading: boolean;
};
const LoadingMascot = ({ isLoading }: Props) => {
  return (
    <Backdrop
      sx={(theme) => ({
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        zIndex: theme.zIndex.drawer + 99999,
      })}
      open={isLoading}
    >
      {/* <div className="loading-container"> */}
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <img
          src="/static/mascot.png"
          alt="Loading Mascot"
          className="mascot-image"
        />
        <Typography variant="h5">กำลังดำเนินการ</Typography>
      </Box>
      {/* </div> */}
    </Backdrop>
  );
};

export default LoadingMascot;
