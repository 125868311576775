import { Box, Collapse, SxProps, Theme, Typography } from "@mui/material";
import ControlledDatePicker from "../../Controller/ControlledDatePicker";
import { CalendarIcon } from "@mui/x-date-pickers-pro";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { IDefaultForm, ISelectOption } from "../../../types/global";
import dayjs from "dayjs";
import GanttChartStatus, { GanttStatus } from "./GanttChartStatus";

import ControlledTextField from "../../Controller/ControlledTextField";
import CustomizedButton from "../../Custom/CustomizedButton";
import { useEffect, useState } from "react";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import ControlledSetSelect from "../../Controller/ControlledSetSelect";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  CreatablesQuery,
  EntityTypeEnum,
  useCreatablesQuery,
} from "../../../generated/creatable";
import {
  ManufactureTagsFindByEntityQuery,
  useManufactureTagsFindByEntityQuery,
} from "../../../generated/manufacture";
import { UsersQuery, useUsersQuery } from "../../../generated/company-user";
import {
  UserInformationsQuery,
  useUserInformationsQuery,
} from "../../../generated/user-infomation";
import { mergeUsersFormatter } from "../../../utils/Formatter/User";
import { IUser, IUserInfo } from "../../../types/Auth/user";

interface GanttChartFilterProps {
  startDate: any;
  endDate: any;
  control: IDefaultForm["control"];
  setValue: IDefaultForm["setValue"];
  handleDateChange: (direction: "next" | "prev") => void;
  handleFilterCilck: () => void;
  ganttStatus: GanttStatus;
  sx?: SxProps<Theme>;
}

const GanttChartFilter = ({
  startDate,
  endDate,
  control,
  setValue,
  handleDateChange,
  handleFilterCilck,
  ganttStatus,
  sx,
}: GanttChartFilterProps) => {
  const formattedMonth =
    startDate.month() !== endDate.month()
      ? startDate.year() !== endDate.year()
        ? `${startDate.format("MMMM YYYY")} - ${endDate.format("MMMM YYYY")}`
        : `${startDate.format("MMMM")} - ${endDate.format("MMMM YYYY")}`
      : startDate.format("MMMM YYYY");

  const [collapsed, setCollapsed] = useState(false);

  const [typeOptions, setTypeOptions] = useState<ISelectOption[]>([]);
  const [tagOptions, setTagOptions] = useState<ISelectOption[]>([]);
  const [userOptions, setUserOptions] = useState<ISelectOption[]>([]);

  const graphQLClientWithHeaderSetting: GraphQLClient =
    createGraphQLClientWithMiddleware("general");
  const graphQLClientWithHeaderManufacture: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");
  const graphQLClientWithHeaderCompany: GraphQLClient =
    createGraphQLClientWithMiddleware("company-user");

  const { data: userData, isSuccess: isSuccessUser } =
    useUsersQuery<UsersQuery>(graphQLClientWithHeaderCompany);

  const { data: userInformationData, isSuccess: isSuccessUserInformation } =
    useUserInformationsQuery<UserInformationsQuery>(
      graphQLClientWithHeaderSetting
    );

  const { data: typeData, isSuccess: isSuccessType } =
    useCreatablesQuery<CreatablesQuery>(graphQLClientWithHeaderSetting, {
      filterInput: {
        usage_field_name: "manufacture_type",
        usage_field_type: EntityTypeEnum.ManufactureOrder,
      },
    });

  const { data: tagListData, isSuccess: isSuccessTagList } =
    useManufactureTagsFindByEntityQuery<ManufactureTagsFindByEntityQuery>(
      graphQLClientWithHeaderManufacture,
      {
        entityName: EntityTypeEnum.ManufactureOrder as any,
      }
    );

  useEffect(() => {
    if (isSuccessType) {
      const getTypeOptions = async () => {
        setTypeOptions(
          typeData.creatables?.map((option) => {
            return {
              value: option.name,
              label: option.name,
            };
          })
        );
      };
      getTypeOptions();
    }
  }, [isSuccessType, typeData]);

  useEffect(() => {
    if (isSuccessTagList) {
      const getTagOptions = async () => {
        setTagOptions(
          tagListData.manufactureTagsFindByEntity?.map((tag) => {
            return {
              value: tag.name,
              label: tag.name,
            };
          })
        );
      };
      getTagOptions();
    }
  }, [isSuccessTagList, tagListData]);

  useEffect(() => {
    if (isSuccessUser && isSuccessUserInformation) {
      const getUserOptions = async () => {
        const mergeData = mergeUsersFormatter(
          userData.users as IUser[],
          userInformationData.userInformations as IUserInfo[]
        );

        setUserOptions(
          mergeData.map((user) => {
            const firstName = user?.first_name ?? "";
            const lastName = user?.last_name ?? "";
            return {
              value: user.unique_id,
              label: firstName + " " + lastName,
            };
          }) || []
        );
      };
      getUserOptions();
    }
  }, [userData, userInformationData]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "4px",
            alignItems: "center",
            ":hover": { cursor: "pointer" },
            userSelect: "none",
          }}
          onClick={() => setCollapsed(!collapsed)}
        >
          <Typography color={"#2167D3"} fontSize={14} fontWeight={600}>
            ตัวกรอง
          </Typography>
          {collapsed && (
            <KeyboardArrowUpOutlinedIcon
              sx={{ fontSize: "16px", color: "#2167D3" }}
            />
          )}
          {!collapsed && (
            <KeyboardArrowDownOutlinedIcon
              sx={{ fontSize: "16px", color: "#2167D3" }}
            />
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Collapse in={collapsed}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              padding: "16px",
              gap: "16px",
              backgroundColor: "#F9F9F9",
              borderRadius: "8px",
              marginTop: "12px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                gap: "16px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  height: "37px",
                  flex: 1,
                  gap: "16px",
                }}
              >
                <ControlledTextField
                  name={"item_unique_id"}
                  label="รหัสสินค้า"
                  control={control}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                    },
                    height: "37px",
                  }}
                  InputLabelProps={{ style: { fontSize: "14px" } }}
                />
                <ControlledTextField
                  name={"item_name"}
                  label="ชื่อสินค้า"
                  control={control}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                    },
                    height: "37px",
                  }}
                  InputLabelProps={{ style: { fontSize: "14px" } }}
                />
                <ControlledTextField
                  name={"customer_name"}
                  label="ชื่อลูกค้า"
                  control={control}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                    },
                    height: "37px",
                  }}
                  InputLabelProps={{ style: { fontSize: "14px" } }}
                />
                <ControlledSetSelect
                  name={"related_user_list"}
                  label={"ผู้เกี่ยวข้อง"}
                  control={control}
                  options={userOptions || []}
                  sx={{
                    borderRadius: "8px",
                    height: "37px",
                    "& .MuiFormControl-root": {
                      top: "-10px",
                    },
                  }}
                  InputLabelProps={{ fontSize: "14px" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  height: "37px",
                  flex: 1,
                  gap: "16px",
                }}
              >
                <ControlledSetSelect
                  name={"type"}
                  label={"ประเภทการผลิต"}
                  control={control}
                  options={typeOptions || []}
                  sx={{
                    borderRadius: "8px",
                    height: "37px",
                    "& .MuiFormControl-root": {
                      top: "-10px",
                    },
                  }}
                  InputLabelProps={{ fontSize: "14px" }}
                />
                <ControlledSetSelect
                  name={"tag_list"}
                  label={"การจัดกลุ่ม"}
                  control={control}
                  options={tagOptions || []}
                  sx={{
                    borderRadius: "8px",
                    height: "37px",
                    "& .MuiFormControl-root": {
                      top: "-10px",
                    },
                  }}
                  InputLabelProps={{ fontSize: "14px" }}
                />
                <ControlledTextField
                  name={"reference_unique_id"}
                  label={"เอกสารอ้างอิง"}
                  control={control}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                    },
                    height: "37px",
                  }}
                  InputLabelProps={{ style: { fontSize: "14px" } }}
                />
                <ControlledSetSelect
                  name={"status"}
                  label={"สถานะ"}
                  control={control}
                  options={[
                    { label: "ร่าง", value: "draft" },
                    { label: "รอผลิต", value: "pending_manu" },
                    { label: "กำลังผลิต", value: "in_progress" },
                    { label: "เสร็จสิ้น", value: "finished" },
                    { label: "ยกเลิก", value: "cancelled" },
                  ]}
                  sx={{
                    borderRadius: "8px",
                    height: "37px",
                    "& .MuiFormControl-root": {
                      top: "-10px",
                    },
                  }}
                  InputLabelProps={{ fontSize: "14px" }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <CustomizedButton
                  title="กรองข้อมูล"
                  variant="contained"
                  onClick={handleFilterCilck}
                  sx={{
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "600",
                    height: "37px",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Collapse>
        <Box>
          <GanttChartStatus data={ganttStatus} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "34px",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "inline-flex",
              flexDirection: "row",
              gap: "10px",
              flex: 1,
            }}
          >
            <ControlledDatePicker
              control={control}
              name="selectedDate"
              label="วันที่"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                },
                maxWidth: "200px",
              }}
            />
            <Box
              sx={{
                display: "inline-flex",
                flexDirection: "row",
                alignItems: "center",
                ":hover": { cursor: "pointer" },
                userSelect: "none",
                gap: "4px",
              }}
              onClick={() => {
                setValue("selectedDate", dayjs().toDate());
              }}
            >
              <CalendarIcon sx={{ color: "#2167D3" }} />
              <Typography color={"#2167D3"} fontSize={16}>
                วันนี้
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "inline-flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <KeyboardArrowLeftIcon
              sx={{
                color: "#2167D3",
                fontSize: 26,
                ":hover": { cursor: "pointer" },
              }}
              onClick={() => handleDateChange("prev")}
            />
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 600,
                color: "#2167D3",
                paddingX: 1.5,
              }}
            >
              {formattedMonth}
            </Typography>
            <KeyboardArrowRightIcon
              sx={{
                color: "#2167D3",
                fontSize: 26,
                ":hover": { cursor: "pointer" },
              }}
              onClick={() => {
                handleDateChange("next");
              }}
            />
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "inline-flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Box
              sx={{
                display: "inline-flex",
              }}
            ></Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GanttChartFilter;
