import { useEffect, useMemo, useState } from "react";
import { useSalesApprovalTemplatesQuery, SalesApprovalTemplatesQuery } from "../../generated/sales";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";

const useSalesCheckApproval = (documentType: string) => {
  const graphQLClientWithHeaderSales = useMemo(
    () => createGraphQLClientWithMiddleware("sales"),
    []
  );

  const { data: approvalTemplatesData, refetch } = useSalesApprovalTemplatesQuery<SalesApprovalTemplatesQuery>(
    graphQLClientWithHeaderSales, {}
  );

  const [isHaveApproval, setIsHaveApproval] = useState(false);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    setIsHaveApproval(
      approvalTemplatesData?.approvalTemplates?.some(
        (template) => template?.document_type === documentType && template?.is_have_approval
      ) ?? false
    );
  }, [approvalTemplatesData, documentType]);

  return isHaveApproval;
};

export default useSalesCheckApproval;