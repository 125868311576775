import { useState, Fragment, useRef, useCallback, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";

import HeaderLayout from "../../../components/UI/HeaderLayout";
import ManufactureOrderTable from "../../../components/Table/Manufacture/Order";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import ButtonLayout from "../../../components/UI/ButtonLayout";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SortIcon from "@mui/icons-material/Sort";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import ConfigurableAgGridEditButton from "../../../components/UI/ConfigurableAgGrid/ConfigurableAgGridEditButton";
import { ConfigurableAgGridReact } from "../../../components/UI/ConfigurableAgGrid/ConfigurableAgGrid";
import GanttChart from "../../../components/UI/GanttChart/GanttChart";
import GanttChartFilter from "../../../components/UI/GanttChart/GanttChartFilter";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { MANUFACTURE_ORDER_AGGRID } from "../../../services/AgGrid/ManufactureAgGrid";
import { formatDataForGantt } from "../../../utils/Formatter/ManufactureOrder";
import { GanttData } from "../../../types/Manufacture/gantt";
import { GanttStatus } from "../../../components/UI/GanttChart/GanttChartStatus";

const ManufactureOrder = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tableRef = useRef<ConfigurableAgGridReact<any>>();
  const ganttRef = useRef<any>(null);
  const isFilter = searchParams.get("filter");
  const graphQLClientWithHeaderManufacture: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  dayjs.extend(isoWeek);
  const startOfWeek = dayjs().startOf("isoWeek");
  const endOfWeek = dayjs().endOf("isoWeek");
  const [startDate, setStartDate] = useState(dayjs(startOfWeek));
  const [endDate, setEndDate] = useState(dayjs(endOfWeek));
  const [formattedData, setFormattedData] = useState<GanttData>({
    tasks: [],
    links: [],
  });
  const [dataStatus, setDataStatus] = useState<GanttStatus>({
    draft: 0,
    pending_manu: 0,
    in_progress: 0,
    finished: 0,
    cancelled: 0,
  });
  const [ganttFilter, setGanttFilter] = useState({
    item_unique_id: "",
    item_name: "",
    type: [],
    tag_list: [],
    status: ["draft", "pending_manu", "in_progress", "finished"],
    related_user_list: [],
    reference_unique_id: "",
    customer_name: "",
  });
  const methods = useForm({
    defaultValues: {
      selectedDate: dayjs(),
      item_unique_id: "",
      item_name: "",
      type: [],
      tag_list: [],
      status: ["draft", "pending_manu", "in_progress", "finished"],
      related_user_list: [],
      reference_unique_id: "",
      customer_name: "",
    },
  });

  const { control, watch, setValue, getValues } = methods;

  const handleDateChange = (direction: "next" | "prev") => {
    const newStartDate =
      direction === "next"
        ? startDate.add(1, "week")
        : startDate.subtract(1, "week");
    setStartDate(newStartDate);
    setEndDate(newStartDate.endOf("isoWeek"));
  };

  const handleFilterClick = () => {
    const item_unique_id = getValues("item_unique_id");
    const item_name = getValues("item_name");
    const type = getValues("type");
    const tag_list = getValues("tag_list");
    const status = getValues("status");
    const related_user_list = getValues("related_user_list");
    const reference_unique_id = getValues("reference_unique_id");
    const customer_name = getValues("customer_name");

    setGanttFilter({
      item_unique_id: item_unique_id,
      item_name: item_name,
      type: type,
      tag_list: tag_list,
      status: status,
      related_user_list: related_user_list,
      reference_unique_id: reference_unique_id,
      customer_name: customer_name,
    });
  };

  const fetchGanttData = useCallback(async () => {
    try {
      const createFilterModel = (condition: number) => {
        const filterModel: any = {
          aggrid_status: {
            filterType: "set",
            values: ganttFilter.status,
          },
        };

        if (condition === 1) {
          filterModel.production_date = {
            filterType: "date",
            type: "inRange",
            dateFrom: startDate.toDate(),
            dateTo: endDate.toDate(),
          };
        }

        if (condition === 2) {
          filterModel.production_completion_date = {
            filterType: "date",
            type: "inRange",
            dateFrom: startDate.toDate(),
            dateTo: endDate.toDate(),
          };
        }

        if (ganttFilter.item_unique_id) {
          filterModel.item_unique_id = {
            filterType: "text",
            type: "contains",
            filter: ganttFilter.item_unique_id,
          };
        }

        if (ganttFilter.item_name) {
          filterModel.item_name = {
            filterType: "text",
            type: "contains",
            filter: ganttFilter.item_name,
          };
        }

        if (ganttFilter.tag_list && ganttFilter.tag_list.length > 0) {
          filterModel.tag_list = {
            filterType: "objectArray",
            filter: {
              name: {
                filterType: "set",
                values: ganttFilter.tag_list,
              },
            },
            type: "some",
          };
        }

        if (ganttFilter.type && ganttFilter.type.length > 0) {
          if (ganttFilter.type.length === 1) {
            filterModel.type = {
              filterType: "text",
              type: "equals",
              filter: ganttFilter.type[0],
            };
          } else {
            filterModel.type = {
              filterType: "text",
              operator: "OR",
              ...ganttFilter.type.reduce(
                (acc: any, type: string, index: number) => {
                  acc[`condition${index + 1}`] = {
                    filterType: "text",
                    type: "equals",
                    filter: type,
                  };
                  return acc;
                },
                {}
              ),
            };
          }
        }

        if (ganttFilter.customer_name) {
          filterModel.customer_name = {
            filterType: "text",
            type: "contains",
            filter: ganttFilter.customer_name,
          };
        }

        return filterModel;
      };

      const firstFilterModel = createFilterModel(1);
      const secondFilterModel = createFilterModel(2);

      const { manufactureOrdersFindManyAggrid: firstMOData } =
        await graphQLClientWithHeaderManufacture.request(
          MANUFACTURE_ORDER_AGGRID,
          {
            aggridInput: {
              startRow: 0,
              endRow: 10000,
              filterModel: firstFilterModel,
            },
          }
        );
      const { manufactureOrdersFindManyAggrid: secondMOData } =
        await graphQLClientWithHeaderManufacture.request(
          MANUFACTURE_ORDER_AGGRID,
          {
            aggridInput: {
              startRow: 0,
              endRow: 10000,
              filterModel: secondFilterModel,
            },
          }
        );

      const mergeData = [
        ...new Map(
          [...firstMOData.data, ...secondMOData.data].map((item) => [
            item.id,
            item,
          ])
        ).values(),
      ];

      const { ganttData, ganttStatus } = formatDataForGantt(
        mergeData,
        ganttFilter,
        startDate.toDate(),
        endDate.toDate()
      );

      setDataStatus(ganttStatus);
      setFormattedData({ tasks: ganttData, links: [] });
    } catch (error) {
      console.error(error);
    }
  }, [startDate, endDate, ganttFilter]);

  useEffect(() => {
    fetchGanttData();
  }, [ganttFilter, startDate, endDate]);

  const selectedDate = watch("selectedDate");
  useEffect(() => {
    const newStartDate = dayjs(selectedDate).startOf("isoWeek");
    setStartDate(newStartDate);
    setEndDate(newStartDate.endOf("isoWeek"));
  }, [selectedDate]);

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("manufacture.index"),
      to: "/manufacture",
    },
    {
      name: t("manufacture.order.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("inventory.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.pending_manu"),
      path: `${pathname}?filter=pending_manu`,
    },
    {
      label: t("status.in_progress"),
      path: `${pathname}?filter=in_progress`,
    },
    {
      label: t("status.finished"),
      path: `${pathname}?filter=finished`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const [view, setView] = useState<string>("table");

  const handleView = (_: any, newView: any) => {
    setView(newView);
  };

  const renderView = (view: string) => {
    switch (view) {
      case "table":
        return (
          <>
            <CustomizedTab table tabs={tabs} currentTab={currentTab} divider />
            <ManufactureOrderTable isFilter={isFilter} ref={tableRef} />
          </>
        );
      case "gantt":
        return (
          <>
            <GanttChartFilter
              control={control}
              setValue={setValue}
              startDate={startDate}
              endDate={endDate}
              handleDateChange={handleDateChange}
              handleFilterCilck={handleFilterClick}
              ganttStatus={dataStatus}
              sx={{ marginBottom: "20px" }}
            />
            <GanttChart
              ref={ganttRef}
              data={formattedData}
              watch={watch}
              startDate={startDate}
              endDate={endDate}
            />
          </>
        );
      default:
        return (
          <>
            <CustomizedTab table tabs={tabs} currentTab={currentTab} divider />
            <ManufactureOrderTable isFilter={isFilter} ref={tableRef} />
          </>
        );
    }
  };

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">{t("manufacture.order.index")}</Typography>
        <ButtonLayout>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              alignContent: "center",
              height: "35px",
            }}
          >
            <CustomizedButton
              title={`สร้าง${t("manufacture.order.index")}`}
              variant="contained"
              onClick={() => navigate(`/manufacture/order/add`)}
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "24px",
              }}
            />
            <ToggleButtonGroup
              color="primary"
              value={view}
              size="small"
              exclusive
              onChange={handleView}
              aria-label="view"
            >
              <ToggleButton value="table" aria-label="table view">
                <FormatListBulletedIcon />
              </ToggleButton>
              <ToggleButton
                disabled
                value="calendar"
                aria-label="calendar view"
              >
                <CalendarTodayIcon />
              </ToggleButton>
              <ToggleButton value="gantt" aria-label="gantt view">
                <SortIcon />
              </ToggleButton>
            </ToggleButtonGroup>
            {view === "table" && (
              <ConfigurableAgGridEditButton tableRef={tableRef} />
            )}
          </Box>
        </ButtonLayout>
      </HeaderLayout>
      {renderView(view)}
    </Fragment>
  );
};

export default ManufactureOrder;
