import { Box, BoxProps, Typography, TypographyProps } from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";

interface Props {
  status?: "active" | "inactive" | string | null;
  text?: string;
  fontSize?: string;
  bgColor?: string;
  fontColor?: string;
}

const StyledStatus = styled(Box)<BoxProps>(({ bgcolor }) => ({
  "&": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "3px",
    backgroundColor: bgcolor,
    padding: ".25rem 0",
    minWidth: 95,
  },
}));

const StyledTypography = styled(Typography)<TypographyProps>(
  ({ color, fontSize }) => ({
    "&": {
      variant: "button",
      color: color,
      fontSize: fontSize ?? ".875rem",
    },
  })
);

const CustomizedStatus = ({
  status,
  text,
  fontSize,
  bgColor,
  fontColor,
}: Props) => {
  const { t } = useTranslation();
  switch (status) {
    case "pre_sales":
    case "ร่าง":
    case "draft":
      return (
        <StyledStatus bgcolor="#F2F2F2">
          <StyledTypography
            sx={{
              color: "#737373",
              textTransform: text ? "lowercase" : "none",
            }}
            fontSize={fontSize}
          >
            {text ?? t("status.draft")}
          </StyledTypography>
        </StyledStatus>
      );
    case "active":
      return (
        <StyledStatus bgcolor="#E6F9F0">
          <StyledTypography sx={{ color: "#22915A" }} fontSize={fontSize}>
            {t("status.active")}
          </StyledTypography>
        </StyledStatus>
      );

    case "is_active":
      return (
        <StyledStatus bgcolor="#FFF4E5">
          <StyledTypography sx={{ color: "#E58000" }} fontSize={fontSize}>
            รอสแกน
          </StyledTypography>
        </StyledStatus>
      );
    case "is_scan":
      return (
        <StyledStatus bgcolor="#E6F9F0">
          <StyledTypography sx={{ color: "#22915A" }} fontSize={fontSize}>
            สแกนแล้ว
          </StyledTypography>
        </StyledStatus>
      );
    case "post_sales":
    case "รอโอนย้าย":
    case "wait_transfer":
      return (
        <StyledStatus bgcolor="#E5F0FF">
          <StyledTypography
            sx={{
              color: "#2167D3",
              textTransform: text ? "lowercase" : "none",
            }}
            fontSize={fontSize}
          >
            {text ?? t("status.wait_transfer")}
          </StyledTypography>
        </StyledStatus>
      );
    case "เสร็จสิ้น":
    case "finished":
      return (
        <StyledStatus bgcolor="#E6F9F0">
          <StyledTypography
            sx={{
              color: "#22915A",
              textTransform: text ? "lowercase" : "none",
            }}
            fontSize={fontSize}
          >
            {text ?? t("status.finished")}
          </StyledTypography>
        </StyledStatus>
      );
    case "inactive":
      return (
        <StyledStatus bgcolor="#FCE8E8">
          <StyledTypography sx={{ color: "#E41B1B" }} fontSize={fontSize}>
            {t("status.in_active")}
          </StyledTypography>
        </StyledStatus>
      );
    case "not_completed":
    case "failed":
      return (
        <StyledStatus bgcolor="#FCE8E8">
          <StyledTypography sx={{ color: "#E41B1B" }} fontSize={fontSize}>
            {t("status.not_completed")}
          </StyledTypography>
        </StyledStatus>
      );
    case "expired":
      return (
        <StyledStatus bgcolor="#FCE8E8">
          <StyledTypography sx={{ color: "#E41B1B" }} fontSize={fontSize}>
            {t("status.expired_date")}
          </StyledTypography>
        </StyledStatus>
      );
    case "ยกเลิก":
    case "cancelled":
      return (
        <StyledStatus bgcolor="#BDBDBD">
          <StyledTypography
            sx={{
              color: "#FFFFFF",
              textTransform: text ? "lowercase" : "none",
            }}
            fontSize={fontSize}
          >
            {t("status.cancelled")}
          </StyledTypography>
        </StyledStatus>
      );
    case "late":
      return (
        <StyledStatus bgcolor="#FCE8E8">
          <StyledTypography
            sx={{
              color: "#E41B1B",
            }}
            fontSize={fontSize}
          >
            {t("status.late")}
          </StyledTypography>
        </StyledStatus>
      );

    case "support":
    case "รออนุมัติ":
    case "wait_approve":
      return (
        <StyledStatus bgcolor="#FFF4E5">
          <StyledTypography
            sx={{
              color: "#E58000",
              textTransform: text ? "lowercase" : "none",
            }}
            fontSize={fontSize}
          >
            {text ?? t("status.wait_approve")}
          </StyledTypography>
        </StyledStatus>
      );
    case "รอตอบรับ":
    case "wait_accept":
      return (
        <StyledStatus bgcolor="#FFF4E5">
          <StyledTypography
            sx={{
              color: "#E58000",
            }}
            fontSize={fontSize}
          >
            {t("status.wait_accept")}
          </StyledTypography>
        </StyledStatus>
      );

    case "planning":
      return (
        <StyledStatus bgcolor="#BDBDBD">
          <StyledTypography
            sx={{
              color: "#FFFFFF",
            }}
            fontSize={fontSize}
          >
            {t("status.planning")}
          </StyledTypography>
        </StyledStatus>
      );
    case "appointment":
      return (
        <StyledStatus bgcolor="#FFF4E5">
          <StyledTypography
            sx={{
              color: "#E58000",
            }}
            fontSize={fontSize}
          >
            {t("status.appointment")}
          </StyledTypography>
        </StyledStatus>
      );

    case "ตอบรับแล้ว":
    case "accepted":
      return (
        <StyledStatus bgcolor="#E5F0FF">
          <StyledTypography
            sx={{
              color: "#2167D3",
            }}
            fontSize={fontSize}
          >
            {t("status.accepted")}
          </StyledTypography>
        </StyledStatus>
      );
    case "check_in":
      return (
        <StyledStatus bgcolor="#E5F0FF">
          <StyledTypography
            sx={{
              color: "#2167D3",
            }}
            fontSize={fontSize}
          >
            {t("status.check_in")}
          </StyledTypography>
        </StyledStatus>
      );
    case "ไม่อนุมัติ":
    case "not_approved":
      return (
        <StyledStatus bgcolor="#FCE8E8">
          <StyledTypography
            sx={{
              color: "#E41B1B",
            }}
            fontSize={fontSize}
          >
            {t("status.not_approved")}
          </StyledTypography>
        </StyledStatus>
      );
    case "อนุมัติแล้ว":
    case "approved":
      return (
        <StyledStatus bgcolor="#E5F0FF">
          <StyledTypography
            sx={{
              color: "#2167D3",
            }}
            fontSize={fontSize}
          >
            {t("status.approved")}
          </StyledTypography>
        </StyledStatus>
      );
    case "is_main_bom":
      return (
        <StyledStatus bgcolor="#E5F0FF">
          <StyledTypography
            sx={{
              color: "#2167D3",
            }}
            fontSize={fontSize}
          >
            {t("status.is_main_bom")}
          </StyledTypography>
        </StyledStatus>
      );
    case "is_main_routing":
      return (
        <StyledStatus bgcolor="#E5F0FF">
          <StyledTypography
            sx={{
              color: "#2167D3",
            }}
            fontSize={fontSize}
          >
            {t("status.is_main_routing")}
          </StyledTypography>
        </StyledStatus>
      );
    case "รอผลิต":
    case "pending_manu":
      return (
        <StyledStatus bgcolor="#FFF1C5">
          <StyledTypography
            sx={{
              color: "#E58000",
            }}
            fontSize={fontSize}
          >
            {t("status.pending_manu")}
          </StyledTypography>
        </StyledStatus>
      );
    case "in_progress":
    case "กำลังผลิต":
      return (
        <StyledStatus bgcolor="#E5F0FF">
          <StyledTypography
            sx={{
              color: "#2167D3",
            }}
            fontSize={fontSize}
          >
            {t("status.in_progress")}
          </StyledTypography>
        </StyledStatus>
      );
    case "สั่งซื้อแล้วบางส่วน":
    case "partially_ordered":
      return (
        <StyledStatus bgcolor="#E6F9F0">
          <StyledTypography
            sx={{
              color: "#22915A",
            }}
            fontSize={fontSize}
          >
            {t("status.partially_ordered")}
          </StyledTypography>
        </StyledStatus>
      );
    case "สั่งซื้อแล้ว":
    case "fully_ordered":
      return (
        <StyledStatus bgcolor="#E6F9F0">
          <StyledTypography
            sx={{
              color: "#22915A",
            }}
            fontSize={fontSize}
          >
            {t("status.fully_ordered")}
          </StyledTypography>
        </StyledStatus>
      );
    case "นำเข้าแล้วบางส่วน":
    case "partially_imported":
      return (
        <StyledStatus bgcolor="#E6F9F0">
          <StyledTypography
            sx={{
              color: "#22915A",
            }}
            fontSize={fontSize}
          >
            {t("status.partially_imported")}
          </StyledTypography>
        </StyledStatus>
      );
    case "นำเข้าแล้ว":
    case "fully_imported":
      return (
        <StyledStatus bgcolor="#E6F9F0">
          <StyledTypography
            sx={{
              color: "#22915A",
            }}
            fontSize={fontSize}
          >
            {t("status.fully_imported")}
          </StyledTypography>
        </StyledStatus>
      );
    case "รอจัดส่ง":
    case "wait_deliver":
      return (
        <StyledStatus bgcolor="#FFF1C5">
          <StyledTypography
            sx={{
              color: "#E58000",
            }}
            fontSize={fontSize}
          >
            {t("status.wait_deliver")}
          </StyledTypography>
        </StyledStatus>
      );
    case "กำลังจัดส่ง":
    case "delivering":
      return (
        <StyledStatus bgcolor="#FFF1C5">
          <StyledTypography
            sx={{
              color: "#E58000",
            }}
            fontSize={fontSize}
          >
            {t("status.delivering")}
          </StyledTypography>
        </StyledStatus>
      );
    case "สำเร็จ":
    case "completed":
      return (
        <StyledStatus bgcolor="#E6F9F0">
          <StyledTypography
            sx={{
              color: "#22915A",
            }}
            fontSize={fontSize}
          >
            {t("status.completed")}
          </StyledTypography>
        </StyledStatus>
      );
    case "custom":
      return (
        <StyledStatus bgcolor={bgColor}>
          <StyledTypography
            variant={"button"}
            sx={{
              color: fontColor,
            }}
            fontSize={fontSize}
          >
            {text}
          </StyledTypography>
        </StyledStatus>
      );
    default:
      return null;
  }
};

export default CustomizedStatus;
