import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useForm, FormProvider } from "react-hook-form";
import { Box, CircularProgress, Stack } from "@mui/material";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import ActionSettingForm from "../../../components/Form/Setting/Action";
import { IOCRSetting } from "../../../types/Setting/action";
import {
  useOcrSettingQuery,
  OcrSettingQuery,
  useOcrSettingUpdateMutation,
} from "../../../generated/general";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

const ActionSetting = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const methods = useForm<IOCRSetting>({
    defaultValues: {
      po_project_id: "",
      so_project_id: "",
      po_setting_key: "",
      so_setting_key: "",
      company_id: "",
      ocr_is_enabled: false,
      po_is_enabled: false,
      so_is_enabled: false,
    },
  });
  const graphqlClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, isLoading, isSuccess, refetch } =
    useOcrSettingQuery<OcrSettingQuery>(graphqlClient);

  const { isLoading: isUpdating, mutateAsync: update } =
    useOcrSettingUpdateMutation<Error>(graphqlClient, {
      onSuccess: () => {
        enqueueSnackbar(`แก้ไขข้อมูลสำเร๊จ`, {
          variant: "success",
        });
        refetch();
      },
      onError: () => {
        enqueueSnackbar(`แก้ไขข้อมูลไม่สำเร็จ`, {
          variant: "error",
        });
      },
    });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (isSuccess) {
      const { OCRSetting } = data;
      const formattedData = {
        ...OCRSetting,
        id: undefined,
      };

      reset(formattedData);
    }
  }, [data, isSuccess, reset]);

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.action.index"),
    },
  ];

  const updateHandler = async (data: IOCRSetting) => {
    try {
      if (data) {
        await update({ updateInput: data });
      }
    } catch (err) {}
  };

  const cancelHandler = () => {
    reset();
  };

  if (isLoading || isUpdating) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 300px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <FormProvider {...methods}>
        <ActionSettingForm />
        <BottomNavbar>
          <Stack direction="row" spacing={1} alignItems="center">
            <CustomizedButton
              title={"ยกเลิก"}
              variant="outlined"
              type="reset"
              onClick={cancelHandler}
            />
            <CustomizedButton
              title={"บันทึก"}
              variant="contained"
              onClick={handleSubmit(updateHandler)}
            />
          </Stack>
        </BottomNavbar>
      </FormProvider>
    </>
  );
};

export default ActionSetting;
