import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IPurchaseRequest } from "../../../types/Purchase/purchaseRequest";
import { useForm, useWatch } from "react-hook-form";
import PurchaseRequestHeader from "../../../components/Form/Purchase/Request/Header";
import RequestorForm from "../../../components/Form/Purchase/Request/Requestor";
import PurchaseItemList from "../../../components/Table/Purchase/ItemList/PurchaseItemList";
import PurchaseFooter from "../../../components/Form/Purchase/Footer";
import {
  purchaseRequestSchema,
  purchaseRequestValidation,
} from "../../../components/Form/Purchase/Request/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, CircularProgress, Stack } from "@mui/material";
import {
  ActivityType,
  PurchaseActivityLogDocumentType,
  PurchaseDocumentType,
  PurchaseRequestCreateInput,
  PurchaseRequestQuery,
  PurchaseRequestUpdateInput,
  Status,
  usePurchaseDocumentNextStatusMutation,
  usePurchaseRequestCreateMutation,
  usePurchaseRequestQuery,
  usePurchaseRequestUpdateMutation,
} from "../../../generated/purchase";
import {
  WarehousesQuery,
  useWarehousesQuery,
} from "../../../generated/inventory";
import { errorMessageFormatter } from "../../Setting/Inventory/Warehouse";
import { useSnackbar } from "notistack";
import {
  purchaseRequestCreatePayloadFormatter,
  purchaseRequestQueryFormatter,
  purchaseRequestUpdatePayloadFormatter,
} from "../../../utils/Formatter/PurchaseRequest";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useTranslation } from "react-i18next";
import { EntityTypeEnum } from "../../../generated/creatable";
import { usePurchaseError } from "../../../hooks/Purchase/use-purchase-error";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useRoleSelfPermission } from "../../../hooks/use-role-permission";
import dayjs from "dayjs";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import { useActivityLogCreate } from "../../../hooks/use-global-activity-log";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import { sortDataByUniqueIdAndName } from "../../../utils/Formatter/Global";
import { useStateContext } from "../../../contexts/auth-context";
import { useUserInformationQuery } from "../../../generated/user-infomation";

const DocumentInfoTab = () => {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { id } = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const {
    control,
    setValue,
    formState: { errors, dirtyFields },
    getValues,
    handleSubmit,
    reset,
  } = useForm<IPurchaseRequest>({
    defaultValues: purchaseRequestSchema,
    resolver: yupResolver(purchaseRequestValidation),
    mode: "onChange",
  });

  const {
    state: { authUser },
  } = useStateContext();

  const { purchaseActivityLogCreate } = useActivityLogCreate();

  const createdBy = useWatch({ control, name: "created_by" });

  useRoleSelfPermission(createdBy);

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const graphQLClientWithHeaderPurchase: GraphQLClient =
    createGraphQLClientWithMiddleware("purchase");

  const graphQLClientWithHeaderSetting: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, isLoading, isSuccess, refetch } =
    usePurchaseRequestQuery<PurchaseRequestQuery>(
      graphQLClientWithHeaderPurchase,
      {
        uniqueInput: {
          unique_id: id,
        },
      },
      {
        enabled: !!id,
      }
    );

  const { isLoading: isCreating, mutateAsync: create } =
    usePurchaseRequestCreateMutation<Error>(graphQLClientWithHeaderPurchase);

  const { isLoading: isUpdating, mutateAsync: update } =
    usePurchaseRequestUpdateMutation<Error>(graphQLClientWithHeaderPurchase);

  const { isLoading: isChanging, mutateAsync: updateStatus } =
    usePurchaseDocumentNextStatusMutation<Error>(
      graphQLClientWithHeaderPurchase
    );

  const { data: warehouseData, isLoading: isWarehouseLoading } =
    useWarehousesQuery<WarehousesQuery>(graphQLClientWithHeaderItem);

  const sortedWarehouse = sortDataByUniqueIdAndName(
    warehouseData?.warehouses || []
  );

  useEffect(() => {
    if (isSuccess) {
      const { purchaseRequest } = data;

      const getPurchaseRequestData = async () => {
        const purchaseRequestType = purchaseRequest as IPurchaseRequest;
        const formattedPurchaseRequest = await purchaseRequestQueryFormatter(
          purchaseRequestType
        );
        reset(formattedPurchaseRequest);
      };

      getPurchaseRequestData();
    }
  }, [data, isSuccess, reset]);

  useEffect(() => {
    if (state) {
      const { copied_id, copied_unique_id, ...otherState } = state;
      reset({ ...otherState, issue_date: dayjs(), due_date: dayjs() });
    }
  }, [reset, state]);

  const { data: userInfo } = useUserInformationQuery(
    graphQLClientWithHeaderSetting,
    {
      uniqueInput: { user_unique_id: authUser && authUser.unique_id },
    },
    { enabled: !!(authUser && authUser.unique_id) }
  );

  useEffect(() => {
    if (!id) {
      if (!isWarehouseLoading && sortedWarehouse.length > 0) {
        setValue(
          "destination_warehouse_unique_id",
          sortedWarehouse[0]?.unique_id
        );
      }
      if (userInfo) {
        setValue(
          "requestor_contact.name",
          authUser?.first_name + " " + authUser?.last_name
        );
        setValue(
          "requestor_contact.department",
          userInfo?.userinformation?.department
        );
        setValue(
          "requestor_contact.position",
          userInfo?.userinformation?.position
        );
      }
    }
  }, [
    authUser?.first_name,
    authUser?.last_name,
    id,
    isWarehouseLoading,
    setValue,
    sortedWarehouse,
    userInfo,
  ]);

  useEffect(() => {
    if (
      !isLoading &&
      !isWarehouseLoading &&
      id &&
      data?.purchaseRequest?.aggrid_status !== "draft"
    ) {
      setDisabled(true);
    }
  }, [data?.purchaseRequest?.aggrid_status, id, isLoading, isWarehouseLoading]);

  const onPurchaseRequestCreate = async (
    data: IPurchaseRequest,
    status: string
  ) => {
    try {
      const payload = purchaseRequestCreatePayloadFormatter(
        data,
        status
      ) as PurchaseRequestCreateInput;
      const { purchaseRequestCreate } = await create({
        createInput: payload,
      });

      if (purchaseRequestCreate.sub_status === "wait_approve") {
        await updateStatus({
          documentInput: {
            reference_document_type: PurchaseDocumentType.PurchaseRequest,
            unique_id: data.unique_id,
          },
        });
        try {
          await purchaseActivityLogCreate({
            activity_type: ActivityType.Create,
            document_type: PurchaseActivityLogDocumentType.PurchaseRequest,
            reference_id: purchaseRequestCreate.id,
            activity_detail: {},
          });
          if (state && state.copied_unique_id) {
            await purchaseActivityLogCreate({
              activity_type: ActivityType.Copy,
              document_type: PurchaseActivityLogDocumentType.PurchaseRequest,
              reference_id: purchaseRequestCreate.id,
              activity_detail: {
                copied_from: {
                  id: state.copied_id,
                  unique_id: state.copied_unique_id,
                },
                copied_to: {
                  id: purchaseRequestCreate.id,
                  unique_id: purchaseRequestCreate.unique_id,
                },
              },
            });
          }
          await purchaseActivityLogCreate({
            activity_type: ActivityType.StatusChange,
            document_type: PurchaseActivityLogDocumentType.PurchaseRequest,
            reference_id: purchaseRequestCreate.id,
            activity_detail: {
              curr_status: Status.WaitApprove,
            },
          });
        } catch (err) {
          console.log(err);
        }
      } else {
        try {
          await purchaseActivityLogCreate({
            activity_type: ActivityType.Create,
            document_type: PurchaseActivityLogDocumentType.PurchaseRequest,
            reference_id: purchaseRequestCreate.id,
            activity_detail: {},
          });

          if (state && state.copied_unique_id) {
            await purchaseActivityLogCreate({
              activity_type: ActivityType.Copy,
              document_type: PurchaseActivityLogDocumentType.PurchaseRequest,
              reference_id: purchaseRequestCreate.id,
              activity_detail: {
                copied_from: {
                  id: state.copied_id,
                  unique_id: state.copied_unique_id,
                },
                copied_to: {
                  id: purchaseRequestCreate.id,
                  unique_id: purchaseRequestCreate.unique_id,
                },
              },
            });
          }
          await purchaseActivityLogCreate({
            activity_type: ActivityType.StatusChange,
            document_type: PurchaseActivityLogDocumentType.PurchaseRequest,
            reference_id: purchaseRequestCreate.id,
            activity_detail: {
              curr_status: Status.Draft,
            },
          });
        } catch (err) {
          console.log(err);
        }
      }
      navigate(`/purchase/request/${purchaseRequestCreate.unique_id}`);
      enqueueSnackbar("สร้างใบขอซื้อสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      const duplicatedUniqueId = errorMessageFormatter(err);
      if (duplicatedUniqueId) {
        enqueueSnackbar(duplicatedUniqueId, {
          variant: "error",
        });
      } else {
        enqueueSnackbar("สร้างใบขอซื้อไม่สำเร็จ", {
          variant: "error",
        });
      }
    }
  };

  const onPurchaseRequestUpdate = async (
    data: IPurchaseRequest,
    status: string
  ) => {
    try {
      const payload = purchaseRequestUpdatePayloadFormatter(
        data,
        status
      ) as PurchaseRequestUpdateInput;

      const { purchaseRequestUpdate } = await update({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: payload,
      });
      const changedData = Object.keys(dirtyFields);
      if (changedData.length > 0) {
        try {
          await purchaseActivityLogCreate({
            activity_type: ActivityType.Edit,
            document_type: PurchaseActivityLogDocumentType.PurchaseRequest,
            reference_id: purchaseRequestUpdate.id,
            activity_detail: {},
          });
        } catch (error) {
          console.log(error);
        }
      }
      enqueueSnackbar(`แก้ไขใบขอซื้อสำเร็จ`, {
        variant: "success",
      });

      await refetch();
    } catch (err) {
      const duplicatedUniqueId = errorMessageFormatter(err);
      if (duplicatedUniqueId) {
        enqueueSnackbar(duplicatedUniqueId, {
          variant: "error",
        });
      } else {
        enqueueSnackbar("สร้างใบขอซื้อไม่สำเร็จ", {
          variant: "error",
        });
      }
    }
  };

  const onPurchaseRequestSendApprove = async (data: IPurchaseRequest) => {
    try {
      if (data) {
        const payload = purchaseRequestUpdatePayloadFormatter(
          data,
          "wait_approve"
        ) as PurchaseRequestUpdateInput;

        if (!id) {
          onPurchaseRequestCreate(data, "wait_approve");
        } else {
          const { purchaseRequestUpdate } = await update({
            uniqueInput: {
              unique_id: id,
            },
            updateInput: payload,
          });
          await updateStatus({
            documentInput: {
              reference_document_type: PurchaseDocumentType.PurchaseRequest,
              unique_id: data.unique_id,
            },
          });
          try {
            await purchaseActivityLogCreate({
              activity_type: ActivityType.StatusChange,
              document_type: PurchaseActivityLogDocumentType.PurchaseRequest,
              reference_id: purchaseRequestUpdate.id,
              activity_detail: {
                curr_status: Status.WaitApprove,
              },
            });
          } catch (error) {
            console.log(error);
          }

          enqueueSnackbar(`ส่งอนุมัติใบขอซื้อสำเร็จ`, {
            variant: "success",
          });

          await refetch();
        }
      }
    } catch (err) {
      enqueueSnackbar(`ส่งอนุมัติใบขอซื้อไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const onPurchaseRequestNotApprove = async (data: IPurchaseRequest) => {
    try {
      const payload = purchaseRequestUpdatePayloadFormatter(
        data,
        "wait_approve",
        true
      ) as PurchaseRequestUpdateInput;
      const { purchaseRequestUpdate } = await update({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: payload,
      });
      try {
        await purchaseActivityLogCreate({
          activity_type: ActivityType.StatusChange,
          document_type: PurchaseActivityLogDocumentType.PurchaseRequest,
          reference_id: purchaseRequestUpdate.id,
          activity_detail: {
            curr_status: Status.NotApproved,
          },
        });
      } catch (error) {
        console.log(error);
      }

      enqueueSnackbar(`ไม่อนุมัติใบขอซื้อสำเร็จ`, {
        variant: "success",
      });
      await refetch();
    } catch (err) {
      enqueueSnackbar(`ไม่อนุมัติใบขอซื้อไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const onPurchaseRequestApprove = async (data: IPurchaseRequest) => {
    try {
      const payload = purchaseRequestUpdatePayloadFormatter(
        data,
        "approved"
      ) as PurchaseRequestUpdateInput;
      const { purchaseRequestUpdate } = await update({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: payload,
      });
      await updateStatus({
        documentInput: {
          reference_document_type: PurchaseDocumentType.PurchaseRequest,
          unique_id: data.unique_id,
        },
      });
      try {
        await purchaseActivityLogCreate({
          activity_type: ActivityType.StatusChange,
          document_type: PurchaseActivityLogDocumentType.PurchaseRequest,
          reference_id: purchaseRequestUpdate.id,
          activity_detail: {
            curr_status: Status.Approved,
          },
        });
      } catch (error) {
        console.log(error);
      }
      enqueueSnackbar(`อนุมัติใบขอซื้อสำเร็จ`, {
        variant: "success",
      });

      await refetch();
    } catch (err) {
      enqueueSnackbar(`อนุมัติใบขอซื้อไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const editClickHandler = () => {
    setDisabled(false);
    setIsEdit(true);
  };

  const cancelEditHandler = () => {
    setDisabled(true);
    setIsEdit(false);
    reset();
  };

  const onPurchaseRequestEditHandler = async (data: IPurchaseRequest) => {
    setDisabled(true);
    setIsEdit(false);
    await onPurchaseRequestUpdate(data, data.sub_status ? data.sub_status : "");
  };

  const renderButton = () => {
    switch (data?.purchaseRequest?.aggrid_status) {
      case "draft":
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isUpdating}
              onClick={handleSubmit((data) =>
                onPurchaseRequestUpdate(data, "draft")
              )}
            />
            <CustomizedButton
              title={t("button.send")}
              variant="contained"
              onClick={handleSubmit(onPurchaseRequestSendApprove)}
              disabled={isChanging}
            />
          </Stack>
        );
      case "wait_approve":
        if (isEdit) {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                disabled={isCreating}
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(onPurchaseRequestEditHandler)}
              />
            </Stack>
          );
        } else {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.not_approve")}
                disabled={isUpdating}
                onClick={handleSubmit(onPurchaseRequestNotApprove)}
              />
              <CustomizedButton
                title={t("button.approve")}
                variant="contained"
                onClick={handleSubmit(onPurchaseRequestApprove)}
                disabled={isChanging}
              />
            </Stack>
          );
        }

      case "not_approved":
      case "approved":
      case "cancelled":
      case "partially_ordered":
      case "fully_ordered":
        if (isEdit) {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                disabled={isCreating}
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(onPurchaseRequestEditHandler)}
              />
            </Stack>
          );
        }
        return;
      default:
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isCreating}
              onClick={handleSubmit((data) =>
                onPurchaseRequestCreate(data, "draft")
              )}
            />
            <CustomizedButton
              title={t("button.send")}
              variant="contained"
              onClick={handleSubmit(onPurchaseRequestSendApprove)}
            />
          </Stack>
        );
    }
  };

  usePurchaseError(errors);

  if ((id && (isLoading || isUpdating)) || isWarehouseLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 300px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <form>
      <PurchaseRequestHeader
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        allWarehouses={sortedWarehouse || []}
        editClickHandler={editClickHandler}
        refetch={refetch}
      />
      <RequestorForm
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        reset={reset}
      />
      <CustomizedBox padding="1rem" sx={{ breakAfter: "auto" }}>
        <PurchaseItemList
          control={control}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          disabled={disabled}
          documentType={EntityTypeEnum.PurchaseRequest}
        />
      </CustomizedBox>
      <PurchaseFooter
        control={control}
        setValue={setValue}
        errors={errors}
        disabled={disabled}
        documentType="purchase_request"
      />
      {(![
        "not_approved",
        "approved",
        "cancelled",
        "partially_ordered",
        "fully_ordered",
      ].includes(data?.purchaseRequest?.aggrid_status || "") ||
        ([
          "not_approved",
          "approved",
          "cancelled",
          "partially_ordered",
          "fully_ordered",
        ].includes(data?.purchaseRequest?.aggrid_status || "") &&
          isEdit)) && <BottomNavbar>{renderButton()}</BottomNavbar>}
    </form>
  );
};

export default DocumentInfoTab;
