import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";
import { useSnackbar } from "notistack";
import { Box, Typography, Grid } from "@mui/material";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import CustomizedButton from "../../../Custom/CustomizedButton";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledSwitch from "../../../Controller/ContolledSwitch";
import { IOCRSetting } from "../../../../types/Setting/action";
import { OCR_SETTING_VALIDATE } from "../../../../services/Setting/OCR/OCRSettingIsValid";

const ActionSettingForm = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext<IOCRSetting>();
  const [disablePOValidate, setDisablePOValidate] = useState<boolean>(false);
  const [disableSOValidate, setDisableSOValidate] = useState<boolean>(false);

  const graphqlClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const ocrIsEnabled = useWatch({
    control,
    name: "ocr_is_enabled",
  });

  const poIsEnabled = useWatch({
    control,
    name: "po_is_enabled",
  });

  const soIsEnabled = useWatch({
    control,
    name: "so_is_enabled",
  });

  const ocrSettingValidate = async (type: string) => {
    const projectId = getValues(
      type === "purchase_order" ? "po_project_id" : "so_project_id"
    );
    const settingKey = getValues(
      type === "purchase_order" ? "po_setting_key" : "so_setting_key"
    );
    try {
      const { OCRSettingIsValid } = await graphqlClient.request(
        OCR_SETTING_VALIDATE,
        {
          validateInput: {
            type,
            project_id: projectId,
            setting_key: settingKey,
          },
        }
      );
      if (!OCRSettingIsValid) {
        throw new Error("Setting not valid");
      }
      enqueueSnackbar(t("setting.action.sentence.test_success"), {
        variant: "success",
      });
    } catch (err) {
      if (type === "purchase_order") {
        setDisablePOValidate(false);
      } else {
        setDisableSOValidate(false);
      }
      enqueueSnackbar(t("setting.action.sentence.test_fail"), {
        variant: "error",
      });
    }
  };

  const ocrSettingValidateHandler = async (type: string) => {
    if (type === "purchase_order") {
      setDisablePOValidate(true);
    } else {
      setDisableSOValidate(true);
    }
    await ocrSettingValidate(type);
  };

  return (
    <CustomizedBox margin={"2rem 0 4rem 0"}>
      <Typography fontWeight="bold" marginBottom={2} marginLeft={1}>
        {t("setting.action.ocr")}
      </Typography>
      <Box sx={{ my: 2, ml: 1 }}>
        <ControlledSwitch
          control={control}
          name="ocr_is_enabled"
          label="เปิด"
          disabled={false}
          sx={{ ml: 1, mr: 1 }}
        />
      </Box>
      {ocrIsEnabled && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledTextField
              control={control}
              name={`company_id`}
              error={Boolean(errors?.company_id)}
              helperText={errors?.company_id?.message}
              label={t("setting.action.company_id")}
              disabled={false}
            />
          </Grid>
        </Grid>
      )}
      {ocrIsEnabled && (
        <CustomizedBox boxShadow="none" backgroundColor="#F9F9F9">
          <Typography fontWeight="bold" marginBottom={2} marginLeft={1}>
            {t("setting.action.purchase_document")}
          </Typography>
          <Box sx={{ my: 2, ml: 1 }}>
            <ControlledSwitch
              control={control}
              name="po_is_enabled"
              label="เปิด"
              disabled={false}
              sx={{ ml: 1, mr: 1 }}
            />
          </Box>
          {poIsEnabled && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextField
                  control={control}
                  name={`po_project_id`}
                  error={Boolean(errors?.po_project_id)}
                  helperText={errors?.po_project_id?.message}
                  label={t("setting.action.project_id")}
                  disabled={false}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextField
                  control={control}
                  name={`po_setting_key`}
                  error={Boolean(errors?.po_setting_key)}
                  helperText={errors?.po_setting_key?.message}
                  label={t("setting.action.api_key")}
                  disabled={false}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Box>
                  <CustomizedButton
                    title={"ทดสอบ"}
                    variant="contained"
                    size="medium"
                    onClick={() => ocrSettingValidateHandler("purchase_order")}
                    disabled={disablePOValidate}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
        </CustomizedBox>
      )}
      {ocrIsEnabled && (
        <CustomizedBox boxShadow="none" backgroundColor="#F9F9F9">
          <Typography fontWeight="bold" marginBottom={2} marginLeft={1}>
            {t("setting.action.sales_document")}
          </Typography>
          <Box sx={{ my: 2, ml: 1 }}>
            <ControlledSwitch
              control={control}
              name="so_is_enabled"
              label="เปิด"
              disabled={false}
              sx={{ ml: 1, mr: 1 }}
            />
          </Box>
          {soIsEnabled && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextField
                  control={control}
                  name={`so_project_id`}
                  error={Boolean(errors?.so_project_id)}
                  helperText={errors?.so_project_id?.message}
                  label={t("setting.action.project_id")}
                  disabled={false}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextField
                  control={control}
                  name={`so_setting_key`}
                  error={Boolean(errors?.so_setting_key)}
                  helperText={errors?.so_setting_key?.message}
                  label={t("setting.action.api_key")}
                  disabled={false}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Box>
                  <CustomizedButton
                    title={"ทดสอบ"}
                    variant="contained"
                    size="medium"
                    onClick={() => ocrSettingValidateHandler("sales_order")}
                    disabled={disableSOValidate}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
        </CustomizedBox>
      )}
    </CustomizedBox>
  );
};

export default ActionSettingForm;
